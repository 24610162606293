import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import ceSyllabus from "./ceSyllabus";
import SyllabusForm from "../Syllabus/SyllabusForm";
import syllabusPdf from "../../../../assets/pdf/SYLLABUS_GATE_2025_CE.pdf";

const CeGateSyllabus = () => {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: {
          xs: "center",
          sx: "space-between",
          md: "space-between",
        },
        flexDirection: { xs: "column", sm: "row", md: "row" },
      }}
     >
      <Grid item sx={{ width: { xs: "100%", sm: "50%", md: "50%" } }}>
        <Card
          sx={{ width: { xs: "300px", md: "95%" } }}
          style={{ backgroundColor: "#FFFFFF", boxShadow: "none" }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "start", sm: "center" },
                gap: { xs: 1, md: 5 },
              }}
            >
              <Typography
                fontFamily="Manrope"
                fontWeight="700"
                textAlign="start"
                sx={{ fontSize: { xs: "18px", md: "20px" } }}
              >
                Exam Syllabus
              </Typography>
              <a href={syllabusPdf} download="SYLLABUS_GATE_2025_CE">
                <Button
                  style={{
                    backgroundColor: "#3CBA80",
                  }}
                  sx={{
                    width: "186px",
                    border: 1,
                    borderRadius: 3,
                    py: 1.5,
                    color: "#FFFFFF",
                    textAlign: "center",
                    gap: 0.7,
                    px: { xs: 1, md: 2 },
                    fontSize: { xs: 12, md: 14 },
                    textTransform: "none",
                    fontFamily: "Manrope",
                    fontWeight: 500,
                  }}
                >
                  <MdOutlineFileDownload />
                  Download Syllabus
                </Button>
              </a>
            </Box>
            {ceSyllabus.map((sub, index) => (
              <Box sx={{ mt: 2 }} key={index}>
                <Typography
                  fontFamily="Manrope"
                  fontWeight="700"
                  textAlign="start"
                  sx={{ fontSize: { xs: "13px", md: "20px" }, mt: 2 }}
                >
                  {sub.section}
                </Typography>
                {sub?.topics?.map((topic) => (
                  <Typography
                    fontFamily="Manrope"
                    fontWeight="500"
                    textAlign="start"
                    sx={{ fontSize: { xs: "13px", md: "18px" }, mt: 2, textAlign:'justify' }}
                  >
                    ● {topic}
                  </Typography>
                ))}
              </Box>
            ))}
          </CardContent>
        </Card>
      </Grid>
      <Grid item sx={{ width: { xs: "100%", sm: "50%", md: "50%" } }}>
        <Box
          sx={{
            backgroundColor: "#F1F7F4",
            mt: 2,
            borderRadius: "14px",
            width: { xs: "300px", md: "597px" },
            height: { xs: "522px", md: "489px" },
          }}
        >
          <SyllabusForm/>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CeGateSyllabus;
