import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import style from "./About.module.scss";

const aboutUs = [
  {
    year: "44",
    title: "World Class Gems",
  },
  {
    year: "14",
    title: "Years of Shared Experience",
  },
  {
    year: "1.9",
    title: "Lakhs Hours of Teaching",
  },
  {
    year: "8",
    title: "Years Researched Process",
  },
];

const About = () => {
  return (
    <Box sx={{ width: "100%", my: { xs: 0, md: 12 } }}>
      <Box className={style.deskTopWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              py: 5,
            }}
          >
            {aboutUs.map((abt, index) => (
              <Grid
                key={index}
                item
               
              >
                <Box  sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width:{sm:'150px', md:'180px'}
                }}>
                  <Typography
                    fontFamily="Tiempos Headline"
                    sx={{
                      color: "#ffffff",
                      fontSize: {sm:'32px',md:"48px"},
                      fontWeight: "700",
                    }}
                  >
                    {abt.year} +
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      color: "#ffffff",
                      fontSize: {sm:'18px',md:"24px"},
                      fontWeight: "300",
                      textAlign:'center',
                    }}
                  >
                    {abt.title}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box className={style.responsiveWrapper}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 4,
                py: 3,
              }}
            >
              {aboutUs.map((abt,index)=>(
                <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
               >
                <Typography
                  fontFamily="Tiempos Text Medium "
                  sx={{ color: "#ffffff", fontSize: "28px", fontWeight: "600" }}
                >
                  {abt.year} +
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "15px", fontWeight: "400" }}
                >
                  {abt.title}
                </Typography>
              </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default About;
