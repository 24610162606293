import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import styles from './CollgPredictor.module.scss';

import excelFile from './collg_rank_list.xlsx'


const SteinStore = require("stein-js-client");
const store = new SteinStore(
  "https://api.steinhq.com/v1/storages/66a0dd224d11fd04f016afc6"
);

const CollegeFinder = () => {
  const [state, setState] = useState({
    data: {},
    branch: '',
    rank: '',
    colleges: [],
    type: '',
    formData: {}
  });

  const handleBranchChange = (selectedOption) => {
    console.log('Selected Branch:', selectedOption); // Add logging
    setState(prevState => ({ ...prevState, branch: selectedOption.value, type: '', colleges: [] }));
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    console.log({ name, value })
    setState(prev => ({ ...prev, formData: { ...prev.formData, [name]: value } }))
  }

  const handleRankChange = (e) => {
    setState(prevState => ({ ...prevState, rank: e.target.value }));
  };

  const handleTypeChange = (selectedOption) => {
    setState(prevState => ({ ...prevState, type: selectedOption ? selectedOption.value : '' }));
  };

  const handleSearch = () => {
    const { data, branch, rank } = state;


    if (branch && rank && state.formData.phone && state.formData.name) {
      const branchData = data[branch] || [];
      const filteredColleges = branchData.filter(college => college['FINAL CUT-OFF RANK'] >= parseInt(rank));
      setState(prevState => ({
        ...prevState,
        colleges: filteredColleges.map(college => ({
          name: college['Name of College'],
          type: college['Type']
        }))
      }));


      const currDate = new Date();
      store
        .append("Sheet1", [{ ...state.formData, timestamp: `${currDate.toLocaleDateString()} ${currDate.toLocaleTimeString()}` }])
        .then((res) => {
          console.log("Form data submitted to Google Sheets:", res);
          // setSubmitted(true);
          // setSubmitting(false);
          // alert("Submitted Successfully");
          // window.location.reload();
        })
        .catch((error) => {
          console.error("Error submitting form data:", error);
          // setSubmitting(false);
          alert("Error submitting form data. Please try again later.");
        });
    } else {
      alert("Please fill all the fields in the form")
    }
  };

  const branchOptions = Object.keys(state.data).map(branchName => ({
    value: branchName,
    label: branchName
  }));

  const typeOptions = [...new Set(state.colleges.map(college => college.type))].map(type => ({
    value: type,
    label: type
  }));

  const filteredColleges = state.type
    ? state.colleges.filter(college => college.type === state.type)
    : state.colleges;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(excelFile);

        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        const sheetNames = workbook.SheetNames;
        let parsedData = {};

        console.log({ sheetNames })

        sheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          // Skip the first row by setting the range
          const range = XLSX.utils.decode_range(worksheet['!ref']);
          range.s.r = 1; // Skip the first row
          worksheet['!ref'] = XLSX.utils.encode_range(range);
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const keys = jsonData[0];
          const data = jsonData.slice(1).map(row => {
            let obj = {};
            keys.forEach((key, index) => {
              obj[key] = row[index];
            });
            return obj;
          }).filter(row => Object.values(row).some(value => value !== undefined && value !== ''));
          parsedData[sheetName] = data;
        });

        console.log('Parsed Data:', parsedData); // Add logging
        setState(prevState => ({ ...prevState, data: parsedData }));
      } catch (error) {
        console.error('Error fetching or parsing the Excel file:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      {/* <h1>College Finder</h1> */}
      <div className={styles.selectors}>
        <div>
          <label className={styles.label}>Name</label>
          <input
            name="name"
            type="text"
            value={state.name}
            onChange={handleChangeForm}
            className={styles.input}
          />
        </div>
        <div>
          <label className={styles.label}>Phone Number</label>
          <input
            name="phone"
            type="text"
            value={state.phone}
            onChange={handleChangeForm}
            className={styles.input}
          />
        </div>

        <div>
          <label className={styles.label}>Select Branch</label>
          <Select
            options={branchOptions}
            onChange={handleBranchChange}
          // styles={customStyles}
          />
        </div>
        <div>
          <label className={styles.label}>Enter Rank</label>
          <input
            type="number"
            value={state.rank}
            onChange={handleRankChange}
            className={styles.input}
          />
        </div>
        <button onClick={handleSearch} className={styles.button}>Find Colleges</button>
      </div>



      <div className={styles.colleges}>
        {state.colleges.length > 0 && (
          <div>
            <label className={styles.label}>Filter by Type:</label>
            <Select
              options={typeOptions}
              onChange={handleTypeChange}
              // styles={customStyles}
              isClearable
            // className={styles.input}
            />
          </div>
        )}


        {/* <h2>Colleges</h2> */}
        <ul>
          {filteredColleges.map((college, index) => (
            <li key={index}>{college.name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CollegeFinder;
