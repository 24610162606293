import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { GoDotFill } from "react-icons/go";

const PrPolicyData = () => {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          p: 8,
          px: { xs: 5, md: 30 },
        }}
      >
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
            textAlign: "justify",
          }}
        >
          Msigma Gokulam Pvt. Ltd. (here-in after referred to as “Msigma
          Gokulam”, “we”, “us”, “our”, “company”, or “mgsigma”) is an edu-tech
          company dedicated to (but not limited to) creating quality and
          effective educational videos for all streams of engineering.
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          We are devoted to making engineering education simple, so we believe
          it's only sensible that our privacy policy should also be simple to
          understand and free of legal jargon – unless absolutely required on a
          lawful basis.
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          We aim for the highest standards of safety, security and
          confidentiality when using your data, and this Privacy Policy
          describes how and why Msigma Gokulam collect, store, use, and/or share
          (process) your information when you use our services (Services), such
          as when you:
        </Typography>
        <Box
          sx={{ display: "flex", alignItems: "center", gap: 2, mt: 4, px: 3 }}
        >
          <GoDotFill />
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "15px" },
              fontWeight: "600",
              textAlign: "justify",
            }}
          >
            Visit our website at http://www.msigmagokulam.com, or any website of
            ours that links to this privacy policy.
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", gap: 2, mt: 1, px: 3 }}
        >
          <GoDotFill />
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "15px" },
              fontWeight: "600",
              textAlign: "justify",
            }}
          >
            Download and use our mobile application (Msigma The Online Academy),
            or any other application of ours that links to this privacy policy.
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", gap: 2, mt: 1, px: 3 }}
        >
          <GoDotFill />
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "15px" },
              fontWeight: "600",
              textAlign: "justify",
            }}
          >
            Engage with us in other related ways, including any sales,
            marketing, or events
          </Typography>
        </Box>

        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          As it is your data, you have the right to know how this Privacy Policy
          applies to you. If at any point in time, an individual provides data
          or other information about someone other than himself or herself, the
          individual warrants that they have that person’s consent to provide
          such information for the purpose specified.
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          Questions or concerns? Reading this privacy policy will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at
          support@msigmagokulam.com.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrPolicyData;
