import React from 'react'
import IeGateBanner from 'components/CourseDetails/Banners/InstGate/IeGateBanner'
import IeGateData from 'components/CourseDetails/Gate/Instrumentation/IeGateData'

const InstrumentationGate = () => {
  return (
    <>
      <IeGateBanner/>
      <IeGateData/>
    </>
  )
}

export default InstrumentationGate
