import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  Button,
  CardContent,
} from "@mui/material";
import style from "./Masters.module.scss";
import { createTheme, ThemeProvider } from "@mui/material";

import manu from "../../../assets/images/manu.png";
import ranghanathan from "../../../assets/images/rang.png";
import shekhar from "../../../assets/images/shekh.png";
import charly from "../../../assets/images/charly.png";
import nithin from "../../../assets/images/nithin.png";
import prasanna from "../../../assets/images/prasanna.png";
import athira from "../../../assets/images/athiramiss.png";
import arya from "../../../assets/images/aryadutt.png";
import akhila from "../../../assets/images/akhila.png";
import anoop from "../../../assets/images/anoop.png";
import bala from "../../../assets/images/bala.png";
import betharaju from "../../../assets/images/betharaju.png";
import betsy from "../../../assets/images/betsy.png";
import hari from "../../../assets/images/hari.png";
import naveen from "../../../assets/images/naveen.png";
import padmaja from "../../../assets/images/padmaja.png";
import raghav from "../../../assets/images/raghav.png";
import rajarao from "../../../assets/images/rajarao.png";
import reddy from "../../../assets/images/reddy.png";
import roshan from "../../../assets/images/roshan.png";
import trinath from "../../../assets/images/trinath.png";
import vinod from "../../../assets/images/vinod.png";
import vishnu from "../../../assets/images/vishnu.png";
import renjan from "../../../assets/images/renjanRoy.png";
import empty from "../../../assets/images/empty.png";
import arjun from "../../../assets/images/arjunsir.png";
import dipin from "../../../assets/images/dipin.png";
import suresh from "../../../assets/images/suresh.png";
import mojya from "../../../assets/images/mojya.png";

const breakpoints = {
  values: {
    xs: 0,
    sm: 620,
    md: 1279,
    lg: 1280,
  },
};

const theme = createTheme({
  breakpoints: { ...breakpoints },
});

const allMasters = [
  {
    name: "Manu M Rajan",
    position: "The CEO & Executive Director Msigma Gokulam",
    image: manu,
  },
  {
    name: "Dr. K Renganathan",
    position: "ISRO G level Scientist",
    image: ranghanathan,
  },
  {
    name: "A V V B  Sekhar",
    position: "Man Behind 100+ Single Digit Ranks in Gate and ESE",
    image: shekhar,
  },
  {
    name: "Charlies Leo",
    position: "Former Military Engineering Service",
    image: charly,
  },
  {
    name: "Rajarao M V [SHASTRI]",
    position: "Ex IES officer (All India Rank 6)",
    image: rajarao,
  },
  {
    name: "V S R Suresh",
    position: "India's No 1 Facuilty in multiple Electronics Subjects",
    image: suresh,
  },
  {
    name: "Trinath Reddy",
    position: "23+ Years of Experience and Over a lakh of students",
    image: trinath,
  },
  {
    name: "T Nithin",
    position: "Consecutive GATE Topper Under AIR 100",
    image: nithin,
  },
  {
    name: "V. Bala Murali Krishna",
    position: "Best Competitive mathematics faculity in India",
    image: bala,
  },
  {
    name: "Vaka Raghav Reddy",
    position: "GATE Single Digit Rank Holder ",
    image: raghav,
  },
  {
    name: "Prasanna Kumar",
    position: "IIT Graduate ",
    image: prasanna,
  },
  {
    name: "Ranga Reddy",
    position: "IIT Graduate ",
    image: reddy,
  },
  {
    name: "Naveen Gollapally",
    position: "Prominent GATE Faculty",
    image: naveen,
  },
  {
    name: "Betharaju Surendra Yadav",
    position: "Prominent GATE Faculty",
    image: betharaju,
  },
  {
    name: "G Vinod Kumar",
    position: "IIIT Graduate",
    image: vinod,
  },
  {
    name: "B Mojya",
    position: "Prominent GATE Faculty",
    image: mojya,
  },
  {
    name: "Vishnu Vardhan",
    position: "Prominent GATE Faculty",
    image: vishnu,
  },
  {
    name: "Dipin Venugopal",
    position: "GATE Rank Holder",
    image: dipin,
  },
  {
    name: "Arjun M A",
    position: "GATE Rank Holder",
    image: arjun,
  },
  {
    name: "Athira A",
    position: "GATE Rank Holder",
    image: athira,
  },
  {
    name: "Aryadutt C S",
    position: "GATE Rank Holder",
    image: arya,
  },
  {
    name: "Renjen Roy R U",
    position: "GATE Rank Holder",
    image: renjan,
  },
  {
    name: "Padmaja P L",
    position: "GATE Rank Holder",
    image: padmaja,
  },
  {
    name: "Akhila Raj",
    position: "GATE Rank Holder",
    image: akhila,
  },
  {
    name: "Betsy Victor",
    position: "GATE Rank Holder",
    image: betsy,
  },
  {
    name: "Roshan Alex",
    position: "GATE Rank Holder",
    image: roshan,
  },

  {
    name: "Harikrishnan",
    position: "CINO",
    image: hari,
  },
  {
    name: "Anoop Baby",
    position: "GATE Rank Holder",
    image: anoop,
  },
];

const Masters = () => {
  const [displayedCards, setDisplayedCards] = useState(allMasters.slice(0, 3));
  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setDisplayedCards(allMasters);
    setShowAll(true);
  };

  const handleShowLess = () => {
    setDisplayedCards(allMasters.slice(0, 3));
    setShowAll(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className={style.deskTopWrapper} sx={{ my: 12 }}>
        <Grid container sx={{ width: "100%", px: { xs: 4, md: 12 } }}>
          <Grid container>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  width: "100%",
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{ fontSize: { xs: "30px", md: "34px" }, fontWeight: 400 }}
                >
                  Meet the
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    fontSize: { xs: "30px", md: "34px" },
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                >
                  Masters
                </Typography>
              </Grid>
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: { md: "14px", lg: "16px" },
                  fontWeight: 400,
                  textAlign: "center",
                  px: { xs: 3, sm: 10, md: 40 },
                  mt: 2,
                  lineHeight: "35px",
                }}
              >
                Not just highly skilled, our teachers are highly sincere and
                devoted to teaching and to help you achieve your dreams.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 1,
            }}
          >
            {allMasters.map((member, index) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={2.4}
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Card
                  sx={{
                    width: 230,
                    height: 410,
                    borderRadius: 0,
                    boxShadow: "none",
                  }}
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <CardMedia
                    component="img"
                    image={member.image}
                    loading="lazy"
                    alt="Chairman"
                    sx={{ height: 300, width: 230 }}
                  />
                  <CardContent>
                    <Typography
                      fontFamily="Manrope"
                      fontWeight="500"
                      textAlign="center"
                      component="div"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      {member.name}
                    </Typography>
                    <Typography
                      fontFamily="Manrope"
                      fontWeight="400"
                      textAlign="center"
                      component="div"
                      sx={{ fontSize: "14px", color: "#000000" }}
                    >
                      {member.position}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>

      <Box className={style.responsiveWrapper} sx={{ mb: 6, mt: 12 }}>
        <Grid container sx={{ width: "100%", px: 4 }}>
          <Grid container>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: 2,
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{ fontSize: "28px", fontWeight: 400 }}
                >
                  Meet the
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    fontSize: "28px",
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                >
                  Masters
                </Typography>
              </Grid>
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  textAlign: "start",
                  mt: 2,
                }}
              >
                Not just highly skilled, our teachers are highly sincere and
                devoted to teaching and to help you achieve your dreams.
              </Typography>
            </Grid>
          </Grid>
          <Grid container marginTop="30px" spacing={2}>
            {displayedCards.map((member, index) => (
              <Grid
                item
                xs={12}
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Card
                  sx={{
                    width: 230,
                    height: 390,
                    borderRadius: 0,
                    boxShadow: "none",
                  }}
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <CardMedia
                    component="img"
                    image={member.image}
                    alt="Chairman"
                    sx={{ height: 300, width: 230 }}
                  />
                  <CardContent>
                    <Typography
                      fontFamily="Manrope"
                      textAlign="center"
                      component="div"
                      sx={{
                        fontSize: 18,
                        fontWeight: 500,
                        color: "#000000",
                      }}
                    >
                      {member.name}
                    </Typography>
                    <Typography
                      fontFamily="Manrope"
                      fontWeight="400"
                      textAlign="center"
                      component="div"
                      sx={{ fontSize: 14, color: "#000000" }}
                    >
                      {member.position}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            {!showAll ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  mt: 2,
                }}
              >
                <Button
                  fontFamily="Manrope"
                  style={{
                    backgroundColor: "#F0F0F0",
                  }}
                  sx={{
                    borderRadius: 15,
                    py: 1,
                    color: "#000000",
                    textAlign: "center",
                    px: 3,
                    fontSize: 14,
                    textTransform: "none",
                    boxShadow: "none",
                  }}
                  variant="contained"
                  onClick={handleShowMore}
                >
                  View All
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  mt: 2,
                }}
              >
                <Button
                  fontFamily="Manrope"
                  style={{
                    backgroundColor: "#F0F0F0",
                  }}
                  sx={{
                    borderRadius: 15,
                    py: 1,
                    color: "#000000",
                    textAlign: "center",
                    px: 3,
                    fontSize: 14,
                    textTransform: "none",
                    boxShadow: "none",
                  }}
                  variant="contained"
                  onClick={handleShowLess}
                >
                  View Less
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Masters;
