import eceSilver from "../../../../assets/thumbnails/gate/eceSilver.png";
import eceGold from "../../../../assets/thumbnails/gate/eceGold.png";
import ecePlatinum from "../../../../assets/thumbnails/gate/ecePlatinum.png";

const gateEce = [
  {
    thumb: eceSilver,
    title: "GATE Electronics& Communication Engineering",
    plan: "Silver",
    endsOn: "28 February 2025",
    prize: "15,000",
    language: "English | Malayalam",
    features: [
      "500+ Hours of State-of-the-Art Studio Recorded Classes.",
      "Assessment Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
    ],
  },
  {
    thumb: eceGold,
    title: "GATE Electronics& Communication Engineering",
    plan: "Gold",
    prize: "20,000",
    endsOn: "28 February 2025",
    language: "English | Malayalam",
    features: [
      "500+ Hours Studio Recorded Classes.",
      "Hard Copy of Study Materials.",
      "Assessment Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
    ],
  },
  {
    thumb: ecePlatinum,
    title: "GATE Electronics& Communication Engineering",
    plan: "Platinum",
    prize: "27,000",
    endsOn: "28 February 2025",
    language: "English | Malayalam",
    features: [
      "150+ Hours of Live Crux Synopsis Conclusion Class.",
      "500+ Hours of Studio Recorded Classes .",
      "Hard Copy of Study Materials.",
      "Assessment Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace.",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
      "Receive a Tailored Study Plan.",
      "Concise Revision Notes.",
    ],
  },
  {
    thumb: eceSilver,
    title: "GATE Electronics& Communication Engineering",
    plan: "Silver",
    prize: "15,000",
    endsOn: "28 February 2025",
    language: "English ",
    features: [
      "500+ Hours of State-of-the-Art Studio Recorded Classes.",
      "Assessment Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
    ],
  },
  {
    thumb: eceGold,
    title: "GATE Electronics& Communication Engineering",
    plan: "Gold",
    prize: "20,000",
    endsOn: "28 February 2025",
    language: "English ",
    features: [
      "500+ Hours Studio Recorded Classes.",
      "Assessment Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
    ],
  },
  {
    thumb: ecePlatinum,
    title: "GATE Electronics& Communication Engineering",
    plan: "Platinum",
    prize: "27,000",
    endsOn: "28 February 2025",
    language: "English ",
    features: [
      "150+ Hours of Live Crux Synopsis Conclusion Class.",
      "500+ Hours of Studio Recorded Classes .",
      "Hard Copy of Study Materials.",
      "Assessment Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace.",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
      "Receive a Tailored Study Plan.",
      "Concise Revision Notes.",
    ],
  },
];

export default gateEce;
