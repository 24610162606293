import React from 'react'
import EeeGateBanner from 'components/CourseDetails/Banners/EeeGate/EeeGateBanner'
import EeeGateData from 'components/CourseDetails/Gate/Electrical/EeeGateData'

const ElectricalGate = () => {
  return (
    <>
      <EeeGateBanner/>
      <EeeGateData/>
    </>
  )
}

export default ElectricalGate
