import { Box, Button } from "@mui/material";
import React from "react";
import { SiWhatsapp } from "react-icons/si";

const Buttons = () => {
  const handleWhatsAppRedirect = () => {
    const phoneNumber = "+918921407459";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  return (
    <Box
      sx={{ display: { xs: "none", sm:'flex', md: "flex" }, justifyContent: "end", pr: 5 }}
    >
      <Button
        onClick={handleWhatsAppRedirect}
        fontFamily="Manrope"
        
        style={{
          backgroundColor: "#3CBA80",
        }}
        sx={{
          position: "fixed",
          borderRadius: 15,
          py: {sm:0.7,md:1},
          color: "#FFFFFF",
          textAlign: "center",
          gap: 0.7,
          px: {sm:1,md:2},
          // mt: { xs: 7, sm:55, md: 69, lg:85 },
          mt: '76vh',
          fontSize: {sm:14,md:16},
          textTransform: "none",
          zIndex: 100,
          fontWeight:400
        }}
      >
        <SiWhatsapp />
        Chat With Us
      </Button>
    </Box>
  );
};

export default Buttons;
