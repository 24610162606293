import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import style from "./BtechBanner.module.scss";

const BtechBanner = () => {
  return (
    <Box sx={{p:4, px:{xs:4,sm:4, md:6, lg:8}}}>
      
      <Box className={style.deskTopWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                p: 6,
                px: 15,
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "700" }}
                >
                  Btech Courses
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "36px",
                  textAlign:'center'
                }}
              >
                We at Msigma Gokulam focus on B Tech differently, we teach the
                subject instead of the syllabus to our students. Any student
                enrolled in any of our B Tech courses can excel in any
                university syllabus. Currently, we offer B Tech to almost every
                university across the state of Kerala.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={style.tabletWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                px: 4,
                py:20,
              }}
            >
              <Box sx={{ display: "flex", mt:-12 }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "700" }}
                >
                  Btech Courses
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "30px",
                  textAlign: "center"
                }}
              >
                We at Msigma Gokulam focus on B Tech differently, we teach the
                subject instead of the syllabus to our students. Any student
                enrolled in any of our B Tech courses can excel in any
                university syllabus. Currently, we offer B Tech to almost every
                university across the state of Kerala.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <div className={style.responsiveWrapper}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
         >
          <Box>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                p: 1,
                px: 2,
                mb:38,
                
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "32px", fontWeight: "700" }}
                >
                  Btech Courses
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "36px",
                  textAlign:'center'
                }}
              >
                We at Msigma Gokulam focus on B Tech differently, we teach the
                subject instead of the syllabus to our students. Any student
                enrolled in any of our B Tech courses can excel in any
                university syllabus. Currently, we offer B Tech to almost every
                university across the state of Kerala.
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </div>
    </Box>
  );
};

export default BtechBanner;
