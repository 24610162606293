const ieSyllabus = [
  {
    section: "Section 1: Engineering Mathematics",
    topics: [
      "Linear Algebra",
      "Calculus",
      "Differential Equations",
      "Analysis of complex variables",
      "Probability and Statistics",
      "Numerical Methods",
    ],
  },
  {
    section: "Section 2: Electricity and Magnetism",
    topics: [
      "Coulomb's Law, Electric Field Intensity, Electric Flux Density, Gauss's Law, Divergence, Electric field and potential due to point, line, plane, and spherical charge distributions, Effect of the dielectric medium, Capacitance of simple configurations, Biot‐Savart’s law, Ampere’s law, Curl, Faraday’s law, Lorentz force, Inductance, Magnetomotive force, Reluctance, Magnetic circuits, Self and Mutual inductance of simple configurations.",
    ],
  },
  {
    section: "Section 3: Electrical Circuits and Machines",
    topics: ["Voltage and current sources", "Single-phase transformer"],
  },
  {
    section: "Section 4: Signals and Systems",
    topics: [
      "Periodic, aperiodic, and impulse signals; Laplace, Fourier, and z-transforms; transfer function, the frequency response of first and second-order linear time-invariant systems, the impulse response of systems; convolution, correlation. Discrete-time system: impulse response, frequency response, pulse transfer function; DFT and FFT; basics of IIR and FIR filters.",
    ],
  },
  {
    section: "Section 5: Control Systems",
    topics: [
      "Feedback principles, signal flow graphs, transient response, steady-state-errors, Bode plot, phase and gain margins, Routh and Nyquist criteria, root loci, design of lead, lag and lead-lag compensators, state-space representation of systems; time-delay systems; mechanical, hydraulic and pneumatic system components, synchro pair, servo and stepper motors, servo valves; on-off, P, PI, PID, cascade, feedforward, and ratio controllers, tuning of PID controllers and sizing of control valves.",
    ],
  },
  {
    section: "Section 6: Analog Electronics",
    topics: [
      "Characteristics and applications of the diode, Zener diode, BJT, and MOSFET; small-signal analysis of transistor circuits, feedback amplifiers. Characteristics of ideal and practical operational amplifiers; applications of op-amps: adder, subtractor, integrator, differentiator, difference amplifier, instrumentation amplifier, precision rectifier, active filters, oscillators, signal generators, voltage-controlled oscillators and phase-locked loop, sources and effects of noise and interference in electronic circuits.",
    ],
  },
  {
    section: "Section 7: Digital Electronics",
    topics: [
      "Combinational logic circuits, minimization of Boolean functions. IC families: TTL and CMOS. Arithmetic circuits, comparators, Schmitt trigger, multi-vibrators, sequential circuits, flipflops, shift registers, timers, and counters; sample-and-hold circuit, multiplexer, analog-to-digital (successive approximation, integrating, flash and sigma-delta) and digital-to-analog converters (weighted R, R-2R ladder and current steering logic). Characteristics of ADC and DAC (resolution, quantization, significant bits, conversion/settling time); basics of number systems, Embedded Systems: Microprocessor and microcontroller applications, memory and input-output interfacing; basics of data acquisition systems, basics of distributed control systems (DCS) and programmable logic controllers (PLC).",
    ],
  },
  {
    section: "Section 8: Measurements",
    topics: [
      "SI units, standards (R,L,C, voltage, current, and frequency), systematic and random errors in measurement, expression of uncertainty - accuracy and precision, propagation of errors, and linear and weighted regression. Bridges: Wheatstone, Kelvin, Megohm, Maxwell, Anderson, Schering, and Wien for measurement of R, L, C and frequency, Q-meter. Measurement of voltage, current, and power in single and three-phase circuits; ac and dc current probes; true RMS meters, voltage and current scaling, instrument transformers, timer/counter, time, phase, and frequency measurements, digital voltmeter, digital multimeter; oscilloscope, shielding, and grounding.",
    ],
  },
  {
    section: "Section 9: Sensors and Industrial Instrumentation",
    topics: [
      "Resistive-, capacitive-, inductive-, piezoelectric-, Hall effect sensors, and associated signal conditioning circuits; transducers for industrial instrumentation: displacement (linear and angular), velocity, acceleration, force, torque, vibration, shock, pressure (including low pressure), flow (variable head, variable area, electromagnetic, ultrasonic, turbine, and open channel flow meters) temperature (thermocouple, bolometer, RTD (3/4 wire), thermistor, pyrometer, and semiconductor); liquid level, pH, conductivity and viscosity measurement. 4-20 mA two-wire transmitter.",
    ],
  },
  {
    section: "Section 10: Communication and Optical Instrumentation",
    topics: [
      "Amplitude- and frequency modulation and demodulation; Shannon's sampling theorem, pulse code modulation; frequency and time division multiplexing, amplitude-, phase-, frequency-, quadrature amplitude, pulse shift keying for digital modulation; optical sources and detectors: LED, laser, photo-diode, light-dependent resistor, square-law detectors, and their characteristics; interferometer: applications in metrology; basics of fiber optic sensing. UV-VIS Spectrophotometers, Mass spectrometers.",
    ],
  }, 
];

export default ieSyllabus;
