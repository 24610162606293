import axios from "axios";

const sendEmail = async (formData, formId) => {
  const url = `https://submit-form.com/${formId}`;
  const emailData = {
    ...formData,
    // subject: subject,
  };

  try {
    const response = await axios.post(url, emailData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.status === 200;
  } catch (error) {
    console.error("Error sending email:", error);
    return false;
  }
};

export default sendEmail;
