import { Button } from "@mui/joy";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { getCourseOptions, submitLead } from "../../api/npf";
import sendEmail from "api/sentformspark";
// Form schema
const formSchema = Yup.object({
  fullName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Full Name Required"),
  phoneNumber: Yup.string()
    .min(10, "Too Short!")
    .max(50, "Too Long!")
    .required("Phone Number Required"),
  email: Yup.string().email("Invalid email").required("Email ID Required"),
});

const courseList = ["Gate", "B.Tech", "ISRO", "ESE"];

const ContactForm = () => {
  const [course, setCourse] = useState("");
  const [loading, setLoading] = useState(false);
  const formId = "9EYLl236S";

  const handleChange = (event) => {
    setCourse(event.target.value);
  };

  // formik
  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      email: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const formData = {
        fullName: values?.fullName,
        phoneNumber: values?.phoneNumber,
        email: values?.email,
        course: course,
      };
      // console.log("Form Data Submitted:", formData);
      // Call submitLead with await to handle asynchronous operation

      // Submit API call

      const status = await sendEmail(
        formData,
        formId,
        "New data on msigma landing"
      );
      if (status) {
        setLoading(false);
        alert("Submitted, we will contact you");

        resetForm({
          values: {
            fullName: "",
            phoneNumber: "",
            email: "",
          },
        });
        setCourse("");
      }
    },
    validationSchema: formSchema,
  });

  return (
    <Box
      sx={{
        width: { xs: 320, md: 600 },
      }}>
      <form
        onSubmit={formik.handleSubmit}
        style={{ width: "100%", marginTop: "16px" }}>
        <FormControl fullWidth variant="standard" sx={{ mt: 4 }}>
          <InputLabel
            id="demo-simple-select-standard-label"
            sx={{ px: 0, ml: -1.7, fontFamily: "Manrope", fontWeight: "500" }}>
            Select course you’re interested in
          </InputLabel>
          <Select
            value={course}
            onChange={handleChange}
            label="Select course you’re interested in"
            sx={{
              width: "100%",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
              "&:active": {
                backgroundColor: "#ffffff",
              },
            }}>
            {courseList.map((courses, index) => (
              <MenuItem
                key={index}
                value={courses}
                sx={{
                  fontFamily: "Manrope",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    color: "#43BA80",
                  },
                  "&:active": {
                    backgroundColor: "#ffffff",
                    color: "#43BA80",
                  },
                }}>
                {courses}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          value={formik.values.fullName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="fullName"
          name="fullName"
          variant="standard"
          label="Full Name"
          sx={{
            width: "100%",
            mt: 4,
            "& label": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
            "& input": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
          }}
        />
        <Typography sx={{ color: "#fc0303", fontFamily: "Manrope" }}>
          {formik.touched.fullName && formik.errors.fullName}
        </Typography>

        <TextField
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="phoneNumber"
          name="phoneNumber"
          variant="standard"
          label="Phone Number"
          sx={{
            width: "100%",
            mt: 4,
            "& label": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
            "& input": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
          }}
        />
        <Typography sx={{ color: "#fc0303", fontFamily: "Manrope" }}>
          {formik.touched.phoneNumber && formik.errors.phoneNumber}
        </Typography>

        <TextField
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="email"
          name="email"
          variant="standard"
          label="Email"
          sx={{
            width: "100%",
            mt: 4,
            "& label": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
            "& input": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
          }}
        />
        <Typography sx={{ color: "#fc0303", fontFamily: "Manrope" }}>
          {formik.touched.email && formik.errors.email}
        </Typography>

        <Button
          type="submit"
          fontFamily="Manrope"
          style={{
            backgroundColor: "#3878f2",
          }}
          disabled={loading}
          sx={{
            borderRadius: "7px",
            py: 1.5,
            color: "#FFFFFF",
            textAlign: "center",
            px: 2,
            fontSize: "14px",
            textTransform: "none",
            mt: 4,
            ml: { md: 60 },
            height: "42px",
            width: "125px",
          }}>
          {loading ? "Sending..." : "Send"}
        </Button>
      </form>
    </Box>
  );
};

export default ContactForm;
