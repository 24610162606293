import * as React from "react";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { submitLead } from "../../api/npf"; // Ensure this import is correct
import sendEmail from "api/sentformspark";

//Form schema
const formSchema = Yup.object({
  fullName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Full Name Required"),
  phoneNumber: Yup.string()
    .min(10, "Too Short!")
    .max(50, "Too Long!")
    .required("Phone Number Required"),
  email: Yup.string().email("Invalid email").required("Email ID Required"),
});

const Enquiry = () => {
  const transparentBlack = alpha("#000000", 0.6);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [course, setCourse] = React.useState("");

  const formId = "9EYLl236S";

  const handleCourseChange = (event) => {
    setCourse(event.target.value);
  };

  // formik
  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      email: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const formData = {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        course: course,
      };
      const status = await sendEmail(
        formData,
        formId,
        "New data on msigma landing"
      );

      if (status) {
        setLoading(false);
        alert("Submitted, we will contact you");

        resetForm({
          values: {
            fullName: "",
            phoneNumber: "",
            email: "",
          },
        });
        setCourse("");
        setOpen(false); // Close the modal
      }
    },
    validationSchema: formSchema,
  });

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        sx={{
          width: "121px",
          borderRadius: "10px",
          borderColor: "#131F2D",
          backgroundColor: "#FFFFFF",
          py: 1,
          color: "#000000",
          textTransform: "none",
          "&:hover, &:focus": {
            border: "none",
            boxShadow: "none",
            backgroundColor: "#F7F7F7",
          },
          fontSize: "12px",
          fontWeight: 400,
          fontFamily: "Manrope",
        }}
        onClick={() => setOpen(true)}>
        Explore Course
      </Button>
      <Modal
        style={{ backgroundColor: transparentBlack }}
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 800,
            borderRadius: "30px",
            p: 3,
            boxShadow: "none",
          }}>
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <FormControl
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{ mt: 3, width: { xs: 280, md: 600 } }}>
            <Box>
              <InputLabel
                id="demo-simple-select-standard-label"
                sx={{
                  px: 0,
                  ml: -1.7,
                  fontFamily: "Manrope",
                  fontWeight: "500",
                }}>
                Select course you’re interested in
              </InputLabel>
              <Select
                value={course}
                onChange={handleCourseChange}
                variant="standard"
                label="Select course you’re interested in"
                sx={{
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                  },
                  "&:active": {
                    backgroundColor: "#ffffff",
                  },
                }}>
                <MenuItem
                  value="BTech"
                  sx={{
                    fontFamily: "Manrope",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      color: "#43BA80",
                    },
                    "&:active": {
                      backgroundColor: "#ffffff",
                      color: "#43BA80",
                    },
                  }}>
                  BTech
                </MenuItem>
              </Select>
            </Box>
            <TextField
              value={formik.values.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="fullName"
              name="fullName"
              variant="standard"
              label="Full Name"
              sx={{
                width: "100%",
                mt: 4,
                "& label": {
                  fontFamily: "Manrope",
                  fontWeight: 500,
                },
                "& input": {
                  fontFamily: "Manrope",
                  fontWeight: 500,
                },
              }}
            />
            <Typography sx={{ color: "#fc0303", fontFamily: "Manrope" }}>
              {formik.touched.fullName && formik.errors.fullName}
            </Typography>
            <TextField
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="phoneNumber"
              name="phoneNumber"
              variant="standard"
              label="Phone Number"
              sx={{
                width: "100%",
                mt: 4,
                "& label": {
                  fontFamily: "Manrope",
                  fontWeight: 500,
                },
                "& input": {
                  fontFamily: "Manrope",
                  fontWeight: 500,
                },
              }}
            />
            <Typography sx={{ color: "#fc0303", fontFamily: "Manrope" }}>
              {formik.touched.phoneNumber && formik.errors.phoneNumber}
            </Typography>
            <TextField
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="email"
              name="email"
              variant="standard"
              label="Email"
              sx={{
                width: "100%",
                mt: 4,
                "& label": {
                  fontFamily: "Manrope",
                  fontWeight: 500,
                },
                "& input": {
                  fontFamily: "Manrope",
                  fontWeight: 500,
                },
              }}
            />
            <Typography sx={{ color: "#fc0303", fontFamily: "Manrope" }}>
              {formik.touched.email && formik.errors.email}
            </Typography>

            <Button
              type="submit"
              fontFamily="Manrope"
              style={{
                backgroundColor: "#3878f2",
              }}
              disabled={loading}
              s
              sx={{
                borderRadius: "7px",
                py: 1.5,
                color: "#FFFFFF",
                textAlign: "center",
                px: 2,
                fontSize: "14px",
                textTransform: "none",
                mt: 4,
                ml: { md: 60 },
                height: "42px",
                width: "125px",
              }}>
              {loading ? "Sending..." : "Apply"}
            </Button>
          </FormControl>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
};

export default Enquiry;
