import { AiOutlineLogout as LogOut } from "react-icons/ai";
import { FiUser } from "react-icons/fi";

export const Roles = {
  User: "user",
  Admin: "admin",
};

export const NavLinks = [
  { name: "My Account", link: "/profile/student/account", icon: <FiUser /> },
  { name: "Logout", link: "", icon: <LogOut /> },
];
export const branches = [
  "Aeronautical Engg",
  "Applied Electronics & Instrumentation",
  "Artificial Intelligence",
  "Artificial Intelligence & Data Science",
  "Automobile",
  "Biomedical Engg",
  "Biotechnology",
  "Chemical Engg",
  "Chemistry",
  "Civil Engg",
  "Computer Science",
  "Computer Science (Artificial Intelligence)",
  "Computer Science (Artificial Intelligence & Machine Learning)",
  "Computer Science (Data Science)",
  "Computer Science Cyber Security",
  "Electronics & Biomedical",
  "Electronics & Communication",
  "Electrical and Computer Engineering",
  "Electrical & Electronics",
  "Food Technology",
  "Humanities",
  "Industrial Engg",
  "Information Technology",
  "Instrumentation & Control",
  "Mandatory Courses",
  "Mathematics",
  "Mechanical Engg",
  "Mechatronics",
  "Metallurgy",
  "Mechanical (Auto)",
  "Mechanical (Prod)",
  "Naval & Ship Building",
  "Physics",
  "Polymer Engg",
  "Production Engg",
  "Robotics and Automation",
  "Safety & Fire Engg",
  "None of the above",
];

export const Colleges = [
  "None of the below",
  "Amal Jyothi College of Engg Kanjirappally",
  "AWH Engg College",
  "Adi Shanakara Institute of Engg and Tech",
  "Al Azhar College of Engg and Tech",
  "ACE College of Engineering",
  "Ahalia School of Engg and Tech",
  "Al-Ameen College, Kulappully Shornur",
  "Albertian Institute of Science and Tech",
  "AXIS College of Engg and Tech",
  "Baselios Mathews II College of Engg",
  "Bishop Jerom Institute",
  "Carmel College of Engg and Tech",
  "Christ College of Engg",
  "Christ Knowledge City",
  "College of Engg Adoor",
  "College of Engg and Management Punnapra",
  "College of Engg and Tech Payyanur",
  "College of Engg Aranmula",
  "College of Engg Chengannur",
  "College of Engg Cherthala",
  "College of Engg Kallooppara",
  "College of Engg Karunagappaly",
  "College of Engg Kidangoor",
  "College of Engg Kottarakkara",
  "College of Engg Munnar",
  "College of engg Muttathara",
  "College of Engg Pathanapuram",
  "College of Engg Perumon",
  "College of Engg Poonjar",
  "College of Engg Thalassery",
  "College of Engg Trikaripur",
  "College Of Engg Trivandrum",
  "College of Engg Vadakara",
  "College of Engg Attingal",
  "CUSAT",
  "Ernad Knowledge City Technical Campus",
  "Fedaral Institute of science and Tech",
  "Govt College of Engg Kannur",
  "Govt Engg College",
  "Govt Engg college Barton Hill",
  "Govt Engg College Idukki",
  "Govt Engg College Sreekrshnapuram",
  "Govt Engg College Thrissur",
  "Govt Engg College Waynad",
  "Gurudeva Institute of Science and Tech",
  "Heera college of engg and Tech",
  "Holy Grace Academy of Engg",
  "ICCS College of Engg and Management",
  "IES College of Engg",
  "Ilahiya College of Engg and Tech",
  "ILM College of Engg and Tech",
  "Indira Gandhi Institute of Engg and Tech",
  "Jaibharath College of Management and Engg Tech",
  "Jawaharlal College of Engg and Tech",
  "John Cox memorial csi institute of Tech",
  "Jyothi Engg College",
  "KMCT College of Engg",
  "KMCT College of Engg for Women",
  "KMEA Engg College",
  "Kottayam Institute of Tech and Science",
  "KVM College of Engg&IT",
  "LBS College of Engg",
  "LBS Institute of tech for women poojappura",
  "Lourdes Matha College of science and Tech",
  "M.Dasan Institute of Tech",
  "Mahaguru Institute of Tech",
  "Malabar College of Engg and Tech",
  "Malabar Institute of Tech",
  "Mangalam College of Engg",
  "Mar Athanasious College of Engg Kothamangalm",
  "Mar Baselios Christian College of Engg and Tech",
  "Mar Baselios College of Engg and Tech",
  "Mar Baselios Institute of Tech and Science",
  "Marian Engineering College",
  "MEA Engg College Perinthalmanna",
  "MES College of Engg and Tech",
  "MES College of Engg Kuttippuram",
  "MES Institute of Tech and Management",
  "MET'S School of Engg",
  "MG College of Engg",
  "MGM College of Engg and Tech",
  "MGM College of Engg and Pharmaceutical Sciences",
  "Model Engg College",
  "Mohan Das College of Engg and Tech",
  "Mount Zion College of Engg",
  "Mount Zion Institute of Science and Tech",
  "Musliar College of Engg",
  "Musliar College of Engg and Tech",
  "Muslim Asscociation college of Engg",
  "Muthoot Institute of Tech and Science",
  "National Institute of Technology ",
  "National Institute of Technology, Calicut ",
  "Nehru College of Engg and Research centre",
  "Nirmala College of Engg",
  "North Malabar Institute of Tech",
  "NSS College of Engg",
  "PA Aziz College of Engg and Tech",
  "Providence College of Engg",
  "PRS College of Engg and Trech",
  "Rajadhani Institute of Engg and Tech",
  "Rajadhani Institute of Science and Tech",
  "Rajagiri School of Engg and Tetch (RET)",
  "Rajiv Gandhi Institute of Tech Kottayam",
  "Royal College of Engg and Tech",
  "Sadguru Swamy Nithyananda Institute of Tech",
  "Sahrdaya College of Engg and Tech",
  "Saintgits College of Engg",
  "Sarabhai Institute of Science and Tech",
  "SCMS School of Engg and Tech",
  "Shahul Hameed Memorial Engg College",
  "SNGIST Group of Institutions",
  "SNM Institute of Management and Tech",
  "Sree Buddha College of Engg",
  "Sree Buddha College of Engg Pattoor",
  "Sree Chitra Thirunnal College of Engg",
  "Sree Narayana Guru College of Engg and Tech",
  "Sree Narayana Gurukulam College of Engg",
  "SreeNarayana Institute of Tech Adoor",
  "Sreepathy Institute of Management and Technology",
  "ST. Thomas Institute for Science and Tech",
  "ST.Josephs College of Engg and Tech PALAI",
  "ST.Thomas College of Engg & Tech Chengannur",
  "ST.Thomas College of Engg and Tech, Mattanur",
  "Thejus Engg College",
  "TKM College of Engg",
  "TKM Institute of Tech",
  "Toc H Institute of Science and Tech",
  "Toms College of Engg",
  "Travancore Engineering College Oyoor",
  "Trinity College of Engg",
  "UKF College of Engg and Tech",
  "Universal Engg College",
  "University College of Engg Thodupuzha",
  "Valia Koonambaikulathamma College of Engg and Tech",
  "Vedavyasa Institute of Technology",
  "Vidya Academy of Science and Tech Technical campus",
  "Vidya Academy of Science and Tech",
  "Vimaljyothi Engg College",
  "VISAT Engg College",
  "Viswajyothi College of Engg and Tech",
  "Younus College of Engg and Tech",
  "Others",
];
