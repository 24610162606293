import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import style from "./Algorithm.module.scss";

const Algorithm = () => {
  return (
    <Box>
      <Box className={style.deskTopWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
              height:'85vh'
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                width: "100%",
                px: 20,
                py:10
              }}
            >
              <Box sx={{ display: "flex", gap: 1, }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "34px", fontWeight: "400" }}
                >
                  Manu Sir’s
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium italic"
                  sx={{ color: "#ffffff", fontSize: "34px", fontWeight: "400" }}
                >
                  Algorithm
                </Typography>
              </Box>
              <Box sx={{ width: "50%", }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "34px",
                    mt: 3,
                  }}
                >
                  This is exclusive to our bachelor’s programs. These are
                  simpler solutions to problems that require complex steps to
                  compute the answers, to save you time and effort for more
                  important aspects. These are tips/shortcuts developed by the
                  master himself with years of his experience in teaching and
                  solving every type of question possible.
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "14px",
                    fontWeight: "400",
                    mt: 3,
                  }}
                >
                  When you learn something to the fullest, you can teach others
                  in the simplest possible way.
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "34px",
                    mt: 3,
                  }}
                >
                  Manu Sir’s Algorithms are the manifestation of that principle.
                  These tips will only be taught after you learn the concept
                  because you should also learn the “How”.
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium italic"
                  sx={{
                    color: "#ffffff",
                    fontSize: "20px",
                    fontWeight: "300",
                    mt: 3,
                  }}
                >
                  “No Shortcuts for Concepts” – Manu M Rajan
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={style.tabletWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                width: "100%",
                px: 4,
                my: 10,
              }}
            >
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "400" }}
                >
                  Manu Sir’s
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium italic"
                  sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "400" }}
                >
                  Algorithm
                </Typography>
              </Box>
              <Box sx={{ width: "55%" }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "34px",
                    mt: 3,
                  }}
                >
                  This is exclusive to our bachelor’s programs. These are
                  simpler solutions to problems that require complex steps to
                  compute the answers, to save you time and effort for more
                  important aspects. These are tips/shortcuts developed by the
                  master himself with years of his experience in teaching and
                  solving every type of question possible.
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "12px",
                    fontWeight: "400",
                    mt: 3,
                  }}
                >
                  When you learn something to the fullest, you can teach others
                  in the simplest possible way.
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "34px",
                    mt: 3,
                  }}
                >
                  Manu Sir’s Algorithms are the manifestation of that principle.
                  These tips will only be taught after you learn the concept
                  because you should also learn the “How”.
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium italic"
                  sx={{
                    color: "#ffffff",
                    fontSize: "16px",
                    fontWeight: "300",
                    mt: 3,
                  }}
                >
                  “No Shortcuts for Concepts” – Manu M Rajan
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <div className={style.responsiveWrapper}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
          }}
        >
          <Grid
            item
            sx={{
              p: 4,
              mb: 55,
            }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography
                fontFamily="Manrope"
                sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "400" }}
              >
                Manu Sir’s
              </Typography>
              <Typography
                fontFamily="Tiempos Text Medium italic"
                sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "400" }}
              >
                Algorithm
              </Typography>
            </Box>
            <Typography
              fontFamily="Manrope"
              sx={{
                color: "#ffffff",
                fontSize: "10px",
                fontWeight: "500",
                lineHeight: "24px",
                mt: 2,
              }}
            >
              This is exclusive to our bachelor’s programs. These are simpler
              solutions to problems that require complex steps to compute the
              answers, to save you time and effort for more important aspects.
              These are tips/shortcuts developed by the master himself with
              years of his experience in teaching and solving every type of
              question possible.
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                color: "#ffffff",
                fontSize: "10px",
                fontWeight: "500",
                mt: 2,
              }}
            >
              When you learn something to the fullest, you can teach others in
              the simplest possible way.
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                color: "#ffffff",
                fontSize: "10px",
                fontWeight: "500",
                lineHeight: "24px",
                mt: 2,
              }}
            >
              Manu Sir’s Algorithms are the manifestation of that principle.
              These tips will only be taught after you learn the concept because
              you should also learn the “How”.
            </Typography>
            <Typography
              fontFamily="Tiempos Text Medium italic"
              sx={{
                color: "#ffffff",
                fontSize: "12px",
                fontWeight: "300",
                mt: 2,
              }}
            >
              “No Shortcuts for Concepts” – Manu M Rajan
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default Algorithm;
