import ieSilver from "../../../../assets/thumbnails/gate/ieSilver.png";
import ieGold from "../../../../assets/thumbnails/gate/ieGold.png";
import iePlatinum from "../../../../assets/thumbnails/gate/iePlatinum.png";

const gateIE = [
  {
    thumb: ieSilver,
    title: "GATE Instrumentation Engineering",
    plan: "Silver",
    prize: "15,000",
    endsOn: "28 February 2025",
    language: "English | Malayalam",
    features: [
      "500+ Hours of State-of-the-Art Studio Recorded Classes.",
      "Assessment Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
    ],
  },
  {
    thumb: ieGold,
    title: "GATE Instrumentation Engineering",
    plan: "Gold",
    prize: "20,000",
    endsOn: "28 February 2025",
    language: "English | Malayalam",
    features: [
      "500+ Hours Studio Recorded Classes.",
      "Hard Copy of Study Materials.",
      "Assessment Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
    ],
  },
  {
    thumb: iePlatinum,
    title: "GATE Instrumentation Engineering",
    plan: "Platinum",
    prize: "27,000",
    endsOn: "28 February 2025",
    language: "English | Malayalam",
    features: [
      "150+ Hours of Live Crux Synopsis Conclusion Class.",
      "500+ Hours of Studio Recorded Classes .",
      "Hard Copy of Study Materials.",
      "Assessment Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace.",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
      "Receive a Tailored Study Plan.",
      "Concise Revision Notes.",
    ],
  },
  {
    thumb: ieSilver,
    title: "GATE Instrumentation Engineering",
    plan: "Silver",
    prize: "15,000",
    endsOn: "28 February 2025",
    language: "English ",
    features: [
      "500+ Hours of State-of-the-Art Studio Recorded Classes.",
      "Assessment Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
    ],
  },
  {
    thumb: ieGold,
    title: "GATE Instrumentation Engineering",
    plan: "Gold",
    prize: "20,000",
    endsOn: "28 February 2025",
    language: "English ",
    features: [
      "500+ Hours Studio Recorded Classes.",
      "Assessment Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
    ],
  },
  {
    thumb: iePlatinum,
    title: "GATE Instrumentation Engineering",
    plan: "Platinum",
    prize: "27,000",
    endsOn: "28 February 2025",
    language: "English ",
    features: [
      "150+ Hours of Live Crux Synopsis Conclusion Class.",
      "500+ Hours of Studio Recorded Classes .",
      "Hard Copy of Study Materials.",
      "Assessment Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace.",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
      "Receive a Tailored Study Plan.",
      "Concise Revision Notes.",
    ],
  },
];

export default gateIE;
