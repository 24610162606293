import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import gateCard from "../../../assets/images/thumbShekhar.png";
import enroll from "../../../assets/images/std.png";
import { Link } from "react-router-dom";
import EnquiryIsro from "components/Modal/EnquiryIsro";

const isro = [
  { dept: "ISRO-Mechanical Engineering", href: "/isro/mech" },
  { dept: "ISRO- Electrical Engineering", href: "/isro/electrical" },
];

const IsroData = () => {
  return (
    <Box sx={{ mt: { xs: 6, md: 3 } }}>
      <Grid container sx={{ width: "100%", px: { xs: 4, md: 8 } }}>
        <Grid container spacing={3}>
          {isro.map((subject, index) => (
            <Grid item md={3} sm={6} xs={12} key={index}>
              <Card
                sx={{
                  maxWidth: 384,
                  boxShadow: "none",
                  border: 1,
                  borderColor: "#E9E9E9",
                  borderRadius: "14px",
                }}
              >
                <CardMedia
                  component="img"
                  height="190"
                  image={gateCard}
                  alt="gate"
                />
                <CardContent>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "18px", fontWeight: "700" }}
                  >
                    {subject.dept}
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      mt: 1,
                      display: "flex",
                      align: "center",
                      gap: 0.5,
                    }}
                  >
                    Language: English | Malayalam
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: "column", md: "flex" },
                        gap: 0.5,
                        alignItems: "center",
                      }}
                    >
                      <img src={enroll} alt="" width="75px" height="30px" />
                      <Typography
                        fontFamily="Manrope"
                        sx={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        2.4K Enrolled
                      </Typography>
                    </Box>

                    <EnquiryIsro />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default IsroData;
