import Placement from "components/PlacementDrive/Placement";
import React from "react";

export default function PlacementDrive() {
  return (
    <>
      <Placement />
    </>
  );
}
