import React from "react";
import { Link } from "react-router-dom";
import style from "./Footer.module.css";
import Logo from "../../assets/logo/newLogo.png";
import Fb from "../../assets/icons/fb.png";
import Ig from "../../assets/icons/ig.png";
import X from "../../assets/icons/x.png";
import Yt from "../../assets/icons/yt.png";
import In from "../../assets/icons/in.png";
import location from "../../assets/icons/location.png";
import mail from "../../assets/icons/mail.png";
import phone from "../../assets/icons/phone.png";

function Footer() {
  return (
    <footer>
      <div className={style.top}>
        <div className={`${style.content} ${style.left}`}>
          <div className={style.sect2}>
            <img src={Logo} alt="" width="200px" height="auto" />
            <div className={style.footerDesc}>
              The story of Msigma Gokulam is all about the passion for teaching
              and the will to make a change
            </div>
          </div>
        </div>
        <div className={style.pages}>
          <ul>
            <div className={style.sectionHeading}>Quick Links</div>
            <div className={style.sectionItems}>
              <div>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/courses">Courses</Link>
                </li>

                <li>
                  <Link
                    to="https://mock.testseries.mgate.in/signup"
                    target="_blank">
                    Free Gate Test & Discussion
                  </Link>
                </li>
                <li>
                  <Link to="/hiring">Careers</Link>
                </li>
                <li>
                  <Link to="/campus-ambassador">Campus Ambassador</Link>
                </li>
              </div>
            </div>
          </ul>

          <ul>
            <div className={style.sectionHeading}>All Courses</div>

            <li className={style.sectionItems}>
              <Link to="/">GATE & PSU</Link>
            </li>
            <li className={style.sectionItems}>
              <Link to="/">B.Tech</Link>
            </li>
            <li className={style.sectionItems}>
              <Link to="/">B.Sc Electronics</Link>
            </li>
            <li className={style.sectionItems}>
              <Link to="/">PSC Engineering Jobs</Link>
            </li>
            <li className={style.sectionItems}>
              <Link to="/">ISRO</Link>
            </li>
            <li className={style.sectionItems}>
              <Link to="/">IES & ESE</Link>
            </li>
          </ul>

          <ul>
            <div className={style.sectionHeading}>Contact Us</div>
            <li className={style.sectionItems}>
              <img src={phone} alt="phone" />
              <a href="tel:+91 8921 40 7459">+91 8921 40 7459</a>
            </li>
            <li
              className={style.sectionItems}
              style={{ marginTop: "10px", marginLeft: "30px" }}>
              <a href="tel:+91 4713 58 5625">+91 7356 489 399</a>
            </li>
            <li className={style.sectionItems}>
              <img src={mail} alt="mail" />
              <a href="mailto:support@msigmagokulam.com">
                support@msigmagokulam.com
              </a>
            </li>
            <li className={style.sectionItems}>
              <img src={location} alt="location" />
              <Link
                style={{ lineHeight: "2" }}
                to="https://www.google.com/maps/place/Msigma+Gokulam/@8.5364936,76.9224721,18.82z/data=!4m7!3m6!1s0x3b05b9523836d0cd:0x8c4368c4da964a6e!4b1!8m2!3d8.5362957!4d76.9229032!16s%2Fg%2F11smm1trmn?entry=ttu">
                Msigma Gokulam Pvt Ltd. PNRA – 40 <br />
                Opp St. Alphonsa Church, Priyadarshini
                <br />
                Nagar, Pongumoodu, P.O, Sreekariyam,
                <br />
                Thiruvananthapuram, Kerala 695017
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className={style.info}>
        <div className={style.socialMedia}>
          {/* <Link to="https://www.facebook.com/">
            <img src={X} alt="X" />
          </Link> */}
          <Link to="https://www.instagram.com/msigmagokulam/" target="_blank">
            <img src={Ig} alt="Ig" />
          </Link>
          <Link to="https://www.facebook.com/msigmagokulam/" target="_blank">
            <img src={Fb} alt="fb" />
          </Link>
          <Link
            to="https://www.linkedin.com/company/msigmagokulam"
            target="_blank">
            <img src={In} alt="in" />
          </Link>

          <Link to="https://www.youtube.com/@msigmagokulam" target="_blank">
            <img src={Yt} alt="yt" />
          </Link>
        </div>
        <div className={style.copyright}>
          © 2024 Msigma Gokulam Pvt Ltd.. All rights reserved
        </div>
        <div className={style.legal}>
          <Link to="/terms">Terms & Conditions</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
