const meSyllabus = [
  {
    section: "Section 1: Engineering Mathematics",
    topics: [
      "Linear Algebra",
      "Differential Equations",
      "Complex Variables",
      "Numerical Methods",
    ],
  },
  {
    section: "Section 2: Applied Mechanics and Design",
    topics: [
      "Engineering Mechanics",
      "Mechanics of Materials",
      "Theory of Machines",
      "Vibrations",
      "Machine Design",
    ],
  },
  {
    section: "Section 3: Fluid Mechanics and Thermal Sciences",
    topics: [
      "Fluid Mechanics",
      "Heat-Transfer",
      "Thermodynamics",
      "Applications: Power Engineering",
      "Refrigeration and air-conditioning",
    ],
  },
  {
    section: "Section 4: Materials, Manufacturing, and Industrial Engineering",
    topics: [
      "Engineering Materials",
      "Casting, Forming, and Joining Processes",
      "Machining and Machine Tool Operations",
      "Metrology and Inspection",
      "Manufacturing",
      "Production Planning and Control",
      "Inventory Control",
      "Operations Research",
    ],
  },
];

export default meSyllabus;
