const syllabusEce = [
    {
      section: "Section 1: Engineering Mathematics",
      topics: [
        "Linear Algebra",
        "Calculus",
        "Differential Equations",
        "Vector Analysis", 
        "Complex Analysis",
        "Probability and Statistics",
      ],
    },
    {
      section: "Section 2: Networks, Signals, and Systems",
      topics: [
        "Circuit analysis",
        "Time and frequency domain analysis of linear circuits",
        "signals",
      ],
    },
    {
      section: "Section 3: Electronic Devices",
      topics: [
        "Energy bands in intrinsic and extrinsic semiconductors, equilibrium carrier concentration, direct and indirect band-gap semiconductors. Carrier transport: diffusion current, drift current, mobility and resistivity, generation and recombination of carriers, Poisson and continuity equations. P-N junction, Zener diode, BJT, MOS capacitor, MOSFET, LED, photo diode, and solar cell.",
      ],
    },
    {
      section: "Section 4: Analog Circuits",
      topics: ["Diode circuits", "Op-amp circuits"],
    },
    {
      section: "Section 5: Digital Circuits",
      topics: [
        "Number representations",
        "Sequential circuits",
        "Data converters",
        "Semiconductor memories",
        "Computer organization",
      ],
    },
    {
      section: "Section 6: Control Systems",
      topics: [
        "Basic control system components; Feedback principle; Transfer function; Block diagram representation; Signal flow graph; Transient and steady-state analysis of LTI systems; Frequency response; Routh-Hurwitz and Nyquist stability criteria; Bode and root-locus plots; Lag, lead, and lag-lead compensation; State variable model and solution of state equation of LTI systems.",
      ],
    },
    {
      section: "Section 7: Communications",
      topics: [
        "Random processes",
        "Analog communications",
        "Digital communications",
      ],
    },
    {
      section: "Section 8: Electromagnetics",
      topics: [
        "Maxwell's equations",
        "Plane waves and properties",
        "Transmission lines",
      ],  
    },
  ];
  
  export default syllabusEce;
  