import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import enroll from "../../../assets/images/std.png";

import gateCard from "../../../assets/images/thumbShekhar.png";
import ece from "../../../assets/images/thumbManu.png";
import aei from "../../../assets/images/thumbRenjan.png";
import eee from "../../../assets/images/thumbPadmaja.png";
import ie from "../../../assets/images/thumbAthira.png";
import cse from "../../../assets/images/thumbRanga.png";
import ce from "../../../assets/images/thumbCharlies.png";
import fyc from "../../../assets/images/thumbArjun.png";
import Enquiry from "components/Modal/Enquiry";

const btech = [
  { dept: "Electronics and Communication", href: "/btech/ece", thumb: ece },
  {
    dept: "Applied Electronics & Instrumentation",
    href: "/btech/aie",
    thumb: aei,
  },
  {
    dept: "Electrical and Electronics Engineering",
    href: "/btech/eee",
    thumb: eee,
  },
  {
    dept: "Electronics & Biomedical Engineering",
    href: "/btech/ebme",
    thumb: aei,
  },
  { dept: "Instrumentation Engineering", href: "/btech/ie", thumb: ie },
  { dept: "Computer Science", href: "/btech/cse", thumb: cse },
  { dept: "Biomedical Engineering", href: "/btech/be", thumb: ece },
  { dept: "Mechanical Engineering", href: "/btech/me", thumb: gateCard },
  { dept: "Civil Engineering", href: "/btech/ce", thumb: ce },
  { dept: "First Year Common Subjects", href: "/btech/fyc", thumb: fyc },
];

const BtechData = () => {
  return (
    <Box sx={{ mt: { xs: 6, md: 3 } }}>
      
      <Grid container sx={{ width: "100%", px: { xs: 4, md: 8 } }}>
        <Grid container spacing={3}>
          {btech.map((subject, index) => (
            <Grid item md={3} sm={6} xs={12} key={index}>
              <Card
                sx={{
                  maxWidth: 384,
                  boxShadow: "none",
                  border: 1,
                  borderColor: "#E9E9E9",
                  borderRadius: "14px",
                }}
              >
                <CardMedia
                  component="img"
                  height="190"
                  image={subject.thumb}
                  alt="gate"
                />
                <CardContent>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "20px", fontWeight: "700" }}
                  >
                    {subject.dept}
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      mt: 1,
                      display: "flex",
                      align: "center",
                      gap: 0.5,
                    }}
                  >
                    Language: Malayalam
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: "column", md: "flex" },
                        gap: 0.5,
                        alignItems: "center",
                      }}
                    >
                      <img src={enroll} alt="" width="75px" height="30px" />
                      {/* <Typography
                        fontFamily="Manrope"
                        sx={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        2.4K Enrolled
                      </Typography> */}
                    </Box>

                    <Enquiry />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BtechData;
