import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import style from "./Isro.module.scss";

const IsroBanner = () => {
  return (
    <Box  sx={{p:4, px:{xs:4,sm:4, md:6, lg:8}}}>
      <Box className={style.deskTopWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                p: 10,
                px: 8,
                mt:-6
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "32px", fontWeight: "700" }}
                >
                  ISRO Courses
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "36px",
                  textAlign:'center',
                }}
              >
                Msigma Gokulam is introducing our comprehensive “ISRO Program.”
                With a course validity of 1.5 years, We prepare you for all the
                upcoming technical exams in engineering, not just for ISRO. The
                Program is curated by Senior ISRO Scientists. Our faculty panel
                includes 16 best of the best handpicked faculties from around
                the country including ISRO Scientists, IES First Rank Holders,
                PSU Toppers, Military Engineering Service Officers & DRDO
                Scientists. 
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={style.tabletWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                px: 4,
                py:20,
              }}
            >
              <Box sx={{ display: "flex", mt:-18 }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "700" }}
                >
                  ISRO Courses
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "30px",
                  textAlign: "center",
                  
                }}
              >
                Msigma Gokulam is introducing our comprehensive “ISRO Program.”
                With a course validity of 1.5 years, We prepare you for all the
                upcoming technical exams in engineering, not just for ISRO. The
                Program is curated by Senior ISRO Scientists. Our faculty panel
                includes 16 best of the best handpicked faculties from around
                the country including ISRO Scientists, IES First Rank Holders,
                PSU Toppers, Military Engineering Service Officers & DRDO
                Scientists.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <div className={style.responsiveWrapper}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            px: 3,
          }}
        >
          <Box>
          <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                p: 1,
                px: 2,
              }}
            >
              <Box sx={{ display: "flex", mt:-30 }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "700" }}
                >
                  ISRO Courses
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "30px",
                  textAlign:'center'
                }}
              >
                Msigma Gokulam is introducing our comprehensive “ISRO Program.”
                With a course validity of 1.5 years, We prepare you for all the
                upcoming technical exams in engineering, not just for ISRO. The
                Program is curated by Senior ISRO Scientists. Our faculty panel
                includes 16 best of the best handpicked faculties from around
                the country including ISRO Scientists, IES First Rank Holders,
                PSU Toppers, Military Engineering Service Officers & DRDO
                Scientists. 
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </div>
    </Box>
  );
};

export default IsroBanner;
