import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const data = [
  {
    id: 1,
    text: "To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.",
  },
  {
    id: 2,
    text: "To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.   ",
  },
  {
    id: 3,
    text: "To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.",
  },
  {
    id: 4,
    text: "To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see WHAT ARE YOUR PRIVACY RIGHTS? below).",
  },
  {
    id: 5,
    text: "To evaluate and improve our Services, products, marketing, and your experience. We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.",
  },
  {
    id: 6,
    text: "To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.",
  },
];

const InfoProcess = () => {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          pb: 2,
          px: { xs: 5, md: 30 },
        }}
      >
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "800",
            mt: 2,
            color: "#787878",
          }}
        >
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </Typography>
        <Box sx={{ display: "", mt: 2 }}>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: "800",
              fontStyle: "italic",
            }}
          >
            In Short:
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: "700",
              fontStyle: "italic",
              textAlign: "justify",
            }}
          >
            We process your information to provide, improve, and administer our
            Services, communicate with you, for security and fraud prevention,
            and to comply with law. We may also process your information for
            other purposes with your consent.
          </Typography>
        </Box>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          pb: 8,
          px: { xs: 1, md: 30 },
        }}
      > 
        <List >
          {data.map((item) => (
            <ListItem 
              key={item.id}
              sx={{ display: "flex", alignItems: "start", textAlign: "justify", }}
             >
              <ListItemIcon sx={{ mt: 1 }}>
                <FiberManualRecordIcon
                  sx={{ color: "#0d0d0d", fontSize: "12px" }}
                />
              </ListItemIcon>
              <ListItemText fontFamily='Manrope'
                sx={{ fontSize: { xs: "12px", md: "16px" } }}
                primary={item.text}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default InfoProcess;
