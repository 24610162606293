import React from 'react'
import BtechBanner from 'components/CourseDetails/Banners/Btech/BtechBanner'
import BtechData from 'components/CourseDetails/Btech/BtechData'

const BtechDetail = () => {
  return (
    <>
      <BtechBanner/>
      <BtechData/>
    </>
  )
}

export default BtechDetail
