import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import style from "./Video.module.scss";
import manu from "../../../assets/images/manuVid.png";
import manuu from "../../../assets/images/manuVidd.png";
import charly from "../../../assets/images/charlyVid.png";
import rang from "../../../assets/images/rangVid.png";
import nithin from "../../../assets/images/nithinVid.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import youtube from "../../../assets/icons/ytb.png";
import ytube from "../../../assets/icons/youtb.png";
import { createTheme, ThemeProvider } from "@mui/material";

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1279,
    lg: 1280,
  },
};

const theme = createTheme({
  breakpoints: { ...breakpoints },
});

const demoClass = [
  {
    topic: "GATE | STRENGTH OF MATERIALS | SOM | MECHANICAL | CIVIL",
    thumb: charly,
    videoLink:'https://www.youtube.com/watch?v=rmJWBeYazn8&t=2s'
  },
  {
    topic: "GATE | EC/EE/IN | NETWORK ANALYSIS",
    thumb: nithin,
    videoLink:'https://www.youtube.com/watch?v=ZvJwM6z3I_4'
  },
  {
    topic: "DSP: DIGITAL SIGNAL PROCESSING: KTU EEE, ECE and AE",
    thumb: manu,
    videoLink:'https://www.youtube.com/watch?v=fBRQSy8e5x8&t=608s'
  },
  {
    topic: "ISRO SCIENTIST\ENGINEER (SC) | EC | NETWORK THEORY",
    thumb: rang,
    videoLink:'https://www.youtube.com/watch?v=Iuw2mxBfJxw'
  },
];

const Video = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box className={style.deskTopWrapper}>
        <Grid
          container
          sx={{
            width: "100%",
            py: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
         >
          <Grid
            item
            sx={{ width: "50%", display: "flex", flexDirection: "column", px:4}}
           >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: 1,
                pr:42
              }}
             >
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "34px", fontWeight: 400 }}
              >
                Our
              </Typography>
              <Typography
                fontFamily="Tiempos Text Medium Italic"
                sx={{ fontSize: "34px", fontWeight: 400, fontStyle: "italic" }}
              >
                Demo Class
              </Typography>
            </Grid>
            <Grid
              Container
              sx={{
                mt: 5.6,
                display: "flex",
                alignItems: "start",
                justifyContent: "end",
                flexWrap: "wrap",
                gap: 4,
              }}
             >
              {demoClass.map((demo, index) => (
                <Grid
                  item
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start",
                  }}
                >
                  <Card
                    sx={{
                      width: "280px",
                      height: "190px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 0,
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "start",
                        width: "280px",
                        marginTop: 13.5,
                        paddingBottom: 2,
                        px: 2,
                      }}
                    >
                      <Link
                        to={demo.videoLink}
                        target="_blank"
                      >
                        <img src={youtube} alt="" />
                      </Link>

                      <Typography
                        fontFamily="Manrope"
                        component="div"
                        sx={{
                          fontSize: "12px",
                          fontWeight: 600,
                          color: "#FFFFFF",
                        }}
                      >
                        {demo.topic}
                      </Typography>
                    </Box>
                    <CardMedia
                      component="img"
                      image={demo.thumb}
                      alt="Chairman"
                      sx={{ height: "190px", width: "280px" }}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "start",
              flexDirection: "column",
              width:'50%'
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                gap: 1,
              }}
             >
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "16px", fontWeight: 500, lineHeight: "32px" }}
              >
                Each of our university courses is designed in such a manner{" "}
                <br /> that it may be finished in as little as 2-3 days if
                necessary.
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }}>
              <Card
                sx={{
                  width: "654px",
                  height: "412px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "654px",
                  }}
                >
                  <Link
                    to="https://youtu.be/iBw3_pEqAQ0?si=2FhM4eRS3KEuD5vZ"
                    target="_blank"
                  >
                    <img src={ytube} alt="" />
                  </Link>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "654px",
                    marginTop: 42,
                    paddingBottom: 2,
                  }}
                >
                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "20px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                      px: 4,
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU - ECE, <br /> EEE, AE 2019 & 2015
                    Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={manuu}
                  alt="Chairman"
                  sx={{ height: { xs: 220, md: "416px" }, width: "654px" }}
                />
              </Card>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 5,
            }}
          >
            <Link to="https://www.youtube.com/@msigmagokulam" target="_blank">
              <Button
                fontFamily="Manrope"
                style={{
                  backgroundColor: "#F0F0F0",
                }}
                sx={{
                  borderRadius: 15,
                  py: 1.5,
                  color: "#000000",
                  textAlign: "center",
                  px: 2,
                  fontSize: "16px",
                  textTransform: "none",
                  mt: 2,
                  fontWeight: 400,
                  width: "140px",
                }}
              >
                View All
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>

      <Box className={style.tabletWrapper}
        sx={{
          width: "100%",
          p: 3,
          mt:4
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "28px", fontWeight: 400 }}
              >
                Our
              </Typography>
              <Typography
                fontFamily="Tiempos Text Medium Italic"
                sx={{ fontSize: "28px", fontWeight: 400, fontStyle: "italic" }}
              >
                Demo Class
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: 8,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "32px",
                  textAlign: "center",
                }}
              >
                Each of our university courses is designed in such a manner that
                it may be finished in as little as 2-3 days if necessary.
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }}>
              <Card
                sx={{
                  width: "585px",
                  height: "326px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "328px",
                  }}
                >
                  <Link
                    to="https://youtu.be/iBw3_pEqAQ0?si=2FhM4eRS3KEuD5vZ"
                    target="_blank"
                  >
                    <img src={youtube} alt="" width="50px" />
                  </Link>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "328px",
                    marginTop: 33,
                    paddingBottom: 2,
                  }}
                >
                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                      px: 2,
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU - ECE, EEE, AE 2019 & 2015 Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={manuu}
                  alt="Chairman"
                  sx={{ height: "326px", width: "585px" }}
                />
              </Card>
            </Grid>
          </Grid>
          <Grid
            Container
            sx={{
              mt: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 3,
            }}
          >
            {demoClass.map((demo, index) => (
              <Grid
                item
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                }}
              >
                <Card
                  sx={{
                    width: "280px",
                    height: "190px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 0,
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "start",
                      width: "280px",
                      marginTop: 13.5,
                      paddingBottom: 2,
                      px: 2,
                    }}
                  >
                    <Link
                      to={demo.videoLink}
                      target="_blank"
                    >
                      <img src={youtube} alt="" />
                    </Link>

                    <Typography
                      fontFamily="Manrope"
                      component="div"
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "#FFFFFF",
                      }}
                    >
                      {demo.topic}
                    </Typography>
                  </Box>
                  <CardMedia
                    component="img"
                    image={demo.thumb}
                    alt="Chairman"
                    sx={{ height: "190px", width: "280px" }}
                  />
                </Card>
              </Grid>
            ))}
            <Grid
              item
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link to="https://www.youtube.com/@msigmagokulam" target="_blank">
                <Button
                  fontFamily="Manrope"
                  style={{
                    backgroundColor: "#F0F0F0",
                  }}
                  sx={{
                    borderRadius: 15,
                    py: 1,
                    color: "#000000",
                    textAlign: "center",
                    fontSize: "16px",
                    textTransform: "none",
                    mt: 2,
                    fontWeight: 500,
                    width: "140px",
                  }}
                >
                  View All
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box className={style.responsiveWrapper}
        sx={{
          width: "100%",
          p: 3,
          mt:4
        }}
       >
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "28px", fontWeight: 400 }}
              >
                Our
              </Typography>
              <Typography
                fontFamily="Tiempos Text Medium Italic"
                sx={{ fontSize: "28px", fontWeight: 400, fontStyle: "italic" }}
              >
                Demo Class
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "32px",
                  textAlign: "center",
                }}
              >
                Each of our university courses is designed in such a manner that
                it may be finished in as little as 2-3 days if necessary.
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }}>
              <Card
                sx={{
                  width: "328px",
                  height: "326px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "328px",
                  }}
                >
                  <Link
                    to="https://youtu.be/iBw3_pEqAQ0?si=2FhM4eRS3KEuD5vZ"
                    target="_blank"
                  >
                    <img src={youtube} alt="" width="50px" />
                  </Link>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "328px",
                    marginTop: 33,
                    paddingBottom: 2,
                  }}
                >
                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                      px: 2,
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU - ECE, EEE, AE 2019 & 2015 Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={manuu}
                  alt="Chairman"
                  sx={{ height: "326px", width: "328px" }}
                />
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
           >
            <Grid item sx={{ mt: 1, display: "flex", gap: 1 }}>
              <Card
                sx={{
                  width: "160px",
                  height: "162px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                
              >
                <Box
                  
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "160px",
                    marginTop: 12,
                    paddingBottom: 2,
                    px: 1,
                  }}
                  >
                  <Link
                    to="https://www.youtube.com/watch?v=rmJWBeYazn8&t=2s"
                    target="_blank"
                  >
                    <img src={ytube} alt="" width="25px" />
                  </Link>
                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "9px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                    GATE | STRENGTH OF MATERIALS | SOM | MECHANICAL | CIVIL
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={charly}
                  alt="Chairman"
                  sx={{ height: "162px", width: "160px" }}
                />
              </Card>
              <Card
                sx={{
                  width: "160px",
                  height: "162px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                
              >
                <Box
                 
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "160px",
                    marginTop: 12,
                    paddingBottom: 2,
                    px: 1,
                  }}
                 >
                  <Link
                    to="https://www.youtube.com/watch?v=ZvJwM6z3I_4"
                    target="_blank"
                  >
                    <img src={ytube} alt="" width="25px" />
                  </Link>
                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "9px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                   GATE | EC/EE/IN | NETWORK ANALYSIS
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={nithin}
                  alt="Chairman"
                  sx={{ height: "162px", width: "160px" }}
                />
              </Card>
            </Grid>
            <Grid
              item
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <Link to="https://www.youtube.com/@msigmagokulam" target="_blank">
                <Button
                  fontFamily="Manrope"
                  style={{
                    backgroundColor: "#F0F0F0",
                  }}
                  sx={{
                    borderRadius: 15,
                    py: 1,
                    color: "#000000",
                    textAlign: "center",
                    fontSize: "16px",
                    textTransform: "none",
                    mt: 2,
                    fontWeight: 500,
                    width: "140px",
                  }}
                >
                  View All
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Video;




// import Box from "@mui/material/Box";
// import Card from "@mui/material/Card";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";
// import style from "./Video.module.scss";
// import manu from "../../../assets/images/manuVid.png";
// import manuu from "../../../assets/images/manuVidd.png";
// import charly from "../../../assets/images/charlyVid.png";
// import rang from "../../../assets/images/rangVid.png";
// import nithin from "../../../assets/images/nithinVid.png";
// import { Button } from "@mui/material";
// import { Link } from "react-router-dom";
// import youtube from "../../../assets/icons/ytb.png";
// import ytube from "../../../assets/icons/youtb.png";
// import { createTheme, ThemeProvider } from "@mui/material";

// const breakpoints = {
//   values: {
//     xs: 0,
//     sm: 600,
//     md: 1279,
//     lg: 1280,
//   },
// };

// const theme = createTheme({
//   breakpoints: { ...breakpoints },
// });

// const demoClass = [
//   {
//     topic: "GATE | STRENGTH OF MATERIALS | SOM | MECHANICAL | CIVIL",
//     thumb: charly,
//     videoLink:'https://www.youtube.com/watch?v=rmJWBeYazn8&t=2s'
//   },
//   {
//     topic: "GATE | EC/EE/IN | NETWORK ANALYSIS",
//     thumb: nithin,
//     videoLink:'https://www.youtube.com/watch?v=ZvJwM6z3I_4'
//   },
//   {
//     topic: "DSP: DIGITAL SIGNAL PROCESSING: KTU EEE, ECE and AE",
//     thumb: manu,
//     videoLink:'https://www.youtube.com/watch?v=fBRQSy8e5x8&t=608s'
//   },
//   {
//     topic: "ISRO SCIENTIST\ENGINEER (SC) | EC | NETWORK THEORY",
//     thumb: rang,
//     videoLink:'https://www.youtube.com/watch?v=Iuw2mxBfJxw'
//   },
// ];

// const Video = () => {
//   return (
//     <ThemeProvider theme={theme}>
//       <Box className={style.deskTopWrapper}>
//         <Grid
//           container
//           sx={{
//             width: "100%",
//             p: 12,
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
           
//           }}
//          >
//           <Grid
//             item
//             sx={{ width: "50%", display: "flex", flexDirection: "column"}}
//           >
//             <Grid
//               item
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "start",
//                 gap: 1,
//               }}
//              >
//               <Typography
//                 fontFamily="Manrope"
//                 sx={{ fontSize: "34px", fontWeight: 400 }}
//               >
//                 Our
//               </Typography>
//               <Typography
//                 fontFamily="Tiempos Text Medium Italic"
//                 sx={{ fontSize: "34px", fontWeight: 400, fontStyle: "italic" }}
//               >
//                 Demo Class
//               </Typography>
//             </Grid>
//             <Grid
//               Container
//               sx={{
//                 mt: 5.6,
//                 display: "flex",
//                 alignItems: "start",
//                 justifyContent: "start",
//                 flexWrap: "wrap",
//                 gap: 4,
//               }}
//              >
//               {demoClass.map((demo, index) => (
//                 <Grid
//                   item
//                   key={index}
//                   sx={{
//                     display: "flex",
//                     alignItems: "start",
//                     justifyContent: "start",
//                   }}
//                 >
//                   <Card
//                     sx={{
//                       width: "280px",
//                       height: "190px",
//                       display: "flex",
//                       flexDirection: "column",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       borderRadius: 0,
//                     }}
//                   >
//                     <Box
//                       sx={{
//                         position: "absolute",
//                         display: "flex",
//                         flexDirection: "column",
//                         alignItems: "start",
//                         justifyContent: "start",
//                         width: "280px",
//                         marginTop: 13.5,
//                         paddingBottom: 2,
//                         px: 2,
//                       }}
//                     >
//                       <Link
//                         to={demo.videoLink}
//                         target="_blank"
//                       >
//                         <img src={youtube} alt="" />
//                       </Link>

//                       <Typography
//                         fontFamily="Manrope"
//                         component="div"
//                         sx={{
//                           fontSize: "12px",
//                           fontWeight: 600,
//                           color: "#FFFFFF",
//                         }}
//                       >
//                         {demo.topic}
//                       </Typography>
//                     </Box>
//                     <CardMedia
//                       component="img"
//                       image={demo.thumb}
//                       alt="Chairman"
//                       sx={{ height: "190px", width: "280px" }}
//                     />
//                   </Card>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//           <Grid
//             item
//             sx={{
//               display: "flex",
//               alignItems: "start",
//               justifyContent: "start",
//               flexDirection: "column",
//               width:'50%'
//             }}
//           >
//             <Grid
//               item
//               sx={{
//                 display: "flex",
//                 alignItems: "start",
//                 justifyContent: "start",
//                 gap: 1,
//               }}
//              >
//               <Typography
//                 fontFamily="Manrope"
//                 sx={{ fontSize: "16px", fontWeight: 500, lineHeight: "32px" }}
//               >
//                 Each of our university courses is designed in such a manner{" "}
//                 <br /> that it may be finished in as little as 2-3 days if
//                 necessary.
//               </Typography>
//             </Grid>
//             <Grid item sx={{ mt: 4 }}>
//               <Card
//                 sx={{
//                   width: "654px",
//                   height: "412px",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   borderRadius: 0,
//                 }}
//               >
//                 <Box
//                   sx={{
//                     position: "absolute",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     width: "654px",
//                   }}
//                 >
//                   <Link
//                     to="https://youtu.be/iBw3_pEqAQ0?si=2FhM4eRS3KEuD5vZ"
//                     target="_blank"
//                   >
//                     <img src={ytube} alt="" />
//                   </Link>
//                 </Box>
//                 <Box
//                   sx={{
//                     position: "absolute",
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "start",
//                     justifyContent: "start",
//                     width: "654px",
//                     marginTop: 42,
//                     paddingBottom: 2,
//                   }}
//                 >
//                   <Typography
//                     fontFamily="Manrope"
//                     component="div"
//                     sx={{
//                       fontSize: "20px",
//                       fontWeight: 600,
//                       color: "#FFFFFF",
//                       px: 4,
//                     }}
//                   >
//                     SIGNALS AND SYSTEMS: KTU - ECE, <br /> EEE, AE 2019 & 2015
//                     Scheme
//                   </Typography>
//                 </Box>
//                 <CardMedia
//                   component="img"
//                   image={manuu}
//                   alt="Chairman"
//                   sx={{ height: { xs: 220, md: "416px" }, width: "654px" }}
//                 />
//               </Card>
//             </Grid>
//           </Grid>
//           <Grid
//             item
//             sx={{
//               width: "100%",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               mt: 5,
//             }}
//           >
//             <Link to="https://www.youtube.com/@msigmagokulam" target="_blank">
//               <Button
//                 fontFamily="Manrope"
//                 style={{
//                   backgroundColor: "#F0F0F0",
//                 }}
//                 sx={{
//                   borderRadius: 15,
//                   py: 1.5,
//                   color: "#000000",
//                   textAlign: "center",
//                   px: 2,
//                   fontSize: "16px",
//                   textTransform: "none",
//                   mt: 2,
//                   fontWeight: 400,
//                   width: "140px",
//                 }}
//               >
//                 View All
//               </Button>
//             </Link>
//           </Grid>
//         </Grid>
//       </Box>

//       <Box className={style.tabletWrapper}
//         sx={{
//           width: "100%",
//           p: 3,
//           mt:4
//         }}
//       >
//         <Grid
//           container
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
            
//           }}
//         >
//           <Grid
//             container
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//             }}
//           >
//             <Grid
//               item
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 gap: 1,
//               }}
//             >
//               <Typography
//                 fontFamily="Manrope"
//                 sx={{ fontSize: "28px", fontWeight: 400 }}
//               >
//                 Our
//               </Typography>
//               <Typography
//                 fontFamily="Tiempos Text Medium Italic"
//                 sx={{ fontSize: "28px", fontWeight: 400, fontStyle: "italic" }}
//               >
//                 Demo Class
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 px: 8,
//               }}
//             >
//               <Typography
//                 fontFamily="Manrope"
//                 sx={{
//                   fontSize: "14px",
//                   fontWeight: 500,
//                   lineHeight: "32px",
//                   textAlign: "center",
//                 }}
//               >
//                 Each of our university courses is designed in such a manner that
//                 it may be finished in as little as 2-3 days if necessary.
//               </Typography>
//             </Grid>
//             <Grid item sx={{ mt: 4 }}>
//               <Card
//                 sx={{
//                   width: "585px",
//                   height: "326px",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   borderRadius: 0,
//                 }}
//               >
//                 <Box
//                   sx={{
//                     position: "absolute",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     width: "328px",
//                   }}
//                 >
//                   <Link
//                     to="https://youtu.be/iBw3_pEqAQ0?si=2FhM4eRS3KEuD5vZ"
//                     target="_blank"
//                   >
//                     <img src={youtube} alt="" width="50px" />
//                   </Link>
//                 </Box>
//                 <Box
//                   sx={{
//                     position: "absolute",
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "start",
//                     justifyContent: "start",
//                     width: "328px",
//                     marginTop: 33,
//                     paddingBottom: 2,
//                   }}
//                 >
//                   <Typography
//                     fontFamily="Manrope"
//                     component="div"
//                     sx={{
//                       fontSize: "15px",
//                       fontWeight: 600,
//                       color: "#FFFFFF",
//                       px: 2,
//                     }}
//                   >
//                     SIGNALS AND SYSTEMS: KTU - ECE, EEE, AE 2019 & 2015 Scheme
//                   </Typography>
//                 </Box>
//                 <CardMedia
//                   component="img"
//                   image={manuu}
//                   alt="Chairman"
//                   sx={{ height: "326px", width: "585px" }}
//                 />
//               </Card>
//             </Grid>
//           </Grid>
//           <Grid
//             Container
//             sx={{
//               mt: 3,
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               flexWrap: "wrap",
//               gap: 3,
//             }}
//           >
//             {demoClass.map((demo, index) => (
//               <Grid
//                 item
//                 key={index}
//                 sx={{
//                   display: "flex",
//                   alignItems: "start",
//                   justifyContent: "start",
//                 }}
//               >
//                 <Card
//                   sx={{
//                     width: "280px",
//                     height: "190px",
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     borderRadius: 0,
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       position: "absolute",
//                       display: "flex",
//                       flexDirection: "column",
//                       alignItems: "start",
//                       justifyContent: "start",
//                       width: "280px",
//                       marginTop: 13.5,
//                       paddingBottom: 2,
//                       px: 2,
//                     }}
//                   >
//                     <Link
//                       to={demo.videoLink}
//                       target="_blank"
//                     >
//                       <img src={youtube} alt="" />
//                     </Link>

//                     <Typography
//                       fontFamily="Manrope"
//                       component="div"
//                       sx={{
//                         fontSize: "12px",
//                         fontWeight: 600,
//                         color: "#FFFFFF",
//                       }}
//                     >
//                       {demo.topic}
//                     </Typography>
//                   </Box>
//                   <CardMedia
//                     component="img"
//                     image={demo.thumb}
//                     alt="Chairman"
//                     sx={{ height: "190px", width: "280px" }}
//                   />
//                 </Card>
//               </Grid>
//             ))}
//             <Grid
//               item
//               sx={{
//                 width: "100%",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <Link to="https://www.youtube.com/@msigmagokulam" target="_blank">
//                 <Button
//                   fontFamily="Manrope"
//                   style={{
//                     backgroundColor: "#F0F0F0",
//                   }}
//                   sx={{
//                     borderRadius: 15,
//                     py: 1,
//                     color: "#000000",
//                     textAlign: "center",
//                     fontSize: "16px",
//                     textTransform: "none",
//                     mt: 2,
//                     fontWeight: 500,
//                     width: "140px",
//                   }}
//                 >
//                   View All
//                 </Button>
//               </Link>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Box>

//       <Box className={style.responsiveWrapper}
//         sx={{
//           width: "100%",
//           p: 3,
//           mt:4
//         }}
//        >
//         <Grid
//           container
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Grid
//             container
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//             }}
//           >
//             <Grid
//               item
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 gap: 1,
//               }}
//             >
//               <Typography
//                 fontFamily="Manrope"
//                 sx={{ fontSize: "28px", fontWeight: 400 }}
//               >
//                 Our
//               </Typography>
//               <Typography
//                 fontFamily="Tiempos Text Medium Italic"
//                 sx={{ fontSize: "28px", fontWeight: 400, fontStyle: "italic" }}
//               >
//                 Demo Class
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 gap: 1,
//               }}
//             >
//               <Typography
//                 fontFamily="Manrope"
//                 sx={{
//                   fontSize: "16px",
//                   fontWeight: 500,
//                   lineHeight: "32px",
//                   textAlign: "center",
//                 }}
//               >
//                 Each of our university courses is designed in such a manner that
//                 it may be finished in as little as 2-3 days if necessary.
//               </Typography>
//             </Grid>
//             <Grid item sx={{ mt: 4 }}>
//               <Card
//                 sx={{
//                   width: "328px",
//                   height: "326px",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   borderRadius: 0,
//                 }}
//               >
//                 <Box
//                   sx={{
//                     position: "absolute",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     width: "328px",
//                   }}
//                 >
//                   <Link
//                     to="https://youtu.be/iBw3_pEqAQ0?si=2FhM4eRS3KEuD5vZ"
//                     target="_blank"
//                   >
//                     <img src={youtube} alt="" width="50px" />
//                   </Link>
//                 </Box>
//                 <Box
//                   sx={{
//                     position: "absolute",
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "start",
//                     justifyContent: "start",
//                     width: "328px",
//                     marginTop: 33,
//                     paddingBottom: 2,
//                   }}
//                 >
//                   <Typography
//                     fontFamily="Manrope"
//                     component="div"
//                     sx={{
//                       fontSize: "15px",
//                       fontWeight: 600,
//                       color: "#FFFFFF",
//                       px: 2,
//                     }}
//                   >
//                     SIGNALS AND SYSTEMS: KTU - ECE, EEE, AE 2019 & 2015 Scheme
//                   </Typography>
//                 </Box>
//                 <CardMedia
//                   component="img"
//                   image={manuu}
//                   alt="Chairman"
//                   sx={{ height: "326px", width: "328px" }}
//                 />
//               </Card>
//             </Grid>
//           </Grid>
//           <Grid
//             container
//             sx={{
//               width: "100%",
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//             }}
//            >
//             <Grid item sx={{ mt: 1, display: "flex", gap: 1 }}>
//               <Card
//                 sx={{
//                   width: "160px",
//                   height: "162px",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   borderRadius: 0,
//                 }}
                
//               >
//                 <Box
                  
//                   sx={{
//                     position: "absolute",
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "start",
//                     justifyContent: "start",
//                     width: "160px",
//                     marginTop: 12,
//                     paddingBottom: 2,
//                     px: 1,
//                   }}
//                   >
//                   <Link
//                     to="https://www.youtube.com/watch?v=rmJWBeYazn8&t=2s"
//                     target="_blank"
//                   >
//                     <img src={ytube} alt="" width="25px" />
//                   </Link>
//                   <Typography
//                     fontFamily="Manrope"
//                     component="div"
//                     sx={{
//                       fontSize: "9px",
//                       fontWeight: 600,
//                       color: "#FFFFFF",
//                     }}
//                   >
//                     GATE | STRENGTH OF MATERIALS | SOM | MECHANICAL | CIVIL
//                   </Typography>
//                 </Box>
//                 <CardMedia
//                   component="img"
//                   image={charly}
//                   alt="Chairman"
//                   sx={{ height: "162px", width: "160px" }}
//                 />
//               </Card>
//               <Card
//                 sx={{
//                   width: "160px",
//                   height: "162px",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   borderRadius: 0,
//                 }}
                
//               >
//                 <Box
                 
//                   sx={{
//                     position: "absolute",
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "start",
//                     justifyContent: "start",
//                     width: "160px",
//                     marginTop: 12,
//                     paddingBottom: 2,
//                     px: 1,
//                   }}
//                  >
//                   <Link
//                     to="https://www.youtube.com/watch?v=ZvJwM6z3I_4"
//                     target="_blank"
//                   >
//                     <img src={ytube} alt="" width="25px" />
//                   </Link>
//                   <Typography
//                     fontFamily="Manrope"
//                     component="div"
//                     sx={{
//                       fontSize: "9px",
//                       fontWeight: 600,
//                       color: "#FFFFFF",
//                     }}
//                   >
//                    GATE | EC/EE/IN | NETWORK ANALYSIS
//                   </Typography>
//                 </Box>
//                 <CardMedia
//                   component="img"
//                   image={nithin}
//                   alt="Chairman"
//                   sx={{ height: "162px", width: "160px" }}
//                 />
//               </Card>
//             </Grid>
//             <Grid
//               item
//               sx={{
//                 width: "100%",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 mt: 2,
//               }}
//             >
//               <Link to="https://www.youtube.com/@msigmagokulam" target="_blank">
//                 <Button
//                   fontFamily="Manrope"
//                   style={{
//                     backgroundColor: "#F0F0F0",
//                   }}
//                   sx={{
//                     borderRadius: 15,
//                     py: 1,
//                     color: "#000000",
//                     textAlign: "center",
//                     fontSize: "16px",
//                     textTransform: "none",
//                     mt: 2,
//                     fontWeight: 500,
//                     width: "140px",
//                   }}
//                 >
//                   View All
//                 </Button>
//               </Link>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Box>
//     </ThemeProvider>
//   );
// };

// export default Video;
