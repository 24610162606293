import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import btech from "../../../assets/images/Manu Sir 2.png";
import gate from "../../../assets/images/sh.png";
import isro from "../../../assets/images/rg.png";
import ese from "../../../assets/images/Charlies.png";
import { Link } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material";

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1279,
    lg: 1280,
  },
};

const theme = createTheme({
  breakpoints: { ...breakpoints },
});

const Course = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: { xs: 8, md: 16 } }}>
        <Grid container sx={{ width: "100%", px: { xs: 4, md: 12 } }}>
          <Grid container> 
            <Grid
              item 
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width:'100%'
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{ fontSize: { xs: "30px", md: "34px" }, fontWeight: 400 }}
                >
                  Our
                </Typography>
                <Typography
                  fontFamily="Tiempos Headline "
                  sx={{
                    fontSize: { xs: "30px", md: "34px" },
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                >
                  Courses
                </Typography>
              </Grid>
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: { md: "14px", lg: "16px" },
                  fontWeight: 400,
                  textAlign: "center",
                  px: { md: 8, lg: 28 },
                  mt: 2,
                  lineHeight: "35px",
                }}
              >
                Our lecturers are experienced, with years of expertise in
                engineering and technology, which means they will educate you in
                the most progressive and systematic manner possible.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ px: { xs: 2, md: 0 }, mt: 2 }}
          >
            <Grid item xs={12} sm={6} md={3} lg={3} sx={{ mt: 2 }}>
              <Link to="/btech">
                <Card
                  sx={{
                    display: "flex",
                    backgroundColor: "#FFE5D2",
                    height: 150,
                    borderRadius: 7,
                    boxShadow: 0,
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{ maxWidth: 150, height: 180, mt: 2 }}
                    image={btech}
                    alt="Btech"
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      fontFamily="Manrope"
                      component="div"
                      variant="h5"
                      sx={{
                        fontSize: { xs: 22, sm: 20, md: 24 },
                        fontWeight: 600,
                      }}
                    >
                      B.Tech
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      fontFamily="Manrope"
                      sx={{ fontSize: 16, fontWeight: 500, mt: 1 }}
                    >
                      50K Enrolled
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ mt: 2 }}>
              <Link to="/gate">
                <Card
                  sx={{
                    display: "flex",
                    backgroundColor: "#CFFFE9",
                    height: 150,
                    borderRadius: 7,
                    boxShadow: 0,
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 151, height: 220, mt: 2 }}
                    image={gate}
                    alt="Gate"
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      component="div"
                      variant="h5"
                      fontFamily="Manrope"
                      sx={{ fontSize: 24, fontWeight: 600 }}
                    >
                      GATE
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      fontFamily="Manrope"
                      sx={{ fontSize: 16, fontWeight: 500, mt: 1 }}
                    >
                      2.4K Enrolled
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ mt: 2 }}>
              <Link to="/isro">
                <Card
                  sx={{
                    display: "flex",
                    backgroundColor: "#F3D1FF",
                    height: 150,
                    borderRadius: 7,
                    boxShadow: 0,
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 151, height: 160 }}
                    image={isro}
                    alt="ISRO"
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      component="div"
                      variant="h5"
                      fontFamily="Manrope"
                      sx={{ fontSize: 24, fontWeight: 600 }}
                    >
                      ISRO
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      fontFamily="Manrope"
                      sx={{ fontSize: 16, fontWeight: 500, mt: 1 }}
                    >
                      1K Enrolled
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ mt: 2 }}>
              <Link to="">
                <Card
                  sx={{
                    display: "flex",
                    backgroundColor: "#C7DAFF",
                    height: 150,
                    borderRadius: 7,
                    boxShadow: 0,
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 151, height: 230, mt: 2 }}
                    image={ese}
                    alt="ESE"
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      component="div"
                      variant="h5"
                      fontFamily="Manrope"
                      sx={{ fontSize: 24, fontWeight: 600 }}
                    >
                      ESE
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      fontFamily="Manrope"
                      sx={{ fontSize: 16, fontWeight: 500, mt: 1 }}
                    >
                     
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};
export default Course;

// import React from "react";
// import Box from "@mui/material/Box";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";

// import btech from "../../../assets/images/login.png";
// import gate from "../../../assets/images/sh.png";
// import isro from "../../../assets/images/rg.png";
// import ese from "../../../assets/images/Charlies.png";
// import { Button } from "@mui/material";
// import { Link } from "react-router-dom";

// const Course = () => {
//   return (
//     <Box sx={{ mt: { xs: 8, md: 16 } }}>
//       <Grid container sx={{ width: "100%", px: { xs: 4, md: 12 } }}>
//         <Grid container>
//           <Grid
//             item
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <Grid
//               item
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 gap: 1,
//               }}
//             >
//               <Typography
//                 fontFamily="Manrope"
//                 sx={{ fontSize: { xs: "30px", md: "34px" }, fontWeight: 400 }}
//               >
//                 Our
//               </Typography>
//               <Typography
//                 fontFamily="Tiempos Text Medium Italic"
//                 sx={{
//                   fontSize: { xs: "30px", md: "34px" },
//                   fontWeight: 400,
//                   fontStyle: "italic",
//                 }}
//               >
//                 Courses
//               </Typography>
//             </Grid>
//             <Typography
//               fontFamily="Manrope"
//               sx={{
//                 fontSize: { md: "14px", lg: "16px" },
//                 fontWeight: 400,
//                 textAlign: "center",
//                 px: {md: 8, lg: 28,  },
//                 mt: 2,
//                 lineHeight: "35px",
//               }}
//             >
//               Our lecturers are experienced, with years of expertise in
//               engineering and technology, which means they will educate you in
//               the most progressive and systematic manner possible.
//             </Typography>
//           </Grid>
//         </Grid>

//         <Grid
//           container
//           rowSpacing={1}
//           columnSpacing={{ xs: 1, sm: 2, md: 3 }}
//           sx={{ px: { xs: 2, md: 0 }, mt: 2 }}
//         >
//           <Grid item xs={12} sm={6} md={3} lg={3} sx={{ mt: 2 }}>
//             <Link to="/btech">
//               <Card
//                 sx={{
//                   display: "flex",
//                   backgroundColor: "#FFE5D2",
//                   height: 150,
//                   borderRadius: 7,
//                   boxShadow: 0,
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   sx={{ maxWidth: 155, height: 220, mt: 2 }}
//                   image={btech}
//                   alt="Live from space album cover"
//                 />
//                 <CardContent
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "start",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Typography
//                     fontFamily="Manrope"
//                     component="div"
//                     variant="h5"
//                     sx={{ fontSize: 24, fontWeight: 600 }}
//                   >
//                     B.Tech
//                   </Typography>
//                   <Typography
//                     variant="subtitle1"
//                     component="div"
//                     fontFamily="Manrope"
//                     sx={{ fontSize: 16, fontWeight: 500, mt: 1 }}
//                   >
//                     50K Enrolled
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Link>
//           </Grid>

//           <Grid item xs={12} sm={6} md={3} sx={{ mt: 2 }}>
//             <Link to="/gate">
//               <Card
//                 sx={{
//                   display: "flex",
//                   backgroundColor: "#CFFFE9",
//                   height: 150,
//                   borderRadius: 7,
//                   boxShadow: 0,
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   sx={{ width: 151, height: 220, mt: 2 }}
//                   image={gate}
//                   alt="Live from space album cover"
//                 />
//                 <CardContent
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "start",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Typography
//                     component="div"
//                     variant="h5"
//                     fontFamily="Manrope"
//                     sx={{ fontSize: 24, fontWeight: 600 }}
//                   >
//                     GATE
//                   </Typography>
//                   <Typography
//                     variant="subtitle1"
//                     component="div"
//                     fontFamily="Manrope"
//                     sx={{ fontSize: 16, fontWeight: 500, mt: 1 }}
//                   >
//                     2.4K Enrolled
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Link>
//           </Grid>

//           <Grid item xs={12} sm={6} md={3} sx={{ mt: 2 }}>
//             <Link to="/isro">
//               <Card
//                 sx={{
//                   display: "flex",
//                   backgroundColor: "#F3D1FF",
//                   height: 150,
//                   borderRadius: 7,
//                   boxShadow: 0,
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   sx={{ width: 151, height: 160 }}
//                   image={isro}
//                   alt="Live from space album cover"
//                 />
//                 <CardContent
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "start",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Typography
//                     component="div"
//                     variant="h5"
//                     fontFamily="Manrope"
//                     sx={{ fontSize: 24, fontWeight: 600 }}
//                   >
//                     ISRO
//                   </Typography>
//                   <Typography
//                     variant="subtitle1"
//                     component="div"
//                     fontFamily="Manrope"
//                     sx={{ fontSize: 16, fontWeight: 500, mt: 1 }}
//                   >
//                     1K Enrolled
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Link>
//           </Grid>

//           <Grid item xs={12} sm={6} md={3} sx={{ mt: 2 }}>
//             <Link to="">
//               <Card
//                 sx={{
//                   display: "flex",
//                   backgroundColor: "#C7DAFF",
//                   height: 150,
//                   borderRadius: 7,
//                   boxShadow: 0,
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   sx={{ width: 151, height: 230, mt: 2 }}
//                   image={ese}
//                   alt="Live from space album cover"
//                 />
//                 <CardContent
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "start",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Typography
//                     component="div"
//                     variant="h5"
//                     fontFamily="Manrope"
//                     sx={{ fontSize: 24, fontWeight: 600 }}
//                   >
//                     ESE
//                   </Typography>
//                   <Typography
//                     variant="subtitle1"
//                     component="div"
//                     fontFamily="Manrope"
//                     sx={{ fontSize: 16, fontWeight: 500, mt: 1 }}
//                   >
//                     1K Enrolled
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Link>
//           </Grid>
//         </Grid>
//         {/* <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             width: "100%",
//             mt: 4,
//           }}
//          >
//           <Button
//             fontFamily="Manrope"
//             style={{
//               backgroundColor: "#F0F0F0",
//             }}
//             sx={{
//               borderRadius: 15,
//               py: 1,
//               color: "#000000",
//               textAlign: "center",
//               px: 3,
//               fontSize: 14,
//               textTransform: "none",
//               boxShadow: "none",
//             }}
//             variant="contained"
//           >
//             Know More
//           </Button>
//         </Box> */}
//       </Grid>
//     </Box>
//   );
// };
// export default Course;
