import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import EeeGateExamPattern from "./EeeGateExamPattern";
import EeeGatePrograms from "./EeeGatePrograms";
import EeeGateSyllabus from "./EeeGateSyllabus";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EeeGateData = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const indicatorStyles = {
    display: "none",
  };

  return (
    <Box sx={{ width: "100%", px: { xs: 4, sm: 4, md: 6, lg: 8 }, mt: 4 }}>
      <Box
        sx={{
          backgroundColor: "#F6F6F6",
          borderRadius: 3,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
          TabIndicatorProps={{ style: indicatorStyles }}
          textColor="#ffffff"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tab
            label="Programs Offered"
            {...a11yProps(0)}
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              textTransform: "none",
              fontFamily: "Manrope",
              fontWeight: 600,
              color: value === 0 ? "#FFFFFF" : "#000000",
              backgroundColor: value === 0 ? "#43BA80" : "#F6F6F6",
              borderRadius: 3,
            }}
          />
          <Tab
            label="Syllabus"
            {...a11yProps(1)}
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              textTransform: "none",
              fontFamily: "Manrope",
              fontWeight: 600,
              color: value === 1 ? "#FFFFFF" : "#000000",
              backgroundColor: value === 1 ? "#43BA80" : "#F6F6F6",
              borderRadius: 3,
            }}
          />
          <Tab
            label=" Exam Patterns"
            {...a11yProps(2)}
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              textTransform: "none",
              fontFamily: "Manrope",
              fontWeight: 600,
              color: value === 2 ? "#FFFFFF" : "#000000",
              backgroundColor: value === 2 ? "#43BA80" : "#F6F6F6",
              borderRadius: 3,
            }}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <EeeGatePrograms />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <EeeGateSyllabus />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <EeeGateExamPattern />
      </CustomTabPanel>
    </Box>
  );
};

export default EeeGateData;
