import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import ContactForm from "./ContactForm";

const GetInTouch = () => {
  return (
    <Grid container sx={{ width: "100%", px: { xs: 4, md: 12 } }}>
      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          mt: 4,
        }}
      >
        <Typography
          fontFamily="Manrope"
          sx={{ fontSize: { xs: "28px", md: "34px" }, fontWeight: 400 }}
        >
          Get in
        </Typography>
        <Typography
          fontFamily="Tiempos Text Medium Italic"
          sx={{
            fontSize: { xs: "28px", md: "34px" },
            fontWeight: 400,
            fontStyle: "italic",
          }}
        >
          Touch
        </Typography>
      </Grid>
      <Grid container sx={{ width: "100%", display: { md: "flex" }, mt: 6 }}>
        <Grid item sx={{ width: { xs: "100%", md: "60%" } }}>
          <Typography
            fontFamily="Manrope"
            sx={{ fontSize: { xs: "20px", md: "28px" }, fontWeight: 600 }}
          >
            Contact Us
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: 500,
              mt: 1,
              lineHeight: "28px",
            }}
          >
            Get in touch with us and we’ll find the right <br />
            course for boosting your career.
          </Typography>
          <ContactForm/>
        </Grid>
        <Grid item sx={{ width: { xs: "100%", md: "40%" }, px: { md: 6 } }}>
          <Box>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "24px", md: "30px" },
                fontWeight: 700,
                mt: { xs: 2 },
              }}
            >
              Call Us
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontWeight: 500,
                mt: 1,
                lineHeight: "28px",
              }}
            >
              We're here to help with any questions or <br /> concerns you may
              have.
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: 700,
                mt: 1,
              }}
            >
              +91 8921 40 7459 | +91 471 358 5625
            </Typography>
          </Box>
          <Box>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "24px", md: "30px" },
                fontWeight: 700,
                mt: 6,
              }}
            >
              Write Us
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontWeight: 500,
                mt: 1,
                lineHeight: "28px",
              }}
            >
              We're here to help with any questions or <br /> concerns you may
              have.
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: 700,
                mt: 1,
              }}
            >
              support@msigmagokulam.com
            </Typography>
          </Box>
          <Box>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "24px", md: "30px" },
                fontWeight: 700,
                mt: 6,
              }}
            >
              Visit Us
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontWeight: 500,
                mt: 1,
                lineHeight: "28px",
              }}
            >
              Our team is ready to assist you. Discover our <br /> location and
              experience exceptional service today!
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: 700,
                mt: 1,
                lineHeight: "28px",
              }}
            >
              Msigma Gokulam Pvt Ltd. PNRA – 40, <br /> Opp St. Alphonsa Church,
              Priyadarshini Nagar,
              <br /> Pongumoodu, P.O, Sreekariyam, <br /> Thiruvananthapuram,
              Kerala | 695011
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GetInTouch;
