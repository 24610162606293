import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React from "react";
import { FaRegUser } from "react-icons/fa6";
import { IoCallOutline } from "react-icons/io5";
import { useFormik } from "formik";
import * as Yup from "yup";
import sendEmail from "api/sentformspark";

// Form schema
const formSchema = Yup.object({
  fullName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Full Name Required"),
  phoneNumber: Yup.string()
    .min(10, "Too Short!")
    .max(15, "Too Long!")
    .required("Phone Number Required"),
});

const SyllabusForm = () => {
  const [loading, setLoading] = React.useState(false);
  const formId = "1auD5FWaW";

  // Formik
  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
    },
    validationSchema: formSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const formData = {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
      };

      const status = await sendEmail(formData, formId);
      setLoading(false);
      if (status) {
        alert("Submitted");
        resetForm({
          values: {
            fullName: "",
            phoneNumber: "",
          },
        });
      } else {
        alert("Submission failed, please try again.");
      }
    },
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      variant="standard"
      sx={{ mt: 3, width: "100%", p: 3, px: 5 }}>
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: { xs: "16px", sm: "18px", md: "24px" },
        }}
        fontFamily="Manrope">
        Stay informed about any updates to syllabus changes and class schedules!
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Typography
          sx={{ fontWeight: "500", fontSize: "13px" }}
          fontFamily="Manrope">
          Full Name
        </Typography>
        <OutlinedInput
          value={formik.values.fullName}
          onChange={formik.handleChange("fullName")}
          onBlur={formik.handleBlur("fullName")}
          id="fullName"
          name="fullName"
          placeholder="Enter Full Name"
          sx={{
            borderRadius: 4,
            borderColor: "#F5F7F9",
            mt: 1,
            backgroundColor: "#FFFFFF",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& label": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
            "& input": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
          }}
          fullWidth
          startAdornment={
            <InputAdornment position="start" sx={{ gap: 1 }}>
              <FaRegUser />{" "}
            </InputAdornment>
          }
        />
        <Typography sx={{ color: "#fc0303", fontFamily: "Manrope" }}>
          {formik.touched.fullName && formik.errors.fullName}
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography
          sx={{ fontWeight: "500", fontSize: "13px" }}
          fontFamily="Manrope">
          Phone Number
        </Typography>
        <OutlinedInput
          value={formik.values.phoneNumber}
          onChange={formik.handleChange("phoneNumber")}
          onBlur={formik.handleBlur("phoneNumber")}
          id="phoneNumber"
          name="phoneNumber"
          placeholder="-  -  -  -  -  -  -  -  -  -"
          sx={{
            borderRadius: 4,
            borderColor: "#F5F7F9",
            mt: 1,
            backgroundColor: "#FFFFFF",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& label": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
            "& input": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
          }}
          fullWidth
          startAdornment={
            <InputAdornment position="start" sx={{ gap: 1 }}>
              <IoCallOutline />{" "}
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "15px",
                }}>
                +91{" "}
              </Typography>
            </InputAdornment>
          }
        />
        <Typography sx={{ color: "#fc0303", fontFamily: "Manrope" }}>
          {formik.touched.phoneNumber && formik.errors.phoneNumber}
        </Typography>
      </Box>

      <Button
        variant="outlined"
        type="submit"
        sx={{
          mt: 4,
          width: "100%",
          borderRadius: 4,
          borderColor: "#B4B5B9",
          backgroundColor: "#43BA80",
          py: 2,
          color: "#FFFFFF",
          textTransform: "none",
          "&:hover, &:focus": {
            border: "none",
            boxShadow: "none",
            backgroundColor: "#43BA80",
          },
          fontSize: "18px",
          fontWeight: 500,
          fontFamily: "Manrope",
        }}>
        {loading ? "Sending updates..." : "Get Updates"}
      </Button>
    </Box>
  );
};

export default SyllabusForm;
