import React from "react";
import { Typography, Grid, Box } from "@mui/material";

const Review = () => {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          pb: 2,
          px: { xs: 5, md: 30 },
        }}
      >
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "800",
            mt: 2,
            color: "#787878",
          }}
        >
          10. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it in some circumstances. To
          request to review, update, or delete your personal information, please
          send an email to support@msigmagokulam.com.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Review;
