import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/system";
import talent from "../../../assets/icons/talent.png";
import expr from "../../../assets/icons/expr.png";
import trust from "../../../assets/icons/trust.png";
import support from "../../../assets/icons/support.png";
import offline from "../../../assets/icons/offline.png";

const CustomCard = styled(Card)({
  transition: "transform 0.3s ease",
  boxShadow: "0px 34px 34px 0px rgba(0, 0, 0, 0)",
});

const about = [
  {
    title: "Empowering Talents",
    description:
      "We are Msigma Gokulam Pvt. Ltd., an online EdTech company dedicated to provide a complete solution for your academic problems",
    image: talent,
  },
  {
    title: "A decade of experience",
    description:
      "We are live from 11/01/2010, we had been conducting classes for engineering students from all technical universities of Kerala in the traditional way till 2019.",
    image: expr,
  },
  {
    title: "Trust is the other name of our team",
    description:
      "Our team is lead by Mr. Manu M Rajan, who is the CEO & founder of MSG.",
    image: trust,
  },
  {
    title: "Our Support",
    description:
      "We are backed by Sree Gokulam Group of Companies, under the chairmanship of Sree Gokulam Gopalan",
    image: support,
  },
  {
    title: "Offline to Online",
    description:
      "We were met with too many requests from students all over Kerala to conduct classes on too many subjects. It was impossible for us to take that much classes for more than 20000 students across the state. So, we had to switch online.",
    image: offline,
  },
];

const AboutIt = () => {
  return (
    <Box sx={{ mt: { xs: 6, md: 1 } }}>
      <Grid container sx={{ width: "100%", px: { xs: 4, md: 12 }, mb: 15 }}>
        <Grid container spacing={5}>
          {about.map((member, index) => (
            <Grid item md={4} sm={6} xs={12} key={index}>
              <CustomCard
                sx={{
                  maxWidth: "484px",
                  mb: { xs: -10, sm: -4, md: -6 },
                  mt: 8,
                }}
                style={{ backgroundColor: "#FFFFFF", boxShadow: "none" }}
              >
                <Box
                  sx={{
                    width: { xs: "70px", md: "90px" },
                    height: "90px",
                    mx: 2,
                  }}
                >
                  <img src={member.image} alt="" width="100%" height="auto" />
                </Box>

                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    textAlign: "justify",
                  }}
                >
                  <Typography
                    fontFamily="Manrope"
                    fontWeight="700"
                    textAlign="start"
                    sx={{ fontSize: { xs: "16px", md: "20px" } }}
                  >
                    {member.title}
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    fontWeight="500"
                    textAlign="start"
                    sx={{
                      fontSize: { xs: "13px", md: "14px" },
                      lineHeight: "36px",
                    }}
                  >
                    {member.description}
                  </Typography>
                </CardContent>
              </CustomCard>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutIt;
