import React from "react";
import { Typography, Grid, Box } from "@mui/material";

const PrPolicySummery = () => {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          pb: 8,
          px: { xs: 5, md: 30 },
        }}
      >
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "800",
            mt: 2,
            color: "#787878",
          }}
        >
          SUMMARY OF KEY POINTS
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
            textAlign: "justify",
          }}
        >
          This summary provides key points from our privacy policy, but you can
          find out more details about any of these topics by using our table of
          contents below to find the section you are looking for or you can skip
          the summary and can go directly to our table of contents.
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          What personal information do we process? When you visit, use, or
          navigate our Services, we may process personal information depending
          on how you interact with Msigma Gokulam and the Services, the choices
          you make, and the products and features you use. Go to section 1. WHAT
          INFORMATION DO WE COLLECT?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          Do we process any sensitive personal information? We may process
          sensitive personal information when necessary with your consent or as
          otherwise permitted by applicable law. Go to section 2. HOW DO WE
          PROCESS YOUR INFORMATION?
        </Typography>

        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          Do you receive any information from third parties? We do not receive
          any information from third parties. section 3. WHEN AND WITH WHOM DO
          WE SHARE YOUR PERSONAL INFORMATION?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          How do you process my information? We process your information to
          provide, improve, and administer our Services, communicate with you,
          for security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do
          so. Go to section 2. HOW DO WE PROCESS YOUR INFORMATION?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          In what situations and with which parties do we share personal
          information? We may share information in specific situations and with
          specific third parties. Go to section 3. WHEN AND WITH WHOM DO WE
          SHARE YOUR PERSONAL INFORMATION?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          How do we keep your information safe? We have organizational and
          technical processes and procedures in place to protect your personal
          information. However, no electronic transmission over the internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security and
          improperly collect, access, steal, or modify your information. Go to
          section 4. HOW LONG DO WE KEEP YOUR INFORMATION? and section 5. HOW DO
          WE KEEP YOUR INFORMATION SAFE?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          What are your rights? Depending on where you are located
          geographically, the applicable privacy law may mean you have certain
          rights regarding your personal information. Go to section 6. WHAT ARE
          YOUR PRIVACY RIGHTS? and section 7. CONTROLS FOR DO-NOT-TRACK FEATURES
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          Want to learn more about what Msigma Gokulam does with any information
          we collect?
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrPolicySummery;
