import React from "react";
import { Typography, Grid, Box } from "@mui/material";

const PolicyInfo = () => {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          pb: 4,
          px: { xs: 5, md: 30 },
        }}
      >
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "800",
            mt: 2,
            color: "#787878",
          }}
        >
          1. WHAT INFORMATION DO WE COLLECT?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "800",
            mt: 2,
            color: "#787878",
          }}
        >
          Personal information you disclose to us
        </Typography>
        <Box sx={{ display: "flex", mt: 2 }}>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: "800",
              fontStyle: "italic",
            }}
          >
            In Short:
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: "700",
              fontStyle: "italic",
            }}
          >
            We collect personal information that you provide to us.
          </Typography>
        </Box>

        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
            textAlign: "justify",
          }}
        >
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </Typography>
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "15px" },
              fontWeight: "800",
            }}
          >
            Personal Information provided by you.
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "15px" },
              fontWeight: "600",
              textAlign: "justify",
            }}
          >
            Personal Information refers to any piece of information that we, as
            a Company, can use to verify you as a real-life human being as well
            as depends on the context of your interactions with us and the
            Services, the choices you make, and the products and features you
            use. For example, e-mail ID linked to your msigma account.
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "15px" },
              fontWeight: "800",
            }}
          >
            Sensitive Information.
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "15px" },
              fontWeight: "600",
              textAlign: "justify",
            }}
          >
            Sensitive Information translates to any highly confidential
            information – i.e. records not available in the public domain – that
            you provide to us. When necessary, with your consent or as otherwise
            permitted by applicable law, we process the following categories of
            sensitive information: Student data.
          </Typography>
        </Box>

        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          Besides these, any information that is freely available, accessible in
          the public domain, furnished under the Right to Information Act, 2005
          or any other law for the time being in force – shall not be regarded
          as Sensitive Personal Data or information.
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          pb: 8,
          px: { xs: 5, md: 30 },
        }}
      >
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "800",
            mt: 2,
            color: "#787878",
          }}
        >
          Personal information you disclose to us
        </Typography>
        <Box sx={{ display: "", mt: 2 }}>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: "800",
              fontStyle: "italic",
            }}
          >
            In Short:
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: "700",
              fontStyle: "italic",
            }}
          >
            Some information — such as your browser and device characteristics —
            is collected automatically when you visit our Services.
          </Typography>
        </Box>

        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
            textAlign: "justify",
          }}
        >
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
          }}
        >
          The information we collect includes:
        </Typography>
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "15px" },
              fontWeight: "800",
            }}
          >
            Log and Usage Data.
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "15px" },
              fontWeight: "600",
              textAlign: "justify",
            }}
          >
            Log and usage data is service-related, diagnostic, usage, and
            performance information our servers automatically collect when you
            access or use our Services and which we record in log files.
            Depending on how you interact with us, this log data may or may not
            include your IP address, device information, browser type, and
            settings and information about your activity in the Services (such
            as the date/time stamps associated with your usage, pages and files
            viewed, searches, and other actions you take such as which features
            you use), device event information (such as system activity, error
            reports (sometimes called crash dumps), and hardware settings).
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "15px" },
              fontWeight: "800",
            }}
          >
            Device Data.
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "15px" },
              fontWeight: "600",
              textAlign: "justify",
            }}
          >
            We collect device data such as information about your computer,
            phone, tablet, or other device you use to access the Services.
            Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PolicyInfo;
