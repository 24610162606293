import PrPolicyBanner from "components/PrivacyPolicy/PrBanner/PrPolicyBanner";
import ContactDetails from "components/PrivacyPolicy/PrData/ContactDetails";
import Controlls from "components/PrivacyPolicy/PrData/Controlls";
import InfoProcess from "components/PrivacyPolicy/PrData/InfoProcess";
import InfoShare from "components/PrivacyPolicy/PrData/InfoShare";
import KeepInfo from "components/PrivacyPolicy/PrData/KeepInfo";
import PolicyInfo from "components/PrivacyPolicy/PrData/PolicyInfo";
import PrPolTableofContent from "components/PrivacyPolicy/PrData/PrPolTableofContent";
import PrPolicyData from "components/PrivacyPolicy/PrData/PrPolicyData";
import PrPolicySummery from "components/PrivacyPolicy/PrData/PrPolicySummery";
import PrivacyRight from "components/PrivacyPolicy/PrData/PrivacyRight";
import Review from "components/PrivacyPolicy/PrData/Review";
import SafeInfo from "components/PrivacyPolicy/PrData/SafeInfo";
import Updates from "components/PrivacyPolicy/PrData/Updates";

const PrivacyPolicy = () => {
  return (
    <>
      <PrPolicyBanner />
      <PrPolicyData />
      <PrPolicySummery />
      <PrPolTableofContent />
      <PolicyInfo />
      <InfoProcess />
      <InfoShare />
      <KeepInfo />
      <SafeInfo />
      <PrivacyRight/>
      <Controlls/>
      <Updates/>
      <ContactDetails/>
      <Review/>
    </>
  );
};

export default PrivacyPolicy;
