import { Typography, Box, Grid, Button } from "@mui/material";
import style from "./Banner.module.scss";
import students from "../../../assets/images/students.png";
import gg from "../../../assets/images/b2.png";
import manu from "../../../assets/images/b3.png";
import app from "../../../assets/logo/app.png";
import play from "../../../assets/logo/play.png";
import gokulam from "../../../assets/logo/gg-s.png";
import { Link } from "react-router-dom";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { createTheme, ThemeProvider } from "@mui/material";

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1000,
    lg: 1280,
  },
};

const theme = createTheme({
  breakpoints: { ...breakpoints },
});

const items = [
  {
    bannerImg: gg,
  },
  {
    bannerImg: manu,
  },
];

const Banner = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box className={style.deskTopWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
              py: 4,
            }}
          >
            <Grid
              item
              sx={{
                width: { xs: "100%", md: "50%" },
                px: 12,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#D5F0E3",
                  width: "236px",
                  height: "33px",
                  borderRadius: "31px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { xs: "10px", md: "13px" },
                    color: "#01381D",
                    fontWeight: "500",
                    py: { xs: 1, md: 1.5 },
                  }}
                >
                  LARGEST LEARNING PLATFORM
                </Typography>
              </Box>
              <Box sx={{mt:1}}>
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    fontSize: { md: "48px", lg: "58px" },
                    fontWeight: "500",
                    lineHeight: 1.2,
                    fontStyle: "italic",
                  }}
                >
                  Building the Nation
                </Typography>
                
                <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { md: "48px", lg: "58px" },
                      fontWeight: "500",
                     
                      lineHeight: 1.2,
                    }}
                  >
                  By Crafting  Best
                  </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { md: "48px", lg: "58px" },
                    fontWeight: "500",
                    
                    lineHeight: 1.2,
                  }}
                >
                 Engineers
                </Typography>
                
              </Box>
              <Box sx={{ display: "flex", gap: 3, alignItems: "center", mt:4}}>
              <Link to="https://play.google.com/store/apps/details?id=in.mgate.msigma">
                      <img src={play} alt="" width="170px" />
                    </Link>
                    <Link to="https://apps.apple.com/in/app/msigma/id6463402970">
                      <img src={app} alt="" width="170px" />
                    </Link>
                    
                  </Box>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 2,
                  width: "100%",
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                 >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { sm: "start", md: "space-between" },
                    }}
                  >
                    <img src={students} alt="Students" />
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: "16px",
                        color: "#01381D",
                        fontWeight: "400",
                      }}
                    >
                      10k+ Students <br /> Downloads
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  mt: 3,
                  gap: 2,
                }}
              >
                {" "}
                <Link to="https://learn.msigmagokulam.com/" target="_blank">
                  <Button
                    fontFamily="Manrope"
                    style={{
                      backgroundColor: "#3CBA80",
                    }}
                    sx={{
                      width: "190px",
                      height: "60px",
                      borderRadius: 16,
                      py: 1.5,
                      color: "#FFFFFF",
                      textAlign: "center",
                      px: 2,
                      fontSize: 14,
                      textTransform: "none",
                    }}
                  >
                    Explore All Courses
                  </Button>
                </Link>
                {/* <img src={gokualm} alt="" /> */}
              </Box>
            </Grid>
            <Grid
              item
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: -4.4,
              }}
            >
              <Swiper
                style={{
                  "--swiper-pagination-color": "#43BA80",
                  "--swiper-pagination-bullet-inactive-color": "#E9EEEC",

                  "--swiper-pagination-bullet-size": "7px",
                  "--swiper-pagination-bullet-horizontal-gap": "4px",
                  "--swiper-pagination-bullet-margin-top": "10px",
                }}
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={200}
                slidesPerView={1}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
              >
                {items.map((member, index) => (
                  <SwiperSlide key={index}>
                    <img src={member.bannerImg} alt="BannerImage" width="80%" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={style.tabletWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
              py: 4,
            }}
          >
            <Grid
              item
              sx={{
                width: "50%",
                px: 4,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#D5F0E3",
                  width: "216px",
                  height: "33px",
                  borderRadius: "31px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: "13px",
                    color: "#01381D",
                    fontWeight: "500",
                    py: { xs: 1, md: 1.5 },
                  }}
                >
                  LARGEST LEARNING PLATFORM
                </Typography>
              </Box>
              <Box sx={{mt:1}}>
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    fontSize: "33px" ,
                    fontWeight: "500",
                    lineHeight: 1.2,
                    fontStyle: "italic",
                  }}
                >
                  Building the Nation
                </Typography>
                
                <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: "33px" ,
                      fontWeight: "500",
                     
                      lineHeight: 1.2,
                    }}
                  >
                  By Crafting  Best
                  </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: "33px" ,
                    fontWeight: "500",
                    
                    lineHeight: 1.2,
                  }}
                >
                 Engineers
                </Typography>
                
              </Box>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 2,
                  width: "100%",
                }}
              >
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { sm: "start", md: "space-between" },
                    }}
                  >
                    <img src={students} alt="Student" />
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: "16px",
                        color: "#01381D",
                        fontWeight: "400",
                      }}
                    >
                      10k+ Students <br /> Downloads
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  mt: 1,
                  gap: 1,
                }}
              >
                {" "}
                <Link to="https://learn.msigmagokulam.com/" target="_blank">
                  <Button
                    fontFamily="Manrope"
                    style={{
                      backgroundColor: "#3CBA80",
                    }}
                    sx={{
                      width: "150px",
                      height: "45px",
                      borderRadius: 16,
                      py: 1.5,
                      color: "#FFFFFF",
                      textAlign: "center",
                      px: 2,
                      fontSize: 12,
                      textTransform: "none",
                    }}
                  >
                    Explore All Courses
                  </Button>
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              sx={{
                width: "50%",
              }}
            >
              <Swiper
                style={{
                  "--swiper-pagination-color": "#43BA80",
                  "--swiper-pagination-bullet-inactive-color": "#E9EEEC",

                  "--swiper-pagination-bullet-size": "7px",
                  "--swiper-pagination-bullet-horizontal-gap": "4px",
                }}
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                slidesPerView={1}
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
              >
                {items.map((member, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={member.bannerImg}
                      alt="BannerImage"
                      width="100%"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={style.responsiveWrapper}>
        <Grid
          container
          sx={{
            py: 3,
            mt: 2,
          }}
        >
          <Grid
            item
            sx={{
              width: "100%",
              px: 4,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#D5F0E3",
                width: "171px",
                borderRadius: "31px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: "10px",
                  color: "#01381D",
                  fontWeight: "500",
                  py: 1,
                }}
              >
                LARGEST LEARNING PLATFORM
              </Typography>
            </Box>
            <Box sx={{mt:1}}>
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    fontSize: "33px" ,
                    fontWeight: "500",
                    lineHeight: 1.2,
                    fontStyle: "italic",
                  }}
                >
                  Building the Nation
                </Typography>
                
                <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: "33px" ,
                      fontWeight: "500",
                     
                      lineHeight: 1.2,
                    }}
                  >
                  By Crafting  Best
                  </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: "33px" ,
                    fontWeight: "500",
                    
                    lineHeight: 1.2,
                  }}
                >
                 Engineers
                </Typography>
                
              </Box>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 2,
                width: "100%",
              }}
            >
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img src={students} alt="Student" width="90px" />
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: "10px",
                        color: "#01381D",
                        fontWeight: "500",
                      }}
                    >
                      10k+ Students <br /> Downloads
                    </Typography>
                  </Box>
                  <img src={gokulam} alt="GG" width="110px" />
                </Box>
                <Box sx={{ display: "flex", gap: 1, my: 4 }}>
                  <Link to="https://apps.apple.com/in/app/msigma/id6463402970">
                    <img src={app} alt="AppStore" width="150px" />
                  </Link>
                  <Link to="https://play.google.com/store/apps/details?id=in.mgate.msigma">
                    <img src={play} alt="PlayStore" width="150px" />
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 12,
            }}
          >
            <Swiper
              style={{
                "--swiper-pagination-color": "#43BA80",
                "--swiper-pagination-bullet-inactive-color": "#E9EEEC",

                "--swiper-pagination-bullet-size": "7px",
                "--swiper-pagination-bullet-horizontal-gap": "4px",
              }}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              spaceBetween={200}
              slidesPerView={1}
              pagination={{ clickable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            >
              {items.map((member, index) => (
                <SwiperSlide key={index}>
                  <img src={member.bannerImg} alt="BannerImage" width="390px" />
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Banner;
