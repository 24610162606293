import React from "react";
import { Typography, Grid, Box } from "@mui/material";

const SafeInfo = () => {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          pb: 8,
          px: { xs: 5, md: 30 },
        }}
      >
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "800",
            mt: 2,
            color: "#787878",
          }}
        >
          5. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </Typography>
        <Box sx={{ display: "", mt: 2 }}>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: "800",
              fontStyle: "italic",
            }}
          >
            In Short:
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: "700",
              fontStyle: "italic",
            }}
          >
            We aim to protect your personal information through a system of
            organizational and technical security measures.
          </Typography>
        </Box>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SafeInfo;
