import React from 'react'
import EceData from 'components/CourseDetails/Gate/Electronics/EceData'
import EceGateBanner from 'components/CourseDetails/Banners/EceGate/EceGateBanner'

const EceGate = () => {
  return (
    <>
      <EceGateBanner/>
      <EceData/>
    </>
  )
}

export default EceGate
