import React from "react";
import { Typography, Grid, Box } from "@mui/material";

const ContactDetails = () => {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          pb: 8,
          px: { xs: 5, md: 30 },
        }}
      >
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "800",
            mt: 2,
            color: "#787878",
          }}
        >
          9. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          If you have questions or comments about this policy, you may email us
          at support@msigmagokulam.com or by post to:
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          Msigma Gokulam Pvt. Ltd. <br />
           PNRA - 40 <br />
            Priyadarshini Nagar, Pongumoodu,P.O <br />
             Sreekariyam, Thiruvananthapuram, Kerala 695011 <br /> India
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ContactDetails;
