import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";

import manu from "../../../assets/images/manu.png";
import gg from "../../../assets/images/gg.png";
import renjan from "../../../assets/images/renjanRoy.png";
import dummy from "../../../assets/images/dummy.png";
import divya from "../../../assets/images/divya.jpg";
import janaki from "../../../assets/images/janakiraman.jpg";
import lijisha from "../../../assets/images/lijisha.jpg";
import rasool from "../../../assets/images/rasul.jpg";
import seetha from "../../../assets/images/seetha.jpg";

const ourTeam = [
  {
    name: "Gokulam Gopalan",
    position: "Chairman",
    image: gg,
  },
  {
    name: "Manu M Rajan",
    position: "CEO & Executive Director",
    image: manu,
  },
  {
    name: "Seetha Raj",
    position: "COO & Director",
    image: seetha,
  },
  {
    name: "Lijisha Gopalan",
    position: "Director",
    image: lijisha,
  },
  {
    name: "Divya Baiju",
    position: "Director",
    image: divya,
  },
  {
    name: "Renjan Roy RU",
    position: "CAO ",
    image: renjan,
  },
  {
    name: "Rasul Khan F",
    position: "Sr. Administrative Coordinator",
    image: rasool,
  },
];

const Team = () => {
  return (
    <Box sx={{ mt: 16 }}>
      <Grid container sx={{ width: "100%", px: { xs: 6, md: 12 }, mb: 15 }}>
        <Grid container justifyContent="center">
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: { xs: "28px", md: "34px" }, fontWeight: 400 }}
              >
                Meet our
              </Typography>
              <Typography
                fontFamily="Tiempos Text Medium Italic"
                sx={{
                  fontSize: { xs: "28px", md: "34px" },
                  fontWeight: 400,
                  fontStyle: "italic",
                }}
              >
                Team
              </Typography>
            </Grid>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: 500,
                textAlign: "center",
                px: { sm: 10, md: 40 },
                mt: 2,
              }}
            >
              Not just highly skilled, our teachers are highly sincere and
              devoted to teaching and to help you achieve your dreams.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{
            mt: 1,
          }}
        >
          {ourTeam.map((member, index) => (
            <Grid item md={2.4} sm={4} xs={12} key={index}>
              <Card
                sx={{ maxWidth: "379px", height: "536" }}
                style={{ backgroundColor: "#FFFFFF", boxShadow: "none" }}
              >
                <CardMedia
                  component="img"
                  image={member.image}
                  alt={member.name}
                />
                <CardContent>
                  <Typography
                    fontFamily="Manrope"
                    fontSize="20px"
                    fontWeight="500"
                    textAlign="center"
                  >
                    {member.name}
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    fontSize="16px"
                    fontWeight="400"
                    textAlign="center"
                  >
                    {member.position}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Team;
