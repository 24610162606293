import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import style from "./AboutBanner.module.scss";

const AboutBanner = () => {
  return (
    <Box>
      <Box className={style.deskTopWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
              py: 10,
            }}>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                width: "100%",
                p: 20,
              }}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "30px",
                    fontWeight: "700",
                  }}>
                  Gokulam Gopalan & Manu M Rajan
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "28px",
                  mt: 2,
                }}>
                Two visionaries with a revolutionary <br /> change in
                engineering
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={style.tabletWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
            }}>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                width: "100%",
                p: 10,
              }}>
              <Box sx={{ display: "flex", mb: 30, flexDirection: "column" }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "24px",
                    fontWeight: "700",
                  }}>
                  Gokulam Gopalan & Manu M Rajan
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "16px",
                    fontWeight: "500",
                    mt: 2,
                  }}>
                  Two visionaries with a revolutionary change in engineering
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <div className={style.responsiveWrapper}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "70vh",
            px: 3,
          }}>
          <Box>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                width: "100%",
              }}>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  width: "100%",
                }}>
                <Box sx={{ mb: 24 }}>
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      color: "#ffffff",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}>
                    Gokulam Gopalan & Manu M Rajan
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      color: "#ffffff",
                      fontSize: "15px",
                      fontWeight: "500",
                      lineHeight: "18px",
                      mt: 1,
                    }}>
                    Two visionaries with a revolutionary <br /> change in
                    engineering
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </div>
    </Box>
  );
};

export default AboutBanner;
