import React from "react";
import { Typography, Grid, Box } from "@mui/material";

const PrPolTableofContent = () => {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          pb: 8,
          px: { xs: 5, md: 30 },
        }}
      >
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "800",
            mt: 2,
            color: "#787878",
          }}
        >
          TABLE OF CONTENTS
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
          }}
        >
          1. WHAT INFORMATION DO WE COLLECT?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
          }}
        >
          1. WHAT INFORMATION DO WE COLLECT?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
          }}
        >
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
          }}
        >
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
          }}
        >
          4. HOW LONG DO WE KEEP YOUR INFORMATION?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
          }}
        >
          5. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
          }}
        >
          6. WHAT ARE YOUR PRIVACY RIGHTS?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
          }}
        >
          7. CONTROLS FOR DO-NOT-TRACK FEATURES
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
          }}
        >
          8. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
          }}
        >
          9. DO WE MAKE UPDATES TO THIS POLICY?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
          }}
        >
          10. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
        </Typography>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 2,
          }}
        >
          11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrPolTableofContent;
