import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import style from "./CivilGateBanner.module.scss";

const CivilGateBanner = () => {
  return (
    <Box  sx={{p:4, px:{xs:4,sm:4, md:6, lg:8}}}>
      
      <Box className={style.deskTopWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                width: "63%",
                p: 6,
                px: 15,
              }}
             >
              <Box sx={{ display: "flex" }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "32px", fontWeight: "700" }}
                >
                  Civil Engineering
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "36px",
                  mt: 2,
                }}
              >
                Our GATE Civil Engineering course is led by Mr. Charlies Leo
                Panandan, not just the cool name, his journey from being a
                Military Engineering Service Officer to being a teacher just
                because teaching was his passion is also pretty cool. Along with
                the Msigma Way of Learning, which makes learning effective and
                efficient for all different kinds of students, our method has
                been researched for 3 years and crafted by 16 of India’s best
                engineering minds. With a combined experience of over 3 decades,
                our faculty panel includes extraordinary individuals. Through
                the Msigma way of learning, cracking GATE will be the least of
                your worries.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={style.tabletWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                width: "63%",
                px: 4,
                py:10,
                pr:12
              }}
             >
              <Box sx={{ display: "flex" }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "32px", fontWeight: "700" }}
                >
                  Civil Engineering
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "36px",
                  mt: 2,
                }}
              >
                Our GATE Civil Engineering course is led by Mr. Charlies Leo
                Panandan, not just the cool name, his journey from being a
                Military Engineering Service Officer to being a teacher just
                because teaching was his passion is also pretty cool. 
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <div className={style.responsiveWrapper}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100vh",
            px: 3,
          }}
        >
          <Box>
          <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                width: "100%",
                mb:38,
              }}
             >
              <Box sx={{ display: "flex" }}>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "700" }}
                >
                  Civil Engineering
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "30px",
                }}
              >
                Our GATE Civil Engineering course is led by Mr. Charlies Leo
                Panandan, not just the cool name, his journey from being a
                Military Engineering Service Officer to being a teacher just
                because teaching was his passion is also pretty cool.
                
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </div>

    </Box>
  );
};

export default CivilGateBanner;
