import GetInTouch from 'components/Contact/GetInTouch'
import Map from 'components/Contact/Map'
import React from 'react'

const ContactUs = () => {
  return (
    <>
      <GetInTouch/>
      <Map/>
    </>
  )
}

export default ContactUs
