const ceSyllabus = [
  {
    section: "Section 1: Engineering Mathematics",
    topics: [
      "Linear Algebra",
      "Differential Equations",
      "Complex variables",
      "Probability and Statistics",
      "Numerical Methods",
    ],
  },
  {
    section: "Section 2: Structural Engineering",
    topics: [
      "Engineering Mechanics",
      "Solid Mechanics",
      "Structural Analysis",
      "Concrete Structures",
      "Steel Structures",
    ],
  },
  {
    section: "Section 3: Geotechnical Engineering",
    topics: ["Soil Mechanics", "Foundation Engineering"],
  },
  {
    section: "Section 4: Water Resources Engineering",
    topics: ["Fluid Mechanics", "Hydraulics", "Hydrology", "Irrigation"],
  },
  {
    section: "Section 5: Environmental Engineering",
    topics: [
      "Water and Waste Water Quality and Treatment",
      "Air Pollution",
      "Municipal Solid Wastes",
    ],
  },
  {
    section: "Section 6: Transportation Engineering",
    topics: [
      " Transportation Infrastructure",
      " Highway Pavements",
      " Traffic Engineering",
    ],
  },
  {
    section: "Section 7: Geomatics Engineering",
    topics: [
      "Principles of surveying; Errors and their adjustment; Maps - scale, coordinate system; Distance and angle measurement - Leveling and trigonometric leveling; Traversing and triangulation survey; Total station; Horizontal and vertical curves. Photogrammetry and Remote Sensing - Scale, flying height; Basics of remote sensing and GIS.",
    ],
  },
];

export default ceSyllabus;
