import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import manu from "../../../assets/images/student.png";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { createTheme, ThemeProvider } from "@mui/material";
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1200,
    lg: 1280,
  },
};

const theme = createTheme({
  breakpoints: { ...breakpoints },
});

const allMasters = [
  {
    description:
      " Sir vere level aan..examnu one week mumb aa sirnte class YouTube il kandathu..sirnte full class kananam enn ella..aka pakuthyil thaza class mathree njn kandollu.. signals and systems pass aay      ",
    image: manu,
    name: "Robin RJ Adoor",
  },
  {
    description:
      "poli aanu signal and system 2nd try pass control system first try pass🔥❤️❤️ 3 മാസം കൊണ്ട് collagil പഠിക്കുന്നത് nan 10 day kond pass ആയി 🔥🔥      ",
    image: manu,
    name: "Achus",
  },

  {
    description:
      "This app was a life saver for me to clear DSP paper. Thanks a lot to Manu sir and the idea of MSigma app. I highly recommend this for anyone who wants to get good marks in DSP and SAS.",
    image: manu,
    name: "Jestin George",
  },
  {
    description:
      "Excellent classes...... Concepts can be learnt from its basics...... I would highly recommend it to all ktu students having signals and systems....... Superb classes🤩.......      ",
    image: manu,
    name: "Navneetha Anil",
  },
  {
    description:
      " I installed this application after watching tutorial video of manu sir on YouTube. Before I enrolling I called manu sir and he said that “abhin trust me you will call me happily after your exam”. And Yes it happened 😍. ",
    image: manu,
    name: "Abhindas U",
  },

  {
    description:
      " Signals and systems ൻ്റെ ABCD പോലും അറിയാത്തവർക്കും, കോളേജിൽ ഒരു ക്ലാസ്സ് പോലും കിട്ടാത്തവർക്കും സുഖസുന്ദരമായി പാസ്സാവാം....iam guaranteed✌️      ",
    image: manu,
    name: "Jesbin Jose    ",
  },
  {
    description:
      " I just wrote my easiest B.Tech exam yesterday! Never knew Signals and System could be this easy. Thanks to Manu Sir and everyone who worked behind the app. ❤️      ",
    image: manu,
    name: "Arshid Vinod",
  },
  {
    description:
      " Lectures by Manu sir made the difficult subject into interesting and fun..thank you sir for your sincerity in teaching      .",
    image: manu,
    name: "Krishna Gopan",
  },
  {
    description:
      "♥️♥️♥️The best ♥️♥️♥️! I’m rating this app right after seeing my exam result 😍😍 Thank you Sir!",
    image: manu,
    name: "Aswin",
  },
];

const Student = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ py: { xs: 8, md: 16 }, backgroundColor: "#FAF8F3" }}>
        <Grid container sx={{ width: "100%", px: { xs: 1, md: 2 } }}>
          <Grid container>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  width: "100%",
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{ fontSize: { xs: "24px", md: "34px" }, fontWeight: 400 }}
                >
                  What our
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    fontSize: { xs: "24px", md: "34px" },
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                >
                  Students Says
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
              mt: 5,
            }}
          >
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={200}
              slidesPerView={4}
              navigation
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              {allMasters.map((member, index) => (
                <SwiperSlide key={index}>
                  <Card
                    sx={{
                     width: { xs: "250px", md: "389px" },
                    }}
                    style={{ backgroundColor: "#FFFFFF", boxShadow: "none" }}
                  >
                    <CardContent>
                      <Box sx={{ height: { md: "120px" } }}>
                        <Typography
                          fontFamily="Manrope"
                          fontWeight="400"
                          textAlign="left"
                          sx={{
                            fontSize: { xs: "13px", md: "14px" },
                            lineHeight: { xs: "24px", md: "34px" },
                          }}
                        >
                          {member.description}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          mt: 4,
                        }}
                      >
                        <CardMedia
                          sx={{
                            width: "46px",
                            height: "46px",
                            borderRadius: "50%",
                          }}
                          component="img"
                          image={member.image}
                          alt={member.name}
                        />
                        <Typography
                          fontFamily="Manrope"
                          fontWeight="500"
                          textAlign="center"
                          sx={{ fontSize: { xs: "14px", md: "16px" } }}
                        >
                          {member.name}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
          <Grid
            container
            sx={{
              display: { xs: "none", sm: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
              mt: 5,
            }}
          >
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={300}
              slidesPerView={3}
              pagination={{ clickable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              {allMasters.map((member, index) => (
                <SwiperSlide key={index}>
                  <Card
                    sx={{
                      width: "349px",
                    }}
                    style={{ backgroundColor: "#FFFFFF", boxShadow: "none" }}
                  >
                    <CardContent>
                      <Box sx={{}}>
                        <Typography
                          fontFamily="Manrope"
                          fontWeight="400"
                          textAlign="left"
                          sx={{
                            fontSize: { xs: "13px", md: "14px" },
                            lineHeight: { xs: "24px", md: "34px" },
                          }}
                        >
                          {member.description}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          mt: 4,
                          gap: 2,
                        }}
                      >
                        <CardMedia
                          sx={{
                            width: "46px",
                            height: "46px",
                            borderRadius: "50%",
                          }}
                          component="img"
                          image={member.image}
                          alt={member.name}
                        />
                        <Typography
                          fontFamily="Manrope"
                          fontWeight="500"
                          textAlign="center"
                          sx={{ fontSize: { xs: "16px", md: "20px" } }}
                        >
                          {member.name}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
          <Grid
            container
            sx={{
              display: { xs: "flex", sm: "none", md: "none" },
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
              mt: 3,
            }}
          >
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              {allMasters.map((member, index) => (
                <SwiperSlide key={index}>
                  <Card
                    sx={{
                      width: "389px",
                    }}
                    style={{ backgroundColor: "#FFFFFF", boxShadow: "none" }}
                  >
                    <CardContent>
                      <Box>
                        <Typography
                          fontFamily="Manrope"
                          fontWeight="400"
                          textAlign="left"
                          sx={{
                            fontSize: { xs: "13px", md: "14px" },
                            lineHeight: { xs: "24px", md: "34px" },
                          }}
                        >
                          {member.description}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          mt: 2,
                          gap: 2,
                        }}
                      >
                        <CardMedia
                          sx={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                          }}
                          component="img"
                          image={member.image}
                          alt={member.name}
                        />
                        <Typography
                          fontFamily="Manrope"
                          fontWeight="500"
                          textAlign="center"
                          sx={{ fontSize: { xs: "16px", md: "20px" } }}
                        >
                          {member.name}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Student;
