import React from 'react'
import IsroBanner from 'components/CourseDetails/Banners/Isro/IsroBanner'
import IsroData from 'components/CourseDetails/ISRO/IsroData'

const IsroDetails = () => {
  return (
    <>
      <IsroBanner/>
      <IsroData/>
    </>
  )
}

export default IsroDetails
