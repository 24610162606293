import { Box, Grid, Typography, Card, CardContent } from "@mui/material";

const gate = [
  {
    title: "Exam Pattern",
    text1: "Examination Mode - Computer Based Test (Online)",
    text2: "Duration - 3 Hours",
    text3: "Sections - 2: General Aptitude (GA) and Course Subject",
  },
  {
    title: "Number of Questions",
    text1: "10 (GA) + 55 (subject) = 65 Questions",
    text2: "Total Marks - 100 Marks",
    text3: "Marking Scheme - All of the questions will be worth 1 or 2 marks",
  },
  {
    title: "There is no negative mark for",
    text1: "● Multiple Select Questions (MSQ)",
    text2: "● Numerical Answer Type (NAT)",
    text3: "",
  },
  {
    title: "Type of Questions",
    text1: "Multiple Choice Questions (MCQs)",
    text2: "Multiple Select Questions (MSQ), and",
    text3: "Numerical Answer Type Questions (NAT)",
  },
  {
    title: "Design of Questions",
    text1: "Application, Analysis, synthesis, Comprehension, and Recall",
    text2: "",
    text3: "",
  },
  {
    title: "Negative Marks",
    text1: "Multiple Choice Questions (MCQ)",
    text2: "● For 1-mark question, ⅓",
    text3: "● For 2 marks question, ⅔",
  },
];
const CeGateExam = () => {
  return (
    <Box sx={{ mt: { xs: 1, md: 2 } }}>
      <Grid container sx={{ width: "100%" }}>
        <Grid container spacing={2}>
          {gate.map((member, index) => (
            <Grid item md={4} sm={6} xs={12} key={index}>
              <Card
                sx={{ maxWidth: "484px" }}
                style={{ backgroundColor: "#FFFFFF", boxShadow: "none" }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                  }}
                >
                  <Typography
                    fontFamily="Manrope"
                    fontWeight="700"
                    textAlign="start"
                    sx={{ fontSize: { xs: "18px", md: "20px" } }}
                  >
                    {member.title}
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    fontWeight="500"
                    textAlign="start"
                    sx={{ fontSize: { xs: "13px", md: "14px" }, mt: 2 }}
                  >
                    {member.text1}
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    fontWeight="500"
                    textAlign="start"
                    sx={{ fontSize: { xs: "13px", md: "14px" }, mt: 2 }}
                  >
                    {member.text2}
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    fontWeight="500"
                    textAlign="start"
                    sx={{ fontSize: { xs: "13px", md: "14px" }, mt: 2 }}
                  >
                    {member.text3}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CeGateExam;
