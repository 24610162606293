import React, { useEffect } from "react";

const QrCode = () => {
  useEffect(() => {
    const redirectToAppStore = () => {
      if (isAndroid()) {
        window.location.href =
          "https://play.google.com/store/apps/details?id=in.mgate.msigma";
      } else if (isIOS()) {
        window.location.href =
          "https://apps.apple.com/in/app/msigma/id6463402970";
      } else {
        alert("This app is not supported on your device.");
      }
    };

    const isAndroid = () => {
      return /Android/i.test(navigator.userAgent);
    };

    const isIOS = () => {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    };

    redirectToAppStore();
  }, []);

  return <div>redirecting...</div>;
};

export default QrCode;
