import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";

import { FaCheck } from "react-icons/fa6";
import calender from "../../../../assets/icons/calender.png";
import language from "../../../../assets/icons/language.png";
import gateEce from "./gateEce";
import { Link } from "react-router-dom";

const EcePrograms = () => {
  return (
    <Box sx={{ mt: { xs: 1, md: 2 } }}>
      <Grid container sx={{ width: "100%" }}>
        <Grid container spacing={3}>
          {gateEce.map((item, index) => (
            <Grid item md={4} sm={6} xs={12} key={index}>
              <Card  sx={{ maxWidth: 455, boxShadow:'10px', borderRadius:'14px' }}>
                <CardMedia
                  component="img"
                  height="210px"
                  image={item.thumb}
                  alt="green iguana"
                  sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                />
                <CardMedia
                  component="img"
                  height="170"
                  image={item.thumb}
                  alt="green iguana"
                  sx={{ display: { xs: "none", sm: "flex", md: "none" } }}
                />
                <CardMedia
                  component="img"
                  height="140"
                  image={item.thumb}
                  alt="green iguana"
                  sx={{ display: { xs: "flex", sm: "none", md: "none" } }}
                />
                <CardContent sx={{ height: { sm: 555, md: 520 } }}>
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { xs: "18px", md: "24px" },
                      fontWeight: "600",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: 1,
                      mt: 2,
                    }}
                  >
                    <img src={calender} alt="" width="16px" height="15px" />
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        display: "flex",
                        align: "center",
                        gap: 0.5,
                      }}
                    >
                      Ends on - {item.endsOn}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: 1,
                      mt: 2,
                    }}
                  >
                    <img src={language} alt="" width="16px" height="15px" />
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        display: "flex",
                        align: "center",
                        gap: 0.5,
                      }}
                    >
                      Language - {item.language}
                    </Typography>
                  </Box>
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { xs: "16px", md: "20px" },
                      fontWeight: "700",
                      color: "#43BA80",
                      mt: 2,
                    }}
                  >
                   ₹ {item.prize}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    {item?.features?.map((feature) => (
                      <Typography
                        fontFamily="Manrope"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          mt: 2,
                          display: "flex",
                          align: "center",
                          gap: 0.5,
                        }}
                      >
                        <FaCheck fontSize="18px" color="#43BA80" />
                        {feature}
                      </Typography>
                    ))}
                  </Box>
                </CardContent>
                <Box sx={{  p:3, mt:4 }}>
                  <Link
                    to="https://learn.msigmagokulam.com/subjects/24?courseId=5"
                    target="_blank"
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        mt: 3,
                        width: "100%",
                        borderRadius: 4,
                        borderColor: "#000000",
                        backgroundColor: "#FFFFFF",
                        py: 2,
                        color: "#000000",
                        textTransform: "none",
                        "&:hover, &:focus": {
                          border: "none",
                          boxShadow: "none",
                          backgroundColor: "#F7F7F7",
                        },
                        fontSize: "18px",
                        fontWeight: 500,
                        fontFamily: "Manrope",
                      }}
                    >
                      Join Now
                    </Button>
                  </Link>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default EcePrograms;
