import ceSilver from "../../../../assets/thumbnails/gate/ceSilver.png";
import ceGold from "../../../../assets/thumbnails/gate/ceGold.png";
import cePlatinum from "../../../../assets/thumbnails/gate/cePlatinum.png";

const gatCe = [
  {
    thumb: ceSilver,
    title: "GATE Civil Engineering",
    plan: "Silver",
    prize: "15,000",
    endsOn: "28 February 2025",
    language: "English | Malayalam",
    features: [
      "500+ Hours of State-of-the-Art Studio Recorded Classes.",
      "Assesscent Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
    ],
  },
  {
    thumb: ceGold,
    title: "GATE Civil Engineering",
    plan: "Gold",
    prize: "20,000",
    endsOn: "28 February 2025",
    language: "English | Malayalam",
    features: [
      "500+ Hours Studio Recorded Classes.",
      "Hard Copy of Study Materials.",
      "Assesscent Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
    ],
  },
  {
    thumb: cePlatinum,
    title: "GATE Civil Engineering",
    plan: "Platinum",
    prize: "27,000",
    endsOn: "28 February 2025",
    language: "English | Malayalam",
    features: [
      "150+ Hours of Live Crux Synopsis Conclusion Class.",
      "500+ Hours of Studio Recorded Classes .",
      "Hard Copy of Study Materials.",
      "Assesscent Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace.",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
      "Rceive a Tailored Study Plan.",
      "Concise Revision Notes.",
    ],
  },
  {
    thumb: ceSilver,
    title: "GATE Civil Engineering",
    plan: "Silver",
    prize: "15,000",
    endsOn: "28 February 2025",
    language: "English ",
    features: [
      "500+ Hours of State-of-the-Art Studio Recorded Classes.",
      "Assesscent Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
    ],
  },
  {
    thumb: ceGold,
    title: "GATE Civil Engineering",
    plan: "Gold",
    prize: "20,000",
    endsOn: "28 February 2025",
    language: "English ",
    features: [
      "500+ Hours Studio Recorded Classes.",
      "Assesscent Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
    ],
  },
  {
    thumb: cePlatinum,
    title: "GATE Civil Engineering",
    plan: "Platinum",
    prize: "27,000",
    endsOn: "28 February 2025",
    language: "English ",
    features: [
      "150+ Hours of Live Crux Synopsis Conclusion Class.",
      "500+ Hours of Studio Recorded Classes .",
      "Hard Copy of Study Materials.",
      "Assesscent Tests.",
      "GATE Replica Mock Test.",
      "Learn At Your Pace.",
      "Access to Desktop Learning.",
      "Doubt Clearance Sessions.",
      "Rceive a Tailored Study Plan.",
      "Concise Revision Notes.",
    ],
  },
];

export default gatCe;