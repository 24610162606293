import React from "react";
import { Typography, Grid, Box } from "@mui/material";

const InfoShare = () => {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          pb: 8,
          px: { xs: 5, md: 30 },
        }}
      >
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "800",
            mt: 2,
            color: "#787878",
          }}
        >
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </Typography>
        <Box sx={{ display: "", mt: 2 }}>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: "800",
              fontStyle: "italic",
            }}
          >
            In Short:
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: "700",
              fontStyle: "italic",
            }}
          >
            We may share information in specific situations described in this
            section and/or with the following third parties.
          </Typography>
        </Box>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign:'justify'
          }}
        >
          We may need to share your personal information in the following
          situations: Business Transfers. We may share or transfer your
          information in connection with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company. <br />
          Affiliates. We may share your
          information with our affiliates, in which case we will require those
          affiliates to honor this privacy policy. Affiliates include our parent
          company and any subsidiaries, joint venture partners, or other
          companies that we control or that are under common control with us. <br />
          Business Partners. We may share your information with our business
          partners to offer you certain products, services, or promotions.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default InfoShare;
