const careers = [
  {
    jobId: "mg103",
    post: "Senior BTech Faculty (Mechanical)",
    empType: "Full-Time, Permanent",
    salary: "₹65K - ₹1.5 Lakh per month",
    location: "Thiruvananthapuram, Kerala",
    experience: "1-3 years",
    branch: "Mechanical Engineering (ME)",
    lookingFor: [
      "Candidates with teaching experience",
      "Comprehensive Knowledge in all subjects of Mechanical Engineering other than their Specialization",
      "Candidates who are passionate about teaching",
      "Excellent communication skills in English & Malayalam",
      "Candidates who have worked in government engineering colleges as Ad Hoc or on contract basis will also have a special preference",
    ],
    responsibilities: [
      "Creating and presenting best content (online and offline classes, live and recorded videos, etc.) as courses or modules through our app, YouTube, etc.",
      "Play an active role in study material creation, proofreading, doubt clearance sessions, exam solution preparations and presentations",
      "Conducting orientation programmes, career and interview guidance, site visits and field classes based on drawings, designs, site investigation etc.",
      "Effectively estimate and communicate the time and effort required to complete tasks",
      "Coordinating with teams (Media and Editorial) in developing video content as well as materials like notes, books, etc.",
      "Coordinate and lead Research and Development, technical workshops and events for Engineering students",
    ],
  },
  {
    jobId: "mg104",
    post: "Junior BTech Faculty (Mechanical)",
    empType: "Full-Time, Permanent",
    salary: "₹60K - ₹1 Lakh per month",
    location: "Thiruvananthapuram, Kerala",
    experience: "0-1 year",
    branch: "Mechanical Engineering (ME)",
    lookingFor: [
      "Candidates with teaching experience",
      "Comprehensive Knowledge in all subjects of Mechanical Engineering other than their Specialization",
      "Candidates who are passionate about teaching",
      "Excellent communication skills in English & Malayalam",
      "Candidates who have worked in government engineering colleges as Ad Hoc or on contract basis will also have a special preference",
    ],
    responsibilities: [
      "Creating and presenting best content (online and offline classes, live and recorded videos, etc.) as courses or modules through our app, YouTube, etc.",
      "Play an active role in study material creation, proofreading, doubt clearance sessions, exam solution preparations and presentations",
      "Conducting orientation programmes, career and interview guidance, site visits and field classes based on drawings, designs, site investigation etc.",
      "Effectively estimate and communicate the time and effort required to complete tasks",
      "Coordinating with teams (Media and Editorial) in developing video content as well as materials like notes, books, etc.",
      "Coordinate and lead Research and Development, technical workshops and events for Engineering students",
    ],
  },
  {
    jobId: "mg105",
    post: "Senior BTech Faculty (Civil)",
    empType: "Full-Time, Permanent",
    salary: "₹65K - ₹1.5 Lakh per month",
    location: "Thiruvananthapuram, Kerala",
    experience: "1-3 years",
    branch: "Civil Engineering (CIVIL)",
    lookingFor: [
      "Candidates with teaching experience",
      "Comprehensive Knowledge in all subjects of their branch other than their Specialization",
      "Candidates who are passionate about teaching",
      "Candidates who are up to date with advanced construction methodologies, Instruments like Total station, skilled in quantity estimation and Structural design",
      "Candidates with software expertise in CAD, STAAD, e-tabs, etc.",
      "Candidates with exposure to technical events at IIT/NIT level (design/model)",
      "Excellent communication skills in English & Malayalam",
      "Candidates who have worked in government engineering colleges as Ad Hoc or on contract basis will also have a special preference",
    ],
    responsibilities: [
      "Creating and presenting best content (online and offline classes, live and recorded videos, etc.) as courses or modules through our app, YouTube, etc.",
      "Play an active role in study material creation, proofreading, doubt clearance sessions, exam solution preparations and presentations",
      "Conducting orientation programmes, career and interview guidance, site visits and field classes based on drawings, designs, site investigation, etc.",
      "Effectively estimate and communicate the time and effort required to complete tasks",
      "Coordinating with teams (Media and Editorial) in developing video content as well as materials like notes, books, etc.",
      "Coordinate and lead Research and Development, technical workshops and events for Engineering students",
    ],
  },
  {
    jobId: "mg106",
    post: "Junior BTech Faculty (Civil)",
    empType: "Full-Time, Permanent",
    salary: "₹60K - ₹1 Lakh per month",
    location: "Thiruvananthapuram, Kerala",
    experience: "0-1 year",
    branch: "Civil Engineering (CIVIL)",
    lookingFor: [
      "Candidates with teaching experience",
      "Comprehensive Knowledge in all subjects of their branch other than their Specialization",
      "Candidates who are passionate about teaching",
      "Candidates who are up to date with advanced construction methodologies, Instruments like Total station, skilled in quantity estimation and Structural design",
      "Candidates with software expertise in CAD, STAAD, e-tabs, etc.",
      "Candidates with exposure to technical events at IIT/NIT level (design/model)",
      "Excellent communication skills in English & Malayalam",
      "Candidates who have worked in government engineering colleges as Ad Hoc or on contract basis will also have a special preference",
    ],
    responsibilities: [
      "Creating and presenting best content (online and offline classes, live and recorded videos, etc.) as courses or modules through our app, YouTube, etc.",
      "Play an active role in study material creation, proofreading, doubt clearance sessions, exam solution preparations and presentations",
      "Conducting orientation programmes, career and interview guidance, site visits and field classes based on drawings, designs, site investigation, etc.",
      "Effectively estimate and communicate the time and effort required to complete tasks",
      "Coordinating with teams (Media and Editorial) in developing video content as well as materials like notes, books, etc.",
      "Coordinate and lead Research and Development, technical workshops and events for Engineering students",
    ],
  },
  {
    jobId: "mg107",
    post: "Senior BTech Faculty (Computer Science)",
    empType: "Full-Time, Permanent",
    salary: "₹65K - ₹1.5 Lakh per month",
    location: "Thiruvananthapuram, Kerala",
    experience: "1-3 years",
    branch: "Computer Science (CS)",
    lookingFor: [
      "Candidates with teaching experience",
      "Comprehensive Knowledge in all subjects of their branch other than their Specialization",
      "Candidates who are passionate about teaching",
      "Candidates with exposure to technical events at IIT/NIT",
      "Excellent communication skills in English & Malayalam",
      "Candidates who have worked in government engineering colleges as Ad Hoc or on contract basis will also have a special preference",
    ],
    responsibilities: [
      "Creating and presenting best content (online and offline classes, live and recorded videos, etc.) as courses or modules through our app, YouTube, etc.",
      "Play an active role in study material creation, proofreading, doubt clearance sessions, exam solution preparations and presentations",
      "Conducting orientation programmes, career and interview guidance, site visits and field classes based on drawings, designs, site investigation, etc.",
      "Effectively estimate and communicate the time and effort required to complete tasks",
      "Coordinating with teams (Media and Editorial) in developing video content as well as materials like notes, books, etc.",
      "Coordinate and lead Research and Development, technical workshops and events for Engineering students",
    ],
  },
  {
    jobId: "mg108",
    post: "Junior BTech Faculty (Computer Science)",
    empType: "Full-Time, Permanent",
    salary: "₹60K - ₹1 Lakh per month",
    location: "Thiruvananthapuram, Kerala",
    experience: "0-1 year",
    branch: "Computer Science (CS)",
    lookingFor: [
      "Candidates with teaching experience",
      "Comprehensive Knowledge in all subjects of their branch other than their Specialization",
      "Candidates who are passionate about teaching",
      "Candidates with exposure to technical events at IIT/NIT",
      "Excellent communication skills in English & Malayalam",
      "Candidates who have worked in government engineering colleges as Ad Hoc or on contract basis will also have a special preference",
    ],
    responsibilities: [
      "Creating and presenting best content (online and offline classes, live and recorded videos, etc.) as courses or modules through our app, YouTube, etc.",
      "Play an active role in study material creation, proofreading, doubt clearance sessions, exam solution preparations and presentations",
      "Conducting orientation programmes, career and interview guidance, site visits and field classes based on drawings, designs, site investigation, etc.",
      "Effectively estimate and communicate the time and effort required to complete tasks",
      "Coordinating with teams (Media and Editorial) in developing video content as well as materials like notes, books, etc.",
      "Coordinate and lead Research and Development, technical workshops and events for Engineering students",
    ],
  },
  {
    jobId: "mg109",
    post: "Senior BTech Faculty (EEE)",
    empType: "Full-Time, Permanent",
    salary: "₹65K - ₹1.5 Lakh per month",
    location: "Thiruvananthapuram, Kerala",
    experience: "1-3 years",
    branch: "Electrical and Electronics Engineering (EEE)",
    lookingFor: [
      "Candidates with teaching experience",
      "Comprehensive Knowledge in all subjects of their branch other than their Specialization",
      "Candidates who are passionate about teaching",
      "Candidates with exposure to technical events at IIT/NIT",
      "Excellent communication skills in English & Malayalam",
      "Candidates who have worked in government engineering colleges as Ad Hoc or on contract basis will also have a special preference",
    ],
    responsibilities: [
      "Creating and presenting best content (online and offline classes, live and recorded videos, etc.) as courses or modules through our app, YouTube, etc.",
      "Play an active role in study material creation, proofreading, doubt clearance sessions, exam solution preparations and presentations",
      "Conducting orientation programmes, career and interview guidance, site visits and field classes based on drawings, designs, site investigation, etc.",
      "Effectively estimate and communicate the time and effort required to complete tasks",
      "Coordinating with teams (Media and Editorial) in developing video content as well as materials like notes, books, etc.",
      "Coordinate and lead Research and Development, technical workshops and events for Engineering students",
    ],
  },
  {
    jobId: "mg110",
    post: "Junior BTech Faculty (EEE)",
    empType: "Full-Time, Permanent",
    salary: "₹60K - ₹1 Lakh per month",
    location: "Thiruvananthapuram, Kerala",
    experience: "0-1 year",
    branch: "Electrical and Electronics Engineering (EEE)",
    lookingFor: [
      "Candidates with teaching experience",
      "Comprehensive Knowledge in all subjects of their branch other than their Specialization",
      "Candidates who are passionate about teaching",
      "Candidates with exposure to technical events at IIT/NIT",
      "Excellent communication skills in English & Malayalam",
      "Candidates who have worked in government engineering colleges as Ad Hoc or on contract basis will also have a special preference",
    ],
    responsibilities: [
      "Creating and presenting best content (online and offline classes, live and recorded videos, etc.) as courses or modules through our app, YouTube, etc.",
      "Play an active role in study material creation, proofreading, doubt clearance sessions, exam solution preparations and presentations",
      "Conducting orientation programmes, career and interview guidance, site visits and field classes based on drawings, designs, site investigation, etc.",
      "Effectively estimate and communicate the time and effort required to complete tasks",
      "Coordinating with teams (Media and Editorial) in developing video content as well as materials like notes, books, etc.",
      "Coordinate and lead Research and Development, technical workshops and events for Engineering students",
    ],
  },
  {
    jobId: "mg111",
    post: "Faculty Maths",
    empType: "Full-Time, Permanent",
    salary: "₹60K - ₹1 Lakh per month",
    location: "Thiruvananthapuram, Kerala",
    experience: "0-3 years",
    branch: "Mathematics",
    lookingFor: [
      "Candidates with teaching experience",
      "Comprehensive Knowledge in all subjects of their branch other than their Specialization",
      "Candidates who are passionate about teaching",
      "Excellent communication skills in English & Malayalam",
      "Candidates who have worked in government engineering colleges as Ad Hoc or on contract basis will also have a special preference",
    ],
    responsibilities: [
      "Creating and presenting best content (online and offline classes, live and recorded videos, etc.) as courses or modules through our app, YouTube, etc.",
      "Play an active role in study material creation, proofreading, doubt clearance sessions, exam solution preparations and presentations",
      "Conducting orientation programmes, career and interview guidance, site visits and field classes based on drawings, designs, site investigation, etc.",
      "Effectively estimate and communicate the time and effort required to complete tasks",
      "Coordinating with teams (Media and Editorial) in developing video content as well as materials like notes, books, etc.",
      "Coordinate and lead Research and Development, technical workshops and events for Engineering students",
    ],
  },
  {
    jobId: "mg112",
    post: "Faculty Mentor",
    empType: "Full-Time, Permanent",
    salary: "₹60K - ₹1 Lakh per month",
    location: "Thiruvananthapuram, Kerala",
    experience: "0-1 year",
    branch: "Various",
    lookingFor: [
      "Comprehensive Knowledge in all subjects of their branch other than their Specialization",
      "Candidates who are passionate about teaching and assisting",
      "Excellent communication skills in English & Malayalam",
      "Freshers can also apply",
    ],
    responsibilities: [
      "Assisting faculties in study material creation, proofreading, doubt clearance sessions, exam solution preparations and presentations",
      "Effectively mentoring educational methodologies",
      "Facilitating educational activities",
      "Coordinating with faculties and other departments",
    ],
  },

  {
    jobId: "mg100",
    post: "Business Development Executive",
    empType: "Full-Time, Permanent",
    salary: "₹15K - ₹25K + incentives up to 1 Lakhs monthly",
    location: "Thiruvananthapuram, Kerala",
    experience: "0-3 years",
    branch: "Any branch",
    lookingFor: [
      "Bachelor's degree in any discipline",
      "0-3 years of sales or business development experience (Fresher and experienced both are preferred)",
      "Excellent communication, interpersonal, and presentation skills",
      "Ability to work in a fast-paced and dynamic environment",
      "Passion for education and technology",
      "Proficiency in Microsoft Office",
    ],
    responsibilities: [
      "Generate new business leads through online channels",
      "Conduct product demonstrations and presentations to potential customers",
      "Build and maintain strong relationships with clients",
      "Manage sales pipeline and close deals to meet or exceed sales targets",
      "Collaborate with cross-functional teams to improve customer experience and drive business growth",
      "Prepare and deliver sales presentations",
    ],
  },
];

export default careers;
