import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import vision from "../../../assets/images/vision.png";
import mission from "../../../assets/images/mission.png";

const MissionVision = () => {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        display: { md: "flex" },

        backgroundColor: "#FAF8F3",
        px: { xs: 4, md: 10 },
        py: 12,
      }}
    >
      <Grid
        item
        sx={{
          width: { sm: "100%", md: "50%" },
          display: "flex",
          flexDirection: { xs: "column", sm: "row", md: "column" },
          alignItems: { xs: "center", sm: "start" },
          gap: 2,
          mt: { xs: 6, sm: 4, md: 0 },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            width: "630px",
            height: "370px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <img src={vision} alt="" width="630px" />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            width: "315px",
            height: "185px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <img src={vision} alt="" width="315px" />
        </Box>
        <Box sx={{ width: { md: "630px" } }}>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "18px", sm: "20px", md: "24px" },
              fontWeight: 700,
              my: { xs: 1, sm: 0, md: 2 },
            }}
          >
            Our Vision
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", sm: "15px", md: "16px" },
              fontWeight: 500,
              lineHeight: { xs: "24px", sm: "30px", md: "36px" },
              textAlign:'justify'
            }}
          >
            Create Engineers equipped with theoretical and practical knowledge
            to forge a perspective about the industry and to design a vision to
            develop and advance the world and humanity.
          </Typography>
        </Box>
      </Grid>

      <Grid
        item
        sx={{
          width: { sm: "100%", md: "50%" },
          display: "flex",
          flexDirection: { xs: "column", sm: "row", md: "column" },
          alignItems: { xs: "center", sm: "start" },
          gap: 2,
          mt: { xs: 6, sm: 4, md: 0 },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            width: "630px",
            height: "370px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <img src={mission} alt="" width="630px" />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            width: "315px",
            height: "185px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <img src={mission} alt="" width="315px" />
        </Box>
        <Box sx={{width:{md:'630px'}}}>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "18px", md: "24px" },
              fontWeight: 700,
              my: { xs: 1, sm: 0, md: 2 },
            }}
          >
            Our Mission
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", sm: "14px", md: "16px" },
              fontWeight: 500,
              lineHeight: { xs: "24px", sm: "28px", md: "36px" },
              textAlign:'justify'
            }}
          >
            To change the way students approach examinations and to ensure
            students are happy and satisfied after each exams. To make
            technological education interesting & to ensure good results for all
            enrolled students. To impart practical along with theoretical sense
            of subject knowledge to all our students.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MissionVision;
