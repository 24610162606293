import AboutIt from 'components/About/AboutIt/AboutIt'
import AboutBanner from 'components/About/Banner/AboutBanner' 
import MissionVision from 'components/About/MissionVision/MissionVision'
import Team from 'components/About/Team/Team'
import Download from 'components/Home/Download/Download'
import React from 'react'

const AboutUs = () => {
  return (
    <>
      <AboutBanner/>
      <AboutIt/>
      <MissionVision/>
      <Team/>
      <Download/>
    </> 
  )
}

export default AboutUs
