import TermsBanner from 'components/TermsNConditions/TermsBanner/TermsBanner'
import TermsData from 'components/TermsNConditions/TermsData/TermsData'
import React from 'react'

const TermsConditions = () => {
  return (
    <>
      <TermsBanner/>
      <TermsData/>
    </>
  )
}

export default TermsConditions
