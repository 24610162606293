import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import style from "./PrivacyPolicy.module.scss";

const PrPolicyBanner = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box className={style.deskTopWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "start",
              p: 8,
              px: 10,
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              <Typography
                fontFamily="Manrope "
                sx={{ color: "#ffffff", fontSize: "48px", fontWeight: "700" }}
              >
                Privacy Policy
              </Typography>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontWeight: "400",
                  mt: 2,
                }}
              >
                Transparency and Trust:
              </Typography>
              <Typography
                fontFamily="Manrope"
                sx={{ color: "#ffffff", fontSize: "20px", fontWeight: "400" }}
              >
                Understanding Our Privacy Policy and Your Rights
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <div className={style.responsiveWrapper}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <Box>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                gap: 4,
                py: 3,
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <Typography
                  fontFamily="Tiempos Text Medium "
                  sx={{ color: "#ffffff", fontSize: "28px", fontWeight: "600" }}
                >
                  Privacy Policy
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "15px",
                    fontWeight: "400",
                    mt: 2,
                  }}
                >
                  Transparency and Trust:
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "13px", fontWeight: "400" }}
                >
                  Understanding Our Privacy Policy and Your Rights.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </div>
    </Box>
  );
};

export default PrPolicyBanner;
