import React, { useState, useRef, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import axios from "axios";
import styles from "./Placement.module.scss";
import {
  Card,
  Grid,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { Colleges, branches } from "utils/consts";

import { useMediaQuery, useTheme } from "@mui/material";

import { SiWhatsapp } from "react-icons/si";

import UnderLine from "../../assets/images/underLine.png";
import keamNavigator from "../../assets/images/keamNavigator.jpeg";

import careersdata from "../../components/PlacementDrive/careers";

export default function Placement() {
  const { jobId } = useParams();

  const [jobData, setJobData] = useState([]);

  const [loading, setLoading] = useState("");
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [shouldFocus, setShouldFocus] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    experience: "",
    qualification: "",
    location: "",
    college: "",
  });

  const SteinStore = require("stein-js-client");
  const store = new SteinStore(
    "https://api.steinhq.com/v1/storages/66c838744d11fd04f02142e8"
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone Number is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (!formData.experience.trim()) {
      errors.experience = "Experience is required";
    }
    if (!formData.qualification.trim()) {
      errors.qualification = "Qualification is required";
    }
    if (!formData.location.trim()) {
      errors.location = " Interview Location is required";
    }
    if (!formData.college.trim()) {
      errors.college = " College is required";
    }

    if (Object.keys(errors).length === 0) {
      setSubmitting(true);

      const dataWithDateTime = {
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        experience: formData.experience,
        qualification: formData.qualification,
        location: formData.location,
        college: formData.college,
        jobPost: Job.post,
        timestamp: new Date().toLocaleString(),
      };

      console.log(dataWithDateTime);

      store
        .append("Sheet1", [dataWithDateTime])
        .then((res) => {
          console.log("Form data submitted to Google Sheets:", res);
          setSubmitted(true);
          setSubmitting(false);
          alert("Submitted Successfully");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error submitting form data:", error);
          setSubmitting(false);
          alert("Error submitting form data. Please try again later.");
        });
    } else {
      setErrors(errors);
    }
  };
  const handleWhatsAppRedirect = () => {
    const url = "https://chat.whatsapp.com/LPFkMMHQwxu0uoe4qjTbEu";
    window.open(url, "_blank");
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const textFieldRef = useRef(null);

  useEffect(() => {
    if (isMobile) {
      setShouldFocus(true);
    } else {
      setShouldFocus(false);
    }
  }, [isMobile]);

  const Job = careersdata.find((job) => job.jobId === jobId);

  // Apply focus conditionally after page load
  useEffect(() => {
    // Ensure focus is applied after initial render
    const handleFocus = () => {
      if (shouldFocus && textFieldRef.current) {
        textFieldRef.current.focus();
      }
    };

    // Use setTimeout to ensure focus happens after initial render
    const timer = setTimeout(handleFocus, 100);

    return () => clearTimeout(timer);
  }, [shouldFocus]);

  return (
    <div className={styles.wrapper}>
      <Typography
        fontFamily="Inter"
        fontSize="32px"
        fontWeight="500"
        textAlign="center">
        {Job.post}
      </Typography>
      <img src={UnderLine} alt="" />

      {/* <Button
        onClick={handleWhatsAppRedirect}
        type="submit"
        variant="contained"
        sx={{
          marginTop: "50px",
          height: "60px",
          backgroundColor: "#3878F2",
          color: "#E2EBFF",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#00359b",
          },
        }}>
        {" "}
        <Typography
          fontFamily="Inter"
          fontWeight="500"
          sx={{
            fontSize: {
              sm: "18px",
              md: "21px",
              lg: "22px",
            },
          }}>
          <SiWhatsapp style={{ marginRight: "5px" }} />
          Click here to join the KEAM Navigator whatsapp community
        </Typography>
      </Button> */}
      <Grid container marginTop="20px" spacing={4}>
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1, lg: 1 }}>
          <form onSubmit={handleSubmit}>
            <Card className={styles.card1}>
              <TextField
                required
                id="standard-required"
                label="Name"
                name="name"
                value={formData.name}
                variant="standard"
                fullWidth
                error={!!errors.name}
                helperText={errors.name}
                onChange={handleInputChange}
              />

              <TextField
                required
                type="number"
                id="standard-required"
                label="Phone No"
                name="phone"
                value={formData.phone}
                variant="standard"
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone}
                onChange={handleInputChange}
              />
              <TextField
                required
                id="standard-required"
                label="Email"
                name="email"
                value={formData.email}
                variant="standard"
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
                onChange={handleInputChange}
              />

              <TextField
                required
                id="standard-required"
                label="Work Experience"
                name="experience"
                value={formData.experience}
                variant="standard"
                fullWidth
                error={!!errors.experience}
                helperText={errors.experience}
                onChange={handleInputChange}
              />
              <TextField
                required
                id="standard-required"
                label="Qualification"
                name="qualification"
                value={formData.qualification}
                variant="standard"
                fullWidth
                onChange={handleInputChange}
              />

              <label>
                <Typography
                  color="#757575"
                  sx={{
                    fontSize: { xs: "12px", sm: "1rem" },
                    marginBottom: "-25px",
                  }}>
                  Select College *
                </Typography>
              </label>
              <Select
                name="college"
                value={formData.college}
                onChange={handleInputChange}
                required
                variant="standard"
                fullWidth
                displayEmpty
                sx={{ marginTop: "-15px" }}
                error={!!errors.college}
                helperText={errors.college}>
                <MenuItem selected disabled>
                  Select College
                </MenuItem>
                {Colleges.map((college, index) => (
                  <MenuItem key={index} value={college}>
                    {college}
                  </MenuItem>
                ))}
              </Select>

              <Select
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                required
                variant="standard"
                fullWidth
                displayEmpty
                sx={{ marginTop: "13px" }}
                error={!!errors.location}
                helperText={errors.location}>
                <MenuItem value="" disabled>
                  Select Interview Location
                </MenuItem>

                <MenuItem value="Trivandrum">Trivandrum</MenuItem>
                <MenuItem value="Kochi"> Kochi</MenuItem>
              </Select>

              <Typography
                fontFamily="Inter"
                fontWeight="500"
                sx={{
                  fontSize: "18px",
                }}>
                Kindly send resume to the mail id:
                <Typography
                  component="a"
                  href="mailto:jobs@msigmagokulam.com"
                  sx={{
                    textDecoration: "none",
                    color: "#3878F2",
                    "&:hover": { color: "blue" },
                  }}>
                  jobs@msigmagokulam.com
                </Typography>
              </Typography>

              <Typography sx={{ marginTop: "-20px" }}>
                Subject of the mail should be of the format: {Job.post}
              </Typography>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  height: "60px",
                  backgroundColor: "#43BA80",
                  color: "#E2EBFF",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#3878F2",
                  },
                }}>
                <Typography
                  fontFamily="Inter"
                  fontWeight="500"
                  sx={{ fontSize: { sm: "18px", md: "21px", lg: "22px" } }}>
                  {submitting ? "Submitting..." : "Submit"}
                </Typography>
              </Button>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2, lg: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              width: "100%",
              alignItems: "start",
              padding: "10px",
            }}>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: 12, sm: 14, md: 16 },
                fontWeight: 500,
              }}>
              Salary : {Job.salary}
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: 12, sm: 14, md: 16 },
                fontWeight: 500,
                mt: 1,
              }}>
              Job Location: {Job.location}
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: 12, sm: 14, md: 16 },
                fontWeight: 500,
                mt: 1,
              }}>
              Experience: {Job.experience}
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: 12, sm: 14, md: 16 },
                fontWeight: 500,
                mt: 1,
              }}>
              Branch: {Job.branch}
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: 12, sm: 14, md: 16 },
                fontWeight: 700,
                mt: 1,
              }}>
              What are we looking for?
            </Typography>
            {Job.lookingFor.map((look) => (
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: { xs: 12, sm: 14, md: 16 },
                  fontWeight: 500,
                }}>
                ● {look}
              </Typography>
            ))}
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: 12, sm: 14, md: 16 },
                fontWeight: 700,
                mt: 1,
              }}>
              Key responsibilities
            </Typography>
            {Job.responsibilities.map((resp) => (
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: { xs: 12, sm: 14, md: 16 },
                  fontWeight: 500,
                }}>
                ● {resp}
              </Typography>
            ))}

            {/* <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: 12, sm: 14, md: 16 },
                fontWeight: 700,
                mt: 2,
              }}>
              How to Apply?
            </Typography> */}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
