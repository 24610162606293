import React from "react";
import { Typography, Grid, Box } from "@mui/material";

const Updates = () => {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          pb: 8,
          px: { xs: 5, md: 30 },
        }}
      >
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "800",
            mt: 2,
            color: "#787878",
          }}
        >
          8. DO WE MAKE UPDATES TO THIS POLICY?
        </Typography>
        <Box sx={{ display: "", mt: 2 }}>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: "800",
              fontStyle: "italic",
            }}
          >
            In Short:
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: "700",
              fontStyle: "italic",
            }}
          >
            Yes, we will update this policy as necessary to stay compliant with
            relevant laws.
          </Typography>
        </Box>
        <Typography
          fontFamily="Manrope"
          sx={{
            fontSize: { xs: "13px", md: "15px" },
            fontWeight: "600",
            mt: 4,
            textAlign: "justify",
          }}
        >
          We may update this privacy policy from time to time. The updated
          version will be indicated by an updated Revised date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy policy, we may notify you either by
          prominently posting a policy of such changes or by directly sending
          you a notification. We encourage you to review this privacy policy
          frequently to be informed of how we are protecting your information.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Updates;
