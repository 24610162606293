import { Box, Grid } from "@mui/material";
import React from "react";

const Map = () => {
  return (
    <Grid container sx={{ width: "100%", px: { xs: 4, md: 12 }, mt: 8 }}>
      <Grid item sx={{ width:"100%"}}>
      <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.619180717741!2d76.92032827406196!3d8.536300996508675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05b9523836d0cd%3A0x8c4368c4da964a6e!2sMsigma%20Gokulam!5e0!3m2!1sen!2sin!4v1712057120793!5m2!1sen!2sin"
      width="100%"
      height="450"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
      </Grid>
    </Grid>
  );
};

export default Map;
