import React from 'react'
import MechanicalBanner from 'components/CourseDetails/Banners/MechGate/MechanicalBanner'
import MechData from 'components/CourseDetails/Gate/Mechanical/MechData'

const MechanicalGate = () => {
  return (
    <>
      <MechanicalBanner/>
      <MechData/>
    </>
  )
}

export default MechanicalGate
