import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import style from "./Video.module.scss";
import manu from "../../../assets/images/manu.png";
import charly from "../../../assets/images/charly.png";
import rang from "../../../assets/images/rang.png";
import nithin from "../../../assets/images/nithin.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import youtube from "../../../assets/icons/ytb.png";
import ytube from "../../../assets/icons/youtb.png";
import { createTheme, ThemeProvider } from "@mui/material";

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1279,
    lg: 1280,
  },
};

const theme = createTheme({
  breakpoints: { ...breakpoints },
});

const Vid = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box className={style.deskTopWrapper}>
        <Grid
          container
          sx={{
            width: "100%",
            p: 12,
            display: "flex",
          }}
        >
          <Grid container sx={{ width: "50%" }}>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                px: 4,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "34px", fontWeight: 400 }}
              >
                Our
              </Typography>
              <Typography
                fontFamily="Tiempos Text Medium Italic"
                sx={{ fontSize: "34px", fontWeight: 400, fontStyle: "italic" }}
              >
                Demo Class
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                mt: 5.6,
                display: "flex",
                flexWrap: "wrap",
                gap: 4,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  width: "280px",
                  height: "190px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                style={{ backgroundColor: "#7a7976" }}
              >
                <Box
                  className={style.grad}
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "280px",
                    marginTop: 13.5,
                    paddingBottom: 2,
                    px: 2,
                  }}
                >
                  <Link
                    to="https://youtu.be/f0MtkGvzKqI?si=PCrdU3MmXBbEsV6l"
                    target="_blank"
                  >
                    <img src={youtube} alt="" />
                  </Link>

                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU - ECE, <br /> EEE, AE 2019 & 2015
                    Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={charly}
                  alt="Chairman"
                  sx={{ height: "190px", width: "200px" }}
                />
              </Card>
              <Card
                sx={{
                  width: "280px",
                  height: "190px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                style={{ backgroundColor: "#514e4d" }}
              >
                <Box
                  className={style.grad}
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "280px",
                    marginTop: 13.5,
                    paddingBottom: 2,
                    px: 2,
                  }}
                >
                  <Link
                    to="https://youtu.be/f0MtkGvzKqI?si=PCrdU3MmXBbEsV6l"
                    target="_blank"
                  >
                    <img src={youtube} alt="" />
                  </Link>

                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU - ECE, <br /> EEE, AE 2019 & 2015
                    Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={nithin}
                  alt="Chairman"
                  sx={{ height: "190px", width: "200px" }}
                />
              </Card>
              <Card
                sx={{
                  width: "280px",
                  height: "190px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                style={{ backgroundColor: "#101416" }}
              >
                <Box
                  className={style.grad}
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "280px",
                    marginTop: 13.5,
                    paddingBottom: 2,
                    px: 2,
                  }}
                >
                  <Link
                    to="https://youtu.be/f0MtkGvzKqI?si=PCrdU3MmXBbEsV6l"
                    target="_blank"
                  >
                    <img src={youtube} alt="" />
                  </Link>

                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU - ECE, <br /> EEE, AE 2019 & 2015
                    Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={manu}
                  alt="Chairman"
                  sx={{ height: "190px", width: "200px" }}
                />
              </Card>
              <Card
                sx={{
                  width: "280px",
                  height: "190px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                style={{ backgroundColor: "#2b2b2b" }}
              >
                <Box
                  className={style.grad}
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "280px",
                    marginTop: 13.5,
                    paddingBottom: 2,
                    px: 2,
                  }}
                >
                  <Link
                    to="https://youtu.be/f0MtkGvzKqI?si=PCrdU3MmXBbEsV6l"
                    target="_blank"
                  >
                    <img src={youtube} alt="" />
                  </Link>

                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU - ECE, <br /> EEE, AE 2019 & 2015
                    Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={rang}
                  alt="Chairman"
                  sx={{ height: "190px", width: "200px" }}
                />
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                gap: 1,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "16px", fontWeight: 500, lineHeight: "32px" }}
              >
                Each of our university courses is designed in such a manner{" "}
                <br /> that it may be finished in as little as 2-3 days if
                necessary.
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }}>
              <Card
                sx={{
                  width: "654px",
                  height: "412px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                style={{ backgroundColor: "#0C1013" }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "654px",
                  }}
                >
                  <Link
                    to="https://youtu.be/f0MtkGvzKqI?si=PCrdU3MmXBbEsV6l"
                    target="_blank"
                  >
                    <img src={ytube} alt="" />
                  </Link>
                </Box>
                <Box
                  className={style.grad}
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "654px",
                    marginTop: 42,
                    paddingBottom: 2,
                  }}
                >
                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "20px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                      px: 4,
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU - ECE, <br /> EEE, AE 2019 & 2015
                    Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={manu}
                  alt="Chairman"
                  sx={{ height: { xs: 220, md: "416px" }, width: "392px" }}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 5,
          }}
         >
          <Link to="https://www.youtube.com/@msigmagokulam" target="_blank">
            <Button
              fontFamily="Manrope"
              style={{
                backgroundColor: "#F0F0F0",
              }}
              sx={{
                borderRadius: 15,
                py: 1.5,
                color: "#000000",
                textAlign: "center",
                px: 2,
                fontSize: "16px",
                textTransform: "none",
                mt: 2,
                fontWeight: 400,
                width: "140px",
              }}
            >
              View All
            </Button>
          </Link>
        </Grid>
      </Box>

      <Box className={style.tabletWrapper}
        sx={{
          width: "100%",
          p: 3,
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "28px", fontWeight: 400 }}
              >
                Our
              </Typography>
              <Typography
                fontFamily="Tiempos Text Medium Italic"
                sx={{ fontSize: "28px", fontWeight: 400, fontStyle: "italic" }}
              >
                Demo Class
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: 6,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "32px",
                  textAlign: "center",
                }}
              >
                Each of our university courses is designed in such a manner that
                it may be finished in as little as 2-3 days if necessary.
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }}>
              <Card
                sx={{
                  width: "328px",
                  height: "326px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                style={{ backgroundColor: "#0C1013" }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "328px",
                  }}
                >
                  <Link
                    to="https://youtu.be/f0MtkGvzKqI?si=--Z8nAXJDnoM2Gm2"
                    target="_blank"
                  >
                    <img src={youtube} alt="" width="50px" />
                  </Link>
                </Box>
                <Box
                  className={style.grad}
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "328px",
                    marginTop: 33,
                    paddingBottom: 2,
                  }}
                >
                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                      px: 2,
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU - ECE, EEE, AE 2019 & 2015 Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={manu}
                  alt="Chairman"
                  sx={{ height: "326px", width: "328px" }}
                />
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
           >
            <Grid item sx={{ mt: 1, display: "flex", gap: 1 }}>
              <Card
                sx={{
                  width: "160px",
                  height: "162px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                style={{ backgroundColor: "#0C1013" }}
              >
                <Box
                  className={style.grad}
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "160px",
                    marginTop: 12,
                    paddingBottom: 2,
                    px: 1,
                  }}
                >
                  <Link
                    to="https://youtu.be/f0MtkGvzKqI?si=--Z8nAXJDnoM2Gm2"
                    target="_blank"
                  >
                    <img src={ytube} alt="" width="25px" />
                  </Link>
                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "9px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU- ECE, EEE, AE 2019& 2015 Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={charly}
                  alt="Chairman"
                  sx={{ height: "162px", width: "160px" }}
                />
              </Card>
              <Card
                sx={{
                  width: "160px",
                  height: "162px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                style={{ backgroundColor: "#0C1013" }}
              >
                <Box
                  className={style.grad}
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "160px",
                    marginTop: 12,
                    paddingBottom: 2,
                    px: 1,
                  }}
                >
                  <Link
                    to="https://youtu.be/f0MtkGvzKqI?si=--Z8nAXJDnoM2Gm2"
                    target="_blank"
                  >
                    <img src={ytube} alt="" width="25px" />
                  </Link>
                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "9px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU- ECE, EEE, AE 2019& 2015 Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={nithin}
                  alt="Chairman"
                  sx={{ height: "162px", width: "160px" }}
                />
              </Card>
            </Grid>
            <Grid
              item
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
             >
              <Link to="https://www.youtube.com/@msigmagokulam" target="_blank">
                <Button
                  fontFamily="Manrope"
                  style={{
                    backgroundColor: "#F0F0F0",
                  }}
                  sx={{
                    borderRadius: 15,
                    py: 1,
                    color: "#000000",
                    textAlign: "center",
                    fontSize: "16px",
                    textTransform: "none",
                    mt: 2,
                    fontWeight: 500,
                    width: "140px",
                  }}
                >
                  View All
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        className={style.responsiveWrapper}
        sx={{
          width: "100%",
          p: 3,
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "28px", fontWeight: 400 }}
              >
                Our
              </Typography>
              <Typography
                fontFamily="Tiempos Text Medium Italic"
                sx={{ fontSize: "28px", fontWeight: 400, fontStyle: "italic" }}
              >
                Demo Class
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "32px",
                  textAlign: "center",
                }}
              >
                Each of our university courses is designed in such a manner that
                it may be finished in as little as 2-3 days if necessary.
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }}>
              <Card
                sx={{
                  width: "328px",
                  height: "326px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                style={{ backgroundColor: "#0C1013" }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "328px",
                  }}
                >
                  <Link
                    to="https://youtu.be/f0MtkGvzKqI?si=--Z8nAXJDnoM2Gm2"
                    target="_blank"
                  >
                    <img src={youtube} alt="" width="50px" />
                  </Link>
                </Box>
                <Box
                  className={style.grad}
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "328px",
                    marginTop: 33,
                    paddingBottom: 2,
                  }}
                >
                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                      px: 2,
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU - ECE, EEE, AE 2019 & 2015 Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={manu}
                  alt="Chairman"
                  sx={{ height: "326px", width: "328px" }}
                />
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid item sx={{ mt: 1, display: "flex", gap: 1 }}>
              <Card
                sx={{
                  width: "160px",
                  height: "162px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                style={{ backgroundColor: "#0C1013" }}
              >
                <Box
                  className={style.grad}
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "160px",
                    marginTop: 12,
                    paddingBottom: 2,
                    px: 1,
                  }}
                >
                  <Link
                    to="https://youtu.be/f0MtkGvzKqI?si=--Z8nAXJDnoM2Gm2"
                    target="_blank"
                  >
                    <img src={ytube} alt="" width="25px" />
                  </Link>
                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "9px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU- ECE, EEE, AE 2019& 2015 Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={charly}
                  alt="Chairman"
                  sx={{ height: "162px", width: "160px" }}
                />
              </Card>
              <Card
                sx={{
                  width: "160px",
                  height: "162px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0,
                }}
                style={{ backgroundColor: "#0C1013" }}
              >
                <Box
                  className={style.grad}
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "160px",
                    marginTop: 12,
                    paddingBottom: 2,
                    px: 1,
                  }}
                >
                  <Link
                    to="https://youtu.be/f0MtkGvzKqI?si=--Z8nAXJDnoM2Gm2"
                    target="_blank"
                  >
                    <img src={ytube} alt="" width="25px" />
                  </Link>
                  <Typography
                    fontFamily="Manrope"
                    component="div"
                    sx={{
                      fontSize: "9px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                    SIGNALS AND SYSTEMS: KTU- ECE, EEE, AE 2019& 2015 Scheme
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={nithin}
                  alt="Chairman"
                  sx={{ height: "162px", width: "160px" }}
                />
              </Card>
            </Grid>
            <Grid
              item
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <Link to="https://www.youtube.com/@msigmagokulam" target="_blank">
                <Button
                  fontFamily="Manrope"
                  style={{
                    backgroundColor: "#F0F0F0",
                  }}
                  sx={{
                    borderRadius: 15,
                    py: 1,
                    color: "#000000",
                    textAlign: "center",
                    fontSize: "16px",
                    textTransform: "none",
                    mt: 2,
                    fontWeight: 500,
                    width: "140px",
                  }}
                >
                  View All
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Vid;
