const eeeSyllabus = [
  {
    section: "Section 1: Engineering Mathematics",
    topics: [
      "Linear Algebra",
      "Calculus",
      "Differential Equations",
      "Complex variables",
      "Probability and Statistics",
    ],
  },
  {
    section: "Section 2: Electric circuits",
    topics: [
      "Network elements: Ideal voltage and current sources, dependent sources, R, L,C, M elements; Network solution methods: KCL, KVL, Node and Mesh analysis; Network Theorems: Thevenin’s, Norton’s, Superposition and Maximum Power Transfer theorem; Transient response of dc and ac networks, sinusoidal steady-state analysis, resonance, two port networks, balanced three phase circuits, star-delta transformation, complex power and power factor in ac circuits",
    ],
  },
  {
    section: "Section 3:Electromagnetic Fields",
    topics: [
      "  Coulomb's Law, Electric Field Intensity, Electric Flux Density, Gauss's Law, Divergence, Electric field and potential due to point, line, plane and spherical charge distributions, Effect of dielectric medium, Capacitance of simple configurations, Biot‐Savart’s law, Ampere’s law, Curl, Faraday’s law, Lorentz force, Inductance, Magnetomotive force, Reluctance, Magnetic circuits, Self and Mutual inductance of simple configurations",
    ],
  },
  {
    section: "Section 4: Signals and Systems",
    topics: ["Representation of continuous and discrete time signals, shifting and scaling properties, linear time invariant and causal systems, Fourier series representation of continuous and  discrete time periodic signals, sampling theorem, Applications of Fourier Transform for continuous and discrete time signals, Laplace Transform and Z transform. R.M.S. value, average value calculation for any general periodic waveform"],
  },
  {
    section: "Section 5: Electrical Machines",
    topics: [
      "Single phase transformer: equivalent circuit, phasor diagram, open circuit and short circuit tests, regulation and efficiency; Three-phase transformers: connections, vector groups, parallel operation; Auto-transformer, Electromechanical energy conversion principles; DC machines: separately excited, series and shunt, motoring and generating mode of operation and their characteristics, speed control of dc motors; Three-phase induction machines: principle of operation, types, performance, torque-speed characteristics, no-load and blocked-rotor tests, equivalent circuit, starting and speed control; Operating principle of single-phase induction motors; Synchronous machines: cylindrical and salient pole machines, performance and characteristics, regulation and parallel operation of generators, starting of synchronous motors; Types of losses and efficiency calculations of electric machines",
    ],
  },
  {
    section: "Section 6:  Power Systems ",
    topics: [
      "Basic concepts of electrical power generation, ac and dc transmission concepts, Models and performance of transmission lines and cables, Economic Load Dispatch (with and without considering transmission losses), Series and shunt compensation, Electric field distribution and insulators, Distribution systems, Per‐unit quantities, Bus admittance matrix, Gauss- Seidel and Newton-Raphson load flow methods, Voltage and Frequency control, Power factor correction, Symmetrical components, Symmetrical and unsymmetrical fault analysis, Principles of over‐current, differential, directional and distance protection; Circuit breakers, System stability concepts, Equal area criterion",
    ],
  },
  {
    section: "Section 7:  Control Systems",
    topics: [
      "Mathematical modelling and representation of systems, Feedback principle, transfer function, Block diagrams and Signal flow graphs, Transient and Steady‐ state analysis of linear time invariant systems, Stability analysis using Routh- Hurwitz and Nyquist criteria, Bode plots, Root loci, Lag, Lead and Lead‐Lag compensators; P, PI and PID controllers; State space model, Solution of state equations of LTI systems",
    ],
  },
  {
    section: "Section 8:  Electrical and Electronic Measurements",
    topics: [
      "Bridges and Potentiometers, Measurement of voltage, current, power, energy and power factor; Instrument transformers, Digital voltmeters and multi-meters,Phase, Time and Frequency measurement; Oscilloscopes, Error analysis",
    ],
  },
  {
    section: "Section 9:  Analog and Digital Electronics",
    topics: [
      "Simple diode circuits: clipping, clamping, rectifiers; Amplifiers: biasing, equivalent circuit and frequency response; oscillators and feedback amplifiers; operational amplifiers: characteristics and applications; single stage active filters, Active Filters: Sallen Key, Butterwoth, VCOs and timers, combinatorial and sequential logic circuits, multiplexers, demultiplexers, Schmitt triggers, sample and hold circuits, A/D and D/A converters ",
    ],
  },
  {
    section: "Section 10:  Power Electronics",
    topics: [
      " Static V-I characteristics and firing/gating circuits for Thyristor, MOSFET, IGBT; DC to DC conversion: Buck, Boost and Buck-Boost Converters; Single and three-phase configuration of uncontrolled rectifiers; Voltage and Current commutated Thyristor based converters; Bidirectional ac to dc voltage source converters; Magnitude and Phase of line current harmonics for uncontrolled and thyristor based converters; Power factor and Distortion Factor of ac to dc converters; Single-phase and three-phase voltage and current source inverters, sinusoidalpulse width modulation. ",
    ],
  },
];

export default eeeSyllabus;

