import React, { useState } from "react";
import axios from "axios";
import styles from "./FreePythonClass.module.scss";
import { Card, Grid, Typography, Button, TextField } from "@mui/material";

import { SiWhatsapp } from "react-icons/si";

import UnderLine from "../../assets/images/underLine.png";
import keamNavigator from "../../assets/images/tn.png";

export default function FreePythonClass() {
  const [loading, setLoading] = useState("");
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    school: "",
    rank: "",
  });

  const SteinStore = require("stein-js-client");
  const store = new SteinStore(
    "https://api.steinhq.com/v1/storages/66b4bab14d11fd04f01bbfcd"
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone Number is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (Object.keys(errors).length === 0) {
      setSubmitting(true);

      const dataWithDateTime = {
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        timestamp: new Date().toLocaleString(),
      };

      store
        .append("Sheet1", [dataWithDateTime])
        .then((res) => {
          console.log("Form data submitted to Google Sheets:", res);
          setSubmitted(true);
          setSubmitting(false);
          alert("Submitted Successfully");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error submitting form data:", error);
          setSubmitting(false);
          alert("Error submitting form data. Please try again later.");
        });
    } else {
      setErrors(errors);
    }
  };
  const handleWhatsAppRedirect = () => {
    const url = "https://chat.whatsapp.com/LPFkMMHQwxu0uoe4qjTbEu";
    window.open(url, "_blank");
  };

  return (
    <div className={styles.wrapper}>
      <Typography fontFamily="Inter" fontSize="32px" fontWeight="500">
        Free Python Class{" "}
      </Typography>
      <img src={UnderLine} alt="" />

      <Grid container spacing={4} marginTop="20px">
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1, lg: 1 }}>
          <form onSubmit={handleSubmit}>
            <Card className={styles.card1}>
              <TextField
                required
                id="standard-required"
                label="Name"
                name="name"
                value={formData.name}
                variant="standard"
                fullWidth
                error={!!errors.name}
                helperText={errors.name}
                onChange={handleInputChange}
              />
              <TextField
                required
                id="standard-required"
                label="Email"
                name="email"
                value={formData.email}
                variant="standard"
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
                onChange={handleInputChange}
              />
              <TextField
                required
                type="number"
                id="standard-required"
                label="Phone No"
                name="phone"
                value={formData.phone}
                variant="standard"
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone}
                onChange={handleInputChange}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  height: "60px",
                  backgroundColor: "#43BA80",
                  color: "#E2EBFF",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#3878F2",
                  },
                }}
              >
                <Typography
                  fontFamily="Inter"
                  fontWeight="500"
                  sx={{ fontSize: { sm: "18px", md: "21px", lg: "22px" } }}
                >
                  {submitting ? "Submitting..." : "Submit"}
                </Typography>
              </Button>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2, lg: 2 }}>
          <img src={keamNavigator} alt="" width="100%" />
        </Grid>
      </Grid>
    </div>
  );
}
