import Course from 'components/Home/Courses/Course'
import Vid from 'components/Home/Video/Vid'
import Video from 'components/Home/Video/Video'

import React from 'react'

const Courses = () => {
  return (
    <>
      <Course/>
      {/* <Vid/> */}
      <Video/>
    </>
  )
}

export default Courses
