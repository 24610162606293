import React from 'react'
import CivilGateBanner from 'components/CourseDetails/Banners/CivilGate/CivilGateBanner'
import CeData from 'components/CourseDetails/Gate/Civil/CeData'

const CivilGate = () => {
  return (
    <>
      <CivilGateBanner/>
      <CeData/>
    </>
  )
}

export default CivilGate
