import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import style from "./Download.module.scss";
import app from "../../../assets/logo/appW.png";
import play from "../../../assets/logo/playW.png";
import { Link } from "react-router-dom";

const Download = () => {
  return (
    <Box>
      <Box className={style.deskTopWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              py: 5,
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                width: "50%",
                p: 6,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{ color: "#ffffff", fontSize: "34px", fontWeight: "400" }}
              >
                DOWNLOAD THE APP
              </Typography>

              <Typography
                fontFamily="Tiempos Text Medium italic"
                sx={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontWeight: "300",
                  mt: 2,
                }}
              >
                We're available on App Store and Google Play Store <br /> for
                anywhere at your convenience
              </Typography>
              <Box sx={{ display: "flex", gap: 1, mt: 4 }}>
                <Link to="https://apps.apple.com/in/app/msigma/id6463402970">
                  <img src={app} alt="" width='160px'/>
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=in.mgate.msigma">
                  <img src={play} alt="" width='160px'/>
                </Link>
              </Box>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              {/* <img src={alg} alt="" /> */}
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={style.tabletWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              py: 20,
              width: "100%",
              pr:6,
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                width: "50%",
              
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "400" }}
              >
                DOWNLOAD THE APP
              </Typography>

              <Typography
                fontFamily="Tiempos Text Medium italic"
                sx={{
                  color: "#ffffff",
                  fontSize: "15px",
                  fontWeight: "300",
                  mt: 2,
                }}
              >
                We're available on App Store and Google Play Store for anywhere
                at your convenience
              </Typography>
              <Box sx={{ display: "flex", gap: 1, mt: 4 }}>
                <Link to="https://apps.apple.com/in/app/msigma/id6463402970">
                  <img src={app} alt="" width='150px'/>
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=in.mgate.msigma">
                  <img src={play} alt="" width='150px'/>
                </Link>
              </Box>
            </Grid>

            
          </Grid>
        </Box>
      </Box>

      <div className={style.responsiveWrapper}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                py: 2,
                width: "100%",
                mb: 55,
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{ color: "#ffffff", fontSize: "28px", fontWeight: "400" }}
                >
                  DOWNLOAD THE APP
                </Typography>

                <Typography
                  fontFamily="Tiempos Text Medium italic"
                  sx={{
                    color: "#ffffff",
                    fontSize: "13px",
                    fontWeight: "300",
                    mt: 2,
                    textAlign: "center",
                  }}
                >
                  We're available on App Store and Google Play Store for
                  anywhere at your convenience
                </Typography>
                <Box sx={{ display: "flex", gap: 1, mt: 4 }}>
                  <Link to="https://apps.apple.com/in/app/msigma/id6463402970">
                    <img src={app} alt="" width='160px'/>
                  </Link>
                  <Link to="https://play.google.com/store/apps/details?id=in.mgate.msigma">
                    <img src={play} alt="" width='160px'/>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </div>
    </Box>
  );
};

export default Download;
