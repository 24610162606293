import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { BsArrowDownRightCircleFill } from "react-icons/bs";
import careers from "./careers";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#43BA80',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#43BA80',
    },
  },
});

const Hire = () => {

  return (
    <Grid container sx={{ width: "100%", px: { xs: 4, sm: 6, md: 8 } }}>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row", md: "row" },
          alignItems: "center",
          justifyContent: {
            xs: "start",
            sm: "space-between",
            md: "space-between",
          },
          my: { xs: 2, sm: 4, md: 6 },
          px: { ms: 0, sm: 2, md: 2 },
        }}
       >
        <Grid item>
          <Typography
            fontFamily="Manrope"
            sx={{ fontSize: "18px", fontWeight: 800 }}
          >
            We’re Hiring
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "14px", sm: "16px", md: "16px" },
              fontWeight: 500,
              mt: 1,
            }}
          >
            Join our team and be part of something extraordinary! <br />
            We're hiring talented individuals who are passionate about making a
            difference
          </Typography>
        </Grid>
        <Grid item>
          <CssTextField
            variant="outlined"
            placeholder="Search Jobs"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ color: "#E6E6E7", fontSize: "40px", mr: -1 }}
                >
                  <BsArrowDownRightCircleFill />
                </InputAdornment>
              ),
              sx: {
                borderRadius: "30px",
                width: { xs: "330px", md: "385px" },
                height: "50px",
                mt: 2,
                "& label": {
                  fontFamily: "Manrope",
                  fontWeight: 500,
                },
                "& input": {
                  fontFamily: "Manrope",
                  fontWeight: 500,
                },
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ width: "100%" }}>
        {careers.map((job, index) => (
          <Accordion
            key={index}
            sx={{ width: "100%", mt: 4, border: "none", boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {" "}
              <Box
                sx={{
                  display: { sm: "flex", md: "flex" },
                  justifyContent: {
                    xs: "start",
                    sm: "space-between",
                    md: "space-between",
                  },
                  width: "95%",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{ fontSize: { xs: 16, sm: 20, md: 24 }, fontWeight: 700 }}
                >
                  {job.post}
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{ fontSize: { xs: 12, sm: 14, md: 16 }, fontWeight: 600 }}
                >
                   {job.empType}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  width: "100%",
                  alignItems: "start",
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{ fontSize: { xs: 12, sm: 14, md: 16 }, fontWeight: 500 }}
                >
                  Salary : {job.salary}
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { xs: 12, sm: 14, md: 16 },
                    fontWeight: 500,
                    mt: 1,
                  }}
                >
                  Job Location: {job.location}
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { xs: 12, sm: 14, md: 16 },
                    fontWeight: 500,
                    mt: 1,
                  }}
                >
                  Experience: {job.experience}
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { xs: 12, sm: 14, md: 16 },
                    fontWeight: 500,
                    mt: 1,
                  }}
                >
                  Branch: {job.branch}
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { xs: 12, sm: 14, md: 16 },
                    fontWeight: 700,
                    mt: 1,
                  }}
                >
                  What are we looking for?
                </Typography>
                {job.lookingFor.map((look) => (
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      fontWeight: 500,
                    }}
                  >
                    ● {look}
                  </Typography>
                ))}
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { xs: 12, sm: 14, md: 16 },
                    fontWeight: 700,
                    mt: 1,
                  }}
                >
                  Key responsibilities
                </Typography>
                {job.responsibilities.map((resp) => (
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      fontWeight: 500,
                    }}
                  >
                    ● {resp}
                  </Typography>
                ))}

                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { xs: 12, sm: 14, md: 16 },
                    fontWeight: 700,
                    mt: 2,
                  }}
                >
                  How to Apply?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    mt: 1,
                    gap: 1,
                  }}
                >
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      fontWeight: 500,
                    }}
                  >
                    Candidates can send their resume to the mail id:{" "}
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      fontWeight: 700,
                    }}
                  >
                    <Link to="mailto:jobs@msigmagokulam.com" sx={{}}>
                      jobs@msigmagokulam.com
                    </Link>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      fontWeight: 500,

                    }}
                  >
                    Subject of the mail should be of the format:
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      fontWeight: 700,

                    }}
                  >
                    {job.post}
                  </Typography>
                </Box>

                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { xs: 12, sm: 14, md: 16 },
                    fontWeight: 600,
                    fontStyle: "italic",
                  }}
                >
                  Otherwise the application will be considered invalid.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

export default Hire;

// import {
//   Box,
//   Grid,
//   InputAdornment,
//   TextField,
//   Typography,
// } from "@mui/material";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import { BsArrowDownRightCircleFill } from "react-icons/bs";
// import careers from "./careers";
// import { Link } from "react-router-dom";

// const Hire = () => {

//   return (
//     <Grid container sx={{ width: "100%", px: { xs: 4, sm: 6, md: 8 } }}>
//       <Grid
//         container
//         sx={{
//           display: "flex",
//           flexDirection: { xs: "column", sm: "row", md: "row" },
//           alignItems: "center",
//           justifyContent: {
//             xs: "start",
//             sm: "space-between",
//             md: "space-between",
//           },
//           my: { xs: 2, sm: 4, md: 6 },
//           px: { ms: 0, sm: 2, md: 2 },
//         }}
//       >
//         <Grid item>
//           <Typography
//             fontFamily="Manrope"
//             sx={{ fontSize: "18px", fontWeight: 800 }}
//           >
//             We’re Hiring
//           </Typography>
//           <Typography
//             fontFamily="Manrope"
//             sx={{
//               fontSize: { xs: "14px", sm: "16px", md: "16px" },
//               fontWeight: 500,
//               mt: 1,
//             }}
//           >
//             Join our team and be part of something extraordinary! <br />
//             We're hiring talented individuals who are passionate about making a
//             difference
//           </Typography>
//         </Grid>
//         <Grid item>
//           <TextField
//             variant="outlined"
//             placeholder="Search Jobs"
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment
//                   position="end"
//                   sx={{ color: "#E6E6E7", fontSize: "30px", mr: -1 }}
//                 >
//                   <BsArrowDownRightCircleFill />
//                 </InputAdornment>
//               ),
//               sx: {
//                 borderRadius: "30px",
//                 width: { xs: "330px", md: "385px" },
//                 height: "45px",
//                 mt: 2,
//                 '&:focus': {
//                   borderColor: '#43BA80', 
//                 },
//               },
//             }}
//           />
//         </Grid>
//       </Grid>

//       <Grid container sx={{ width: "100%" }}>
//         {careers.map((job, index) => (
//           <Accordion
//             key={index}
//             sx={{ width: "100%", mt: 4, border: "none", boxShadow: "none" }}
//           >
//             <AccordionSummary
//               expandIcon={<ArrowDownwardIcon />}
//               aria-controls="panel1-content"
//               id="panel1-header"
//             >
//               {" "}
//               <Box
//                 sx={{
//                   display: { sm: "flex", md: "flex" },
//                   justifyContent: {
//                     xs: "start",
//                     sm: "space-between",
//                     md: "space-between",
//                   },
//                   width: "95%",
//                   alignItems: "center",
//                 }}
//               >
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{ fontSize: { xs: 16, sm: 20, md: 24 }, fontWeight: 700 }}
//                 >
//                   {job.post}
//                 </Typography>
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{ fontSize: { xs: 12, sm: 14, md: 16 }, fontWeight: 600 }}
//                 >
//                    {job.empType}
//                 </Typography>
//               </Box>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "start",
//                   width: "100%",
//                   alignItems: "start",
//                 }}
//               >
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{ fontSize: { xs: 12, sm: 14, md: 16 }, fontWeight: 500 }}
//                 >
//                   Salary : {job.salary}
//                 </Typography>
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{
//                     fontSize: { xs: 12, sm: 14, md: 16 },
//                     fontWeight: 500,
//                     mt: 1,
//                   }}
//                 >
//                   Job Location: {job.location}
//                 </Typography>
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{
//                     fontSize: { xs: 12, sm: 14, md: 16 },
//                     fontWeight: 500,
//                     mt: 1,
//                   }}
//                 >
//                   Experience: {job.experience}
//                 </Typography>
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{
//                     fontSize: { xs: 12, sm: 14, md: 16 },
//                     fontWeight: 500,
//                     mt: 1,
//                   }}
//                 >
//                   Branch: {job.branch}
//                 </Typography>
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{
//                     fontSize: { xs: 12, sm: 14, md: 16 },
//                     fontWeight: 700,
//                     mt: 1,
//                   }}
//                 >
//                   What are we looking for?
//                 </Typography>
//                 {job.lookingFor.map((look) => (
//                   <Typography
//                     fontFamily="Manrope"
//                     sx={{
//                       fontSize: { xs: 12, sm: 14, md: 16 },
//                       fontWeight: 500,
//                     }}
//                   >
//                     ● {look}
//                   </Typography>
//                 ))}
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{
//                     fontSize: { xs: 12, sm: 14, md: 16 },
//                     fontWeight: 700,
//                     mt: 1,
//                   }}
//                 >
//                   Key responsibilities
//                 </Typography>
//                 {job.responsibilities.map((resp) => (
//                   <Typography
//                     fontFamily="Manrope"
//                     sx={{
//                       fontSize: { xs: 12, sm: 14, md: 16 },
//                       fontWeight: 500,
//                     }}
//                   >
//                     ● {resp}
//                   </Typography>
//                 ))}

//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{
//                     fontSize: { xs: 12, sm: 14, md: 16 },
//                     fontWeight: 700,
//                     mt: 2,
//                   }}
//                 >
//                   How to Apply?
//                 </Typography>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     mt: 1,
//                     gap: 1,
//                   }}
//                 >
//                   <Typography
//                     fontFamily="Manrope"
//                     sx={{
//                       fontSize: { xs: 12, sm: 14, md: 16 },
//                       fontWeight: 500,
//                     }}
//                   >
//                     Candidates can send their resume to the mail id:{" "}
//                   </Typography>
//                   <Typography
//                     fontFamily="Manrope"
//                     sx={{
//                       fontSize: { xs: 12, sm: 14, md: 16 },
//                       fontWeight: 700,
//                     }}
//                   >
//                     <Link to="mailto:jobs@msigmagokulam.com" sx={{}}>
//                       jobs@msigmagokulam.com
//                     </Link>
//                   </Typography>
//                 </Box>

//                 <Box
//                   sx={{
//                     display: "flex",
//                     gap: 1,
//                   }}
//                 >
//                   <Typography
//                     fontFamily="Manrope"
//                     sx={{
//                       fontSize: { xs: 12, sm: 14, md: 16 },
//                       fontWeight: 500,

//                     }}
//                   >
//                     Subject of the mail should be of the format:
//                   </Typography>
//                   <Typography
//                     fontFamily="Manrope"
//                     sx={{
//                       fontSize: { xs: 12, sm: 14, md: 16 },
//                       fontWeight: 700,

//                     }}
//                   >
//                     {job.post}
//                   </Typography>
//                 </Box>

//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{
//                     fontSize: { xs: 12, sm: 14, md: 16 },
//                     fontWeight: 600,
//                     fontStyle: "italic",
//                   }}
//                 >
//                   Otherwise the application will be considered invalid.
//                 </Typography>
//               </Box>
//             </AccordionDetails>
//           </Accordion>
//         ))}
//       </Grid>
//     </Grid>
//   );
// };

// export default Hire;
